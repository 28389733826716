import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { MyStoreTireRimSizeType } from '@1po/1po-bff-fe-spec/generated/my_store/tires/request/UpdateMyStoreTireDiscount';
import { RootState } from 'app/AppStore';
import {
  getMyStoreTiresDiscount,
  getMyStoreTiresDiscountChange,
  setMyStoreTiresChange,
} from 'domains/myStore/MyStore.store';
import { SValidatedInputNumber } from './MyStoreDiscountInput.styled';

interface MyStoreDiscountInputProps {
  brand: ReferenceBrandType;
  tireRimSizeType: MyStoreTireRimSizeType;
}

export const MyStoreDiscountInput = ({ brand, tireRimSizeType }: MyStoreDiscountInputProps) => {
  const dispatch = useDispatch();

  const defaultMyStoreTireDiscount = useSelector((state: RootState) =>
    getMyStoreTiresDiscount(state, { brand, tireRimSizeType }),
  );

  const getUsersChangedValue = useSelector((state: RootState) =>
    getMyStoreTiresDiscountChange(state, { brand, tireRimSizeType }),
  );

  // when new data from server arrives
  useEffect(() => {
    getUsersChangedValue == undefined && setDiscount(defaultMyStoreTireDiscount?.discount);
  }, [defaultMyStoreTireDiscount, getUsersChangedValue]);

  // when cancel button is pressed
  useEffect(() => {
    getUsersChangedValue == undefined && setDiscount(defaultMyStoreTireDiscount?.discount);
  }, [defaultMyStoreTireDiscount?.discount, getUsersChangedValue]);

  const [discount, setDiscount] = useState(getUsersChangedValue ?? defaultMyStoreTireDiscount?.discount);

  return (
    <SValidatedInputNumber
      status={
        getUsersChangedValue && getUsersChangedValue != defaultMyStoreTireDiscount?.discount ? 'warning' : undefined
      }
      value={Number(discount)}
      min={0}
      max={100}
      precisionMin={0}
      precisionMax={2}
      isValid={discount !== undefined}
      onChange={(newValue) => {
        setDiscount(Number(newValue));
        dispatch(setMyStoreTiresChange({ item: { brandId: brand, tireRimSizeType, discount: Number(newValue) } }));
      }}
    />
  );
};
