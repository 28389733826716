import React from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { Box, CenteredSpin, Cursor, ScalableImage } from 'UI/index';
import { getData } from 'utils';

export const ImageWithLoader = ({
  imageBase64,
  imageUrl,
  catalogSource,
  imageHeight,
  imageWidth,
  fallbackComponent,
  alt,
  cursor,
}: {
  imageBase64?: string;
  imageUrl?: string;
  catalogSource?: CatalogSource;
  imageHeight: number;
  imageWidth?: number;
  fallbackComponent?: React.ReactNode;
  alt: string;
  cursor?: Cursor;
}) => {
  const { t } = useTranslation();

  return (
    <DataContainer
      data={imageBase64 || imageUrl}
      Loading={() => (
        <Box height={imageHeight} width={imageWidth}>
          <CenteredSpin />
        </Box>
      )}
      Error={() => (
        <Box height={imageHeight} width={imageWidth}>
          <ErrorWithLabel
            label={t('common.image.backend_error', 'Picture temporarily unavailable, please try again later.')}
          />
        </Box>
      )}
    >
      <ScalableImage
        height={imageHeight}
        width={imageWidth}
        base64={getData(imageBase64)}
        imageUrl={imageUrl}
        catalogSource={catalogSource}
        fallbackSrc={'placeholder.png'}
        fallbackComponent={fallbackComponent}
        alt={alt}
        cursor={cursor ?? 'auto'}
      />
    </DataContainer>
  );
};
