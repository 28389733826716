import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { getExplodedTree, getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { formatCategoryLabelToDataCy, getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import {
  SRoutineVehicleMaintenanceCard,
  SText,
} from 'pages/CatalogPage/DH/CarPartGroupsSection/CarPartGroupItem/CarPartGroupItem.styled';
import { CarPartElementId } from 'pages/CatalogPage/DH/CarPartGroupsSection/CarPartGroupsSection';
import { ScrollWrapper } from 'pages/CatalogPage/DH/CarPartGroupsSection/CarPartGroupsSection.styled';
import { Box, CenterFlex, Flex, Image, sentenceCase, Text, WithTooltip } from 'UI';
import { getData } from 'utils';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';
import { RoutineVehicleMaintenanceItemContainer } from './RoutineVehicleMaintenanceGroupCard.styled';
import { routineVehicleMaintenanceItems } from './RoutineVehicleMaintenanceGroupCard.types';

const BODY_HEIGHT = 514;
const CARD_HEIGHT = BODY_HEIGHT + 60;
const BODY_WIDTH = 1200;

export const ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID = 'N-21347';
export const isMaintenanceNodeId = (nodeId: string) => nodeId === ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID;

interface RoutineVehicleMaintenanceItemProps {
  label: string;
  nodeId: string;
  path: string;
}

const RoutineVehicleMaintenanceItem = ({ label, nodeId, path }: RoutineVehicleMaintenanceItemProps) => {
  const history = useHistory();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const currentItem = routineVehicleMaintenanceItems[nodeId];

  if (!currentItem) return null;

  return (
    <WithTooltip title={sentenceCase(label)}>
      <RoutineVehicleMaintenanceItemContainer
        onClick={() => {
          if (vehicleDetail) {
            history.push({
              pathname: `${getCatalogSourceUrl(
                vehicleDetail.catalogSource,
              )}/${vehicleKey}${ROUTER_CATALOG_VEHICLE}/${path}`,
              search: location.search,
            });
          }
        }}
        offsetX={currentItem.offsetX}
        offsetY={currentItem.offsetY}
      >
        <Image
          alt={label}
          src={`catalog/RoutineVehicleMaintenance/${currentItem.src}`}
          width={currentItem.width}
          dataCy={formatCategoryLabelToDataCy(label, 'image')}
        />
      </RoutineVehicleMaintenanceItemContainer>
    </WithTooltip>
  );
};

function findNodeByLabel(
  element: VehiclePartCategoryTreeItem | undefined,
  label: string,
): VehiclePartCategoryTreeItem | undefined {
  if (!element) return undefined;
  if (element.label.toLowerCase() === label.toLowerCase()) {
    return element;
  } else if (element.items) {
    let result = undefined;
    for (let i = 0; result === undefined && i < element.items.length; i++) {
      result = findNodeByLabel(element.items[i], label);
    }
    return result ? { ...element, items: [result] } : undefined;
  }
  return undefined;
}

export const RoutineVehicleMaintenanceGroupCard = () => {
  const { t } = useTranslation();
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleExplodedViews = getData(useSelector((state: RootState) => getExplodedTree(state, vehicleKey)));
  const rvmNodeTree = useMemo(
    () => vehicleExplodedViews?.find((item) => item.nodeId === ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID),
    [vehicleExplodedViews],
  );
  const rvmExplodedViewItems = rvmNodeTree?.items;
  const vehicleExplodedViewsFiltered = useMemo(
    () => vehicleExplodedViews?.filter((l1Item) => l1Item.nodeId !== ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID),
    [vehicleExplodedViews],
  );

  const l3Items = useMemo(() => {
    return (
      rvmExplodedViewItems
        // create list of RVM l2Items
        ?.map((item) =>
          // create list of RVM l3Items
          item.items.map((l3Item) => {
            // search for RMV item in tree view
            for (const el of vehicleExplodedViewsFiltered || []) {
              const result = findNodeByLabel(el, l3Item.label);
              // add to list if result found
              if (result) {
                const lvl2Item = result.items.length > 0 ? result.items[0] : undefined;
                const lvl3Item = lvl2Item && lvl2Item.items.length > 0 ? lvl2Item.items[0] : undefined;
                if (
                  result.nodeId &&
                  lvl2Item?.nodeId &&
                  lvl3Item?.nodeId &&
                  (lvl3Item?.plateIds?.length > 0 || lvl3Item?.containsMarketingReferences)
                ) {
                  return {
                    label: l3Item.label,
                    nodeId: l3Item.nodeId,
                    path: `${result.nodeId}/${lvl2Item.nodeId}/${lvl3Item.nodeId}`,
                  };
                }
              }
            }
          }),
        )
        .reduce((acc, next) => acc.concat(next), [])
    );
  }, [rvmExplodedViewItems, vehicleExplodedViewsFiltered]);

  return (
    <Box height={CARD_HEIGHT}>
      <CenterFlex>
        <ScrollWrapper offsetTop={offsetTop} id={`${CarPartElementId}${rvmNodeTree?.nodeId}`}>
          <SRoutineVehicleMaintenanceCard
            title={
              <SText type={'h2'} cursor={'pointer'} disableGutter ellipsis dataCy={'title-routine-vehicle-maintenance'}>
                {sentenceCase(rvmNodeTree?.label ?? '')}
              </SText>
            }
            width={BODY_WIDTH}
          >
            <Flex
              minHeight={BODY_HEIGHT}
              minWidth={BODY_WIDTH}
              direction={'column'}
              dataCy={'content-routine-vehicle-maintenance'}
            >
              {/*<RoutineVehicleMaintenanceBackgroundImage*/}
              {/*  width={BODY_WIDTH}*/}
              {/*  height={BODY_HEIGHT}*/}
              {/*  backgroundImageType={PUBLIC}*/}
              {/*  backgroundImage={`catalog/RoutineVehicleMaintenance/routine-vehicle-maintenance-background.png`}*/}
              {/*/>*/}
              <Flex direction={'row'} justify={'flex-end'} maxHeight={70}>
                <Box width={300}>
                  <Text type={'h1_banner_light'}>{t('catalog.parts.front', 'Front')}</Text>
                </Box>
                <Box width={500}>
                  <Text type={'h1_banner_light'}>{t('catalog.parts.rear', 'Rear')}</Text>
                </Box>
              </Flex>
              <Flex>
                {l3Items?.map(
                  (item) =>
                    item && (
                      <RoutineVehicleMaintenanceItem
                        key={`rvm-${item.nodeId}`}
                        label={item.label}
                        nodeId={item.nodeId}
                        path={item.path}
                      />
                    ),
                )}
              </Flex>
            </Flex>
          </SRoutineVehicleMaintenanceCard>
        </ScrollWrapper>
      </CenterFlex>
    </Box>
  );
};
