/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import Loader from 'components/Loader';
import { useFetchFullPromotion } from 'domains/promotion/Promotion.requests';
import { findStatusTypeByPromotionID, getFullPromotion } from 'domains/promotion/Promotion.store';
import { useSetCreatePromoModel } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion.hook';
import { PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Empty, Flex, Link, MarginBox, Text } from 'UI';
import CreatePromotion from '../CreatePromotion/CreatePromotion';

const EditPromotion = () => {
  const { t } = useTranslation();
  const { promotionId } = useParams<{ promotionId: string }>();
  const promotion = useSelector((state: RootState) => getFullPromotion(state, promotionId));
  const promotionStatus = useSelector((state: RootState) => findStatusTypeByPromotionID(state, promotionId));

  useFetchFullPromotion(promotionId);
  useSetCreatePromoModel(promotionId);

  return (
    <DataContainer
      data={promotion}
      Error={() => (
        <ErrorWithLabel label={t('common.backend_error', 'Data temporarily unavailable, please try again later.')} />
      )}
      Loading={() => <Loader height={'50vh'} />}
      NotFound={() => <Empty />}
    >
      {promotionStatus !== 'ARCHIVED' ? (
        <CreatePromotion />
      ) : (
        <Flex direction={'column'} justify={'center'}>
          <MarginBox mt={90} />
          <ErrorWithLabel
            label={t('backoffice.promotion.edit.archived.error', 'Archived promotion cannot be edited.')}
          />
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Link to={`/${PROMOTION}/${promotionId}`}>
              <Text type={'text'} displayStyle={'link'} hoverUnderLine cursor={'pointer'}>
                {t('backoffice.promotion.see_promotion_page', 'See promotion page')}
              </Text>
            </Link>
          </Flex>
        </Flex>
      )}
    </DataContainer>
  );
};

export default EditPromotion;
