import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterAndSortField } from '@1po/1po-bff-fe-spec/generated/order/request/model/FilterAndSortField';
import { TFunction } from 'i18next';
import { ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DownOutlinedIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { RANGE_FILTER_TYPE, TEXT_FILTER_TYPE } from 'components/Filter/Filter.types';
import { FilterTag } from 'components/Filter/FilterTag';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { disableEnablePopins } from 'domains/firstHelp/FirstHelp.api';
import { useAutomaticFirstHelp } from 'domains/firstHelp/FirstHelp.requests';
import { useInfiniteScrollInProgressOrders } from 'domains/order/Order.requests';
import {
  fetchDealerOrdersRequestSaga,
  getActiveFilters,
  getInProgressOrderIdsData,
  getInProgressOrders,
  getInProgressOrdersIsLoading,
  resetInProgressOrdersFilter,
  resetInProgressOrdersForNewSearch,
  setActiveFilters,
  setInProgressOrdersSort,
} from 'domains/order/Order.store';
import { FilterKey, SortOption } from 'domains/order/Order.types';
import { getDealerType, getUserContext } from 'domains/user';
import { FilterOptions } from 'pages/MyOrdersPage/InProgressOrders/FilterOptions';
import { SelectButton, SPopover } from 'pages/MyOrdersPage/InProgressOrders/InProgressOrders.styled';
import { handlePrintDetail, handlePrintList } from 'pages/MyOrdersPage/InProgressOrders/pdfPrint';
import { OrderCard } from 'pages/MyOrdersPage/OrderCards/OrderCard';
import { Flex, Icon, InfiniteScroll, MarginBox, PrintButtonRound, Select, SelectOptionSingle, Checkbox } from 'UI';
import { textFormatter } from 'utils';

const sortOptions = (t: TFunction): SelectOptionSingle[] => [
  { title: t('order.in_progress.filter.sort_by.most_recent', 'Most recent order'), value: 'DATE_DESC' },
  { title: t('order.in_progress.filter.sort_by.oldest', 'Oldest order'), value: 'DATE_ASC' },
  { title: t('order.in_progress.filter.sort_by.highest_price', 'Highest price'), value: 'PRICE_DESC' },
  { title: t('order.in_progress.filter.sort_by.lowest_price', 'Lowest price'), value: 'PRICE_ASC' },
];

const transformStatusStringToArray = (statusFilter: [FilterAndSortField, string[]] | undefined) => {
  if (!statusFilter || statusFilter.length < 2) {
    return null;
  }

  const inputString = statusFilter[1][0];

  return inputString.split('-');
};

export function InProgressOrders() {
  const { t } = useTranslation();
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const orderIds = useSelector(getInProgressOrderIdsData);
  const activeFilters = useSelector(getActiveFilters);
  const statusFilter = activeFilters.findLast(([key, _]) => key === 'STATUS');
  const statusLabels = transformStatusStringToArray(statusFilter);
  const orders = useSelector((state: RootState) => getInProgressOrders(state, orderIds));
  const dealerType = useSelector(getDealerType);
  const { r1Country } = useSelector(getUserContext);
  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { hasMore, loadMore, currentData } = useInfiniteScrollInProgressOrders({ orders });
  const isLoading = useSelector(getInProgressOrdersIsLoading);

  useEffect(() => {
    if (selectedOrders && currentData && selectedOrders.length === currentData.length && selectedOrders.length > 0) {
      if (!isSelectAllChecked) {
        setIsSelectAllChecked(true);
      }
    }
  }, [selectedOrders, currentData, isSelectAllChecked, setIsSelectAllChecked]);

  const setUnselect = () => {
    if (isSelectAllChecked) {
      setIsSelectAllChecked(false);
    }
  };

  useEffect(() => {
    disableEnablePopins(dispatch, currentData.length === 0, [`${ROUTER_IN_PROGRESS_ORDERS}_order_detail`]);
  }, [currentData.length, dispatch]);
  useAutomaticFirstHelp(ROUTER_IN_PROGRESS_ORDERS, orders.length === 0);

  function selectAll() {
    doSelectAll(!isSelectAllChecked);
  }

  function doSelectAll(isSelected: boolean) {
    setIsSelectAllChecked(isSelected);
    setSelectedOrders(isSelected ? [...currentData.map((i) => i.internalOrderId)] : []);
  }

  return (
    <Flex direction={'column'}>
      <MarginBox mt={15} />
      <Flex>
        <FirstHelpPopin
          streamId={ROUTER_IN_PROGRESS_ORDERS}
          popinId={`${ROUTER_IN_PROGRESS_ORDERS}_filters`}
          placement={'right'}
        >
          <SPopover content={<FilterOptions buttonRef={buttonRef} />} trigger="click" placement={'bottom'}>
            <SelectButton passRef={buttonRef}>
              {t('order.in_progress.filter.filter_by', 'Filter by:')}
              <Icon IconComponent={DownOutlinedIcon} size={14} />
            </SelectButton>
          </SPopover>
        </FirstHelpPopin>
        <MarginBox ml={15} />
        <Flex maxWidth={200}>
          <Select
            onChange={(value) => {
              dispatch(setInProgressOrdersSort(value as SortOption));
              dispatch(resetInProgressOrdersForNewSearch());
              dispatch(fetchDealerOrdersRequestSaga());
            }}
            options={sortOptions(t)}
            bordered
            styleLess
            placeholder={t('order.in_progress.filter.sort_by', 'Sort by:')}
          />
        </Flex>
        <Flex size={3} />
        <Flex size={1} justify={'flex-end'}>
          <MarginBox mt={5} mr={30}>
            <Checkbox
              checked={isSelectAllChecked}
              onChange={selectAll}
              label={`${t('order.in_progress.select_all', 'Select all')}`}
            />
          </MarginBox>
          <MarginBox mt={-15} mr={30}>
            <FirstHelpPopin
              streamId={ROUTER_IN_PROGRESS_ORDERS}
              popinId={`${ROUTER_IN_PROGRESS_ORDERS}_print`}
              placement={'left'}
            >
              <PrintButtonRound
                onClick={() => {
                  selectedOrders.length === 1 && handlePrintDetail(currentData, selectedOrders, dealerType, r1Country);
                  selectedOrders.length > 1 && handlePrintList(currentData, selectedOrders);
                }}
                disabled={selectedOrders.length === 0}
              />
            </FirstHelpPopin>
          </MarginBox>
        </Flex>
      </Flex>
      <MarginBox mt={30} />
      {activeFilters.length > 0 && (
        <Flex wrap={'wrap'} gap={15}>
          {activeFilters
            .filter(([key, _]) => key !== 'STATUS')
            .map(([key, value], index) => {
              const item =
                key === 'DATE' && value.length > 1
                  ? t('order.in_progress.filter.tag.date_range', '{{date_from}} to {{date_to}}', {
                      date_from: textFormatter.formatDateShort(new Date(value[0])),
                      date_to: textFormatter.formatDateShort(new Date(value[1])),
                    })
                  : value[0];
              return (
                <React.Fragment key={key + index}>
                  <FilterTag
                    key={key}
                    type={TEXT_FILTER_TYPE}
                    id={key}
                    item={item}
                    handleCancelFilter={(
                      _type: typeof TEXT_FILTER_TYPE | typeof RANGE_FILTER_TYPE,
                      id: string,
                      _item: string,
                    ) => {
                      dispatch(resetInProgressOrdersFilter({ key: id as FilterKey }));
                      dispatch(resetInProgressOrdersForNewSearch());
                      dispatch(setActiveFilters());
                      dispatch(fetchDealerOrdersRequestSaga());
                    }}
                  />
                </React.Fragment>
              );
            })}
        </Flex>
      )}
      {statusLabels && (
        <MarginBox mt={15}>
          <Flex wrap={'wrap'} gap={15}>
            {statusLabels.map((key, index) => {
              return (
                <React.Fragment key={key + index}>
                  <FilterTag
                    key={key}
                    type={TEXT_FILTER_TYPE}
                    id={'STATUS'}
                    item={key}
                    handleCancelFilter={(
                      _type: typeof TEXT_FILTER_TYPE | typeof RANGE_FILTER_TYPE,
                      _id: string,
                      _item: string,
                    ) => {
                      dispatch(resetInProgressOrdersFilter({ key: 'STATUS', statusValue: key }));
                      dispatch(resetInProgressOrdersForNewSearch());
                      dispatch(setActiveFilters());
                      dispatch(fetchDealerOrdersRequestSaga());
                    }}
                  />
                </React.Fragment>
              );
            })}
          </Flex>
        </MarginBox>
      )}
      <MarginBox mt={30} />
      <InfiniteScroll hasMore={hasMore} loadMore={loadMore} dataCy={'table-orders'}>
        <DataContainer data={isLoading}>
          {currentData?.map((order, index) => (
            <OrderCard
              key={order.internalOrderId}
              order={order}
              index={index}
              setSelectedOrders={setSelectedOrders}
              selectedOrders={selectedOrders}
              setUnselect={setUnselect}
            />
          ))}
        </DataContainer>
      </InfiniteScroll>
      <MarginBox mt={30} />
    </Flex>
  );
}
