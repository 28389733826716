import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { RootState } from 'app/AppStore';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import {
  getMaintenanceValuesXml,
  setMaintenanceValuesXml,
  setMaintenanceValuesXmlStatus,
} from 'domains/maintenanceValues/MaintenanceValues.store';
import { useFetchSignature } from 'domains/pictures/Pictures.requests';
import { getSignature } from 'domains/urlSignatures';
import { FOUND, LOADING, NOT_FOUND } from 'utils';

export const useFetchMaintenanceValuesXml = (xmlPath: string | undefined, catalogSource: CatalogSource) => {
  const dispatch = useDispatch();
  const signature = useSelector((state: RootState) => getSignature(state, catalogSource));
  const xml = useSelector(getMaintenanceValuesXml).data;
  const urlSign = signature ? `?${signature}` : '';
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const vehicleImage = vehicleDetail?.imageUrl;
  const url = new URL(vehicleImage ?? '');
  const preUrl = url.protocol + '//' + url.hostname + '/';

  useFetchSignature();

  useEffect(() => {
    if (xmlPath && signature && !xml) {
      dispatch(setMaintenanceValuesXmlStatus(LOADING));
      fetch(`${preUrl}${xmlPath}${urlSign}`)
        .then((response) => {
          if (response.status >= 400 && response.status <= 499) {
            throw new Error(`Unable to get data.`);
          }
          return response.text();
        })
        .then((xml) => {
          dispatch(setMaintenanceValuesXml(xml));
          dispatch(setMaintenanceValuesXmlStatus(FOUND));
        })
        .catch(() => {
          dispatch(setMaintenanceValuesXmlStatus(NOT_FOUND));
        });
    }
  }, [dispatch, xml, xmlPath, signature, urlSign, preUrl]);
};
