import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { useFetchUniversalProducts } from 'domains/catalog/Catalog.requests';
import { getAllUniversalProducts } from 'domains/catalog/Catalog.store';
import { SparePartsViewType } from 'domains/user';
import { UniversalProduct } from 'pages/UniversalProductsPage/UniversalProduct';
import { Box, CenterFlex, ErrorAlert, Grid, InfiniteScroll, MarginBox, useInfiniteScroll } from 'UI';
import { hasData, LOADING, NO_DATA, NOT_FOUND, useBreakpointSelectorFull } from 'utils';

const PAGING_SIZE = 15;

export interface UniversalProductsProps {
  sparePartsView: SparePartsViewType;
  references: (string | NO_DATA)[];
}

export const UniversalProducts = ({ sparePartsView, references }: UniversalProductsProps) => {
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const { hasMore, loadMore, currentData } = useInfiniteScroll<string | NO_DATA>({
    data: references,
    chunkSize: PAGING_SIZE,
  });
  const allUniversalProducts = useSelector(getAllUniversalProducts);
  useFetchUniversalProducts(currentData.filter((ref) => hasData(ref) && !allUniversalProducts.get(ref)) as string[]);

  const displayNotFoundForFirstOnly = (index: number) => (index === 0 ? NOT_FOUND : undefined);

  const { t } = useTranslation();

  return (
    <MarginBox mt={45}>
      {currentData.length === 0 && (
        <ErrorAlert
          message={t(
            'catalog.universal_products.filter.notfound',
            'Sorry, no match found for your research, please adjust your search criteria.',
          )}
        />
      )}
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        <Grid columns={breakpointSelectorFull({ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 })} gutter={undefined}>
          {currentData.map((r, index) => (
            <CenterFlex key={`universal-product-${index}`}>
              <Box width={210} height={450}>
                <DataContainer
                  data={
                    r && allUniversalProducts.get(r) !== NOT_FOUND
                      ? r && allUniversalProducts.get(r)
                      : displayNotFoundForFirstOnly(index)
                  }
                  Error={() => (
                    <ErrorWithLabel
                      label={t(
                        'catalog.universal_products.backend_error',
                        'This Universal product is temporarily unavailable, please try again later.',
                      )}
                    />
                  )}
                >
                  <UniversalProduct
                    sparePartsView={sparePartsView}
                    refLocal={r ? allUniversalProducts.get(r) : LOADING} //FIXME LOADING/NO_DATA possibility
                  />
                </DataContainer>
              </Box>
            </CenterFlex>
          ))}
        </Grid>
      </InfiniteScroll>
    </MarginBox>
  );
};
