import * as React from 'react';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import styled, { ThemeProvider } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { CheckCircleIcon, ExclamationCircleIcon, InfoCircleIcon, TimesCircleIcon } from 'assets/icons';
import { theme } from 'styles';
import { MarginBox } from 'UI/Box/MarginBox';
import { BlueButton, WhiteBlueButton } from 'UI/Button/Button.styled';
import { Flex } from 'UI/Flex';
import { Link } from 'UI/Link';
import { Text } from 'UI/Text';

export type NotificationDisplayStyle = 'info' | 'warning' | 'error' | 'success';

export const NotificationLink = styled.div`
  font-family: 'NouvelR', sans-serif;
  font-size: 14px;
  color: ${theme.color.info};
  text-decoration: underline;
  cursor: pointer;
`;

const MessageWrapper = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ActionWrapper = styled.div`
  display: flex;
  white-space: nowrap;
`;

export const notificationStyle = () => ({
  success: {
    style: {
      width: 600,
      padding: '30px 20px 25px 25px',
      backgroundColor: theme.color.success_bg,
    },
    icon: <CheckCircleIcon width={24} height={24} fill={theme.color.grass_green} />,
    duration: 2,
  },
  info: {
    style: {
      width: 600,
      padding: '30px 20px 25px 25px',
      backgroundColor: theme.color.info_blue,
    },
    icon: <InfoCircleIcon width={24} height={24} fill={theme.color.info} />,
    duration: 2,
  },
  warning: {
    style: {
      width: 600,
      padding: '30px 20px 25px 25px',
      backgroundColor: theme.color.warning_bg,
    },
    icon: <ExclamationCircleIcon width={24} height={24} fill={theme.color.warning} />,
    duration: 3,
  },
  error: {
    style: {
      width: 600,
      padding: '30px 20px 25px 25px',
      backgroundColor: theme.color.error_bg,
    },
    icon: <TimesCircleIcon width={24} height={24} fill={theme.color.lipstick} />,
    duration: 4,
  },
});

export const notifyNotImplemented = (): void =>
  notifyTop('info', 'Not implemented', 'Given feature has not been implemented yet.');

const notificationUUIDListMaxSize = 3;
const notificationUUIDList: string[] = [];

function handleNotificationCount(uuid: string) {
  if (notificationUUIDList.length >= notificationUUIDListMaxSize) {
    const closeUUID = notificationUUIDList.shift();
    closeUUID && notification.close(closeUUID);
  }
  notificationUUIDList.push(uuid);
}

export const notifyTop = (
  displayStyle: NotificationDisplayStyle,
  message: React.ReactNode,
  description?: React.ReactNode,
  action: ReactNode = <></>,
  dataCy?: string,
): void => {
  const notificationUUID = uuidv4();
  handleNotificationCount(notificationUUID);

  const options: ArgsProps = {
    key: notificationUUID,
    message: (
      <MessageWrapper data-cy={dataCy}>
        <TextWrapper>{message}</TextWrapper>
        <ActionWrapper>
          <div onClick={() => notification.close(notificationUUID)}>{action}</div>
        </ActionWrapper>
      </MessageWrapper>
    ),
    description,
    placement: 'top',
    top: 80,
    ...notificationStyle()[displayStyle],
  };
  notification[displayStyle](options);
};

export interface NotifyWithButtonsProps {
  header: string;
  description: string[];
  acceptButtonText?: React.ReactNode;
  disagreeButtonText?: React.ReactNode;
  onAccept?: () => void;
  onDisagree?: () => void;
  onClose?: () => void;
}

export const notifyWithButtons = ({
  header,
  description,
  acceptButtonText,
  disagreeButtonText,
  onAccept,
  onDisagree,
  onClose,
}: NotifyWithButtonsProps): void => {
  const notificationUUID = uuidv4();

  const handleAccept = () => {
    notification.close(notificationUUID);
    onAccept && onAccept();
  };

  const handleDisagree = () => {
    notification.close(notificationUUID);
    onDisagree && onDisagree();
  };

  notification.open({
    key: notificationUUID,
    message: (
      <ThemeProvider theme={theme}>
        <MarginBox mt={15}>
          <Text type={'h6'}>{header}</Text>
        </MarginBox>
      </ThemeProvider>
    ),
    description: (
      <ThemeProvider theme={theme}>
        <Flex direction={'column'}>
          {description.map((d, key) => (
            <Text key={key} type={'text_dim'}>
              {d}
            </Text>
          ))}
          <Flex direction={'row-reverse'}>
            <MarginBox mt={15}>
              {onDisagree && (
                <WhiteBlueButton size={'middle'} onClick={handleDisagree}>
                  {disagreeButtonText ?? <Trans i18nKey={'common.no'}>{'No'}</Trans>}
                </WhiteBlueButton>
              )}
              {onAccept && (
                <BlueButton size={'middle'} onClick={handleAccept}>
                  {acceptButtonText ?? <Trans i18nKey={'common.yes'}>{'Yes'}</Trans>}
                </BlueButton>
              )}
            </MarginBox>
          </Flex>
        </Flex>
      </ThemeProvider>
    ),
    duration: 0,
    style: { width: '425px', margin: 0, borderRadius: '7px' },
    onClose,
    placement: 'topRight',
    top: 80,
  });
};

export const notifyTopWithLink = (
  displayStyle: NotificationDisplayStyle,
  message: React.ReactNode,
  description: React.ReactNode,
  linkText: React.ReactNode,
  to: string,
): void => {
  notifyTop(
    displayStyle,
    message,
    description,
    <Link to={to} external>
      <NotificationLink>{linkText}</NotificationLink>
    </Link>,
  );
};
