/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { ReferenceField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateReference';
import { Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Reference';
import { Table } from 'antd';
import { RootState } from 'app/AppStore';
import { ChevronDownIcon, ChevronUpIcon, UnlinkIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import {
  getCatalogReferences,
  getPendingReferenceValidations,
  getReferences,
  removeKnownReferenceValidation,
  removeReference,
  updateReference,
} from 'domains/estimate/Estimate.store';
import { DHReferenceLocal } from 'domains/references';
import { useFetchDiscounts } from 'domains/references/References.requests';
import { GarageView, getCurrency } from 'domains/user';
import { EstimateGarageMargin } from 'pages/EstimatePage/TableSection/EstimateGarageMargin';
import { EstimateGaragePrice } from 'pages/EstimatePage/TableSection/EstimateGaragePrice';
import { LinkedReferenceTable } from 'pages/EstimatePage/TableSection/LinkedReferenceTable';
import {
  DiscountInput,
  EstimatePriceInput,
  GetTitle,
  QuantityInput,
  TextInput,
  VatInput,
} from 'pages/EstimatePage/TableSection/tableComponents';
import { TableProps } from 'pages/EstimatePage/TableSection/TableSection';
import { Flex, Icon, Text, WithTooltip } from 'UI';
import { NO_DATA } from 'utils';
import { textFormatter } from 'utils/format';
import { STable, TooltipContent } from './TableSection.styled';
/* eslint-enable max-len */

export default function ReferenceTable({ estimateId, sparePartsView }: Readonly<TableProps>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const data = useSelector((state: RootState) => getReferences(state, estimateId));
  const catalogReferences = useSelector((state: RootState) => getCatalogReferences(state, estimateId));
  const validationRefs = useSelector(getPendingReferenceValidations);
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;
  const [validationRow, setValidationRow] = useState<{ itemId: string; referenceNumber: string } | undefined>(
    undefined,
  );
  const [checkedReferences, setCheckedReferences] = useState(new Map<string, DHReferenceLocal | NO_DATA>());

  const removeCheckedReferences = (k: string) => {
    checkedReferences.delete(k);
    setCheckedReferences(new Map(checkedReferences));
  };

  const tradingData: ReferenceTradingDataRequestDetail[] = data.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      commercialFamily: '',
    };
  });

  useFetchDiscounts(tradingData);

  useEffect(() => {
    validationRefs
      .flatMap((refForValidation) =>
        catalogReferences.filter((refData) => refData.referenceNumber === refForValidation),
      )
      .forEach((x) => {
        if (!x.isCompatible) {
          setValidationRow({ itemId: x.itemId, referenceNumber: x.referenceNumber });
          onOpen();
        }
        dispatch(removeKnownReferenceValidation(x.referenceNumber));
      });
  }, [validationRefs, dispatch, catalogReferences, onOpen]);

  const handleUpdate = (itemId: string, field: ReferenceField) => (newValue: string) => {
    dispatch(updateReference({ itemId, field, newValue }));
  };

  function continueAdding() {
    setValidationRow(undefined);
  }

  function removeRow(rowId: string) {
    const removedRef = catalogReferences.filter((e) => e.itemId === rowId);
    if (removedRef && removedRef.length === 1 && removedRef[0].referenceNumber) {
      removeCheckedReferences(removedRef[0].referenceNumber);
    }
    dispatch(removeReference({ itemId: rowId }));
  }

  function revertRow() {
    if (validationRow) {
      const refItemToRevert = catalogReferences.filter(
        (refData) => refData.referenceNumber === validationRow.referenceNumber,
      );
      if (refItemToRevert.length === 1) {
        const referenceToRevert = refItemToRevert[0];
        const originalQuantity = referenceToRevert.quantity >= 1 ? referenceToRevert.quantity - 1 : 0;
        if (originalQuantity === 0) {
          dispatch(removeReference({ itemId: validationRow.itemId }));
        } else {
          dispatch(
            updateReference({ itemId: validationRow.itemId, field: 'QUANTITY', newValue: String(originalQuantity) }),
          );
        }
      }
      dispatch(removeKnownReferenceValidation(validationRow.referenceNumber));
      setValidationRow(undefined);
    }
  }

  const tableColumns = [
    {
      title: GetTitle(t('catalog.reference', 'Reference')),
      dataIndex: 'referenceNumber',
      width: '8vw',
      render: function rowSelector(referenceNumber: string, row: Reference) {
        return (
          <Flex justify={'center'} align={'center'}>
            <TextInput
              value={referenceNumber}
              saveFunction={handleUpdate(row.itemId, 'REFERENCE_NUMBER')}
              removeRow={() => removeRow(row.itemId)}
              readOnly={!row.isCustom}
              minWidth={140}
            />
            {!row.isCompatible && (
              <WithTooltip
                title={t('estimate.reference.noncompatible', 'Not compatible with selected vehicle')}
                placement={'top'}
              >
                <TooltipContent>
                  <Flex size={0} align={'center'} justify={'center'}>
                    <Icon IconComponent={UnlinkIcon} size={16} color={'black'} display={'inline'} />
                  </Flex>
                </TooltipContent>
              </WithTooltip>
            )}
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      render: function rowSelector(designation: string, row: Reference) {
        return (
          <TextInput value={designation} saveFunction={handleUpdate(row.itemId, 'NAME')} readOnly={!row.isCustom} />
        );
      },
    },
    {
      title: GetTitle(t('common.price.unit_price', 'Unit Price')),
      dataIndex: 'unitPrice',
      width: '10vw',
      render: function rowSelector(unitPrice: string, row: Reference) {
        return (
          <EstimatePriceInput
            value={unitPrice}
            saveFunction={handleUpdate(row.itemId, 'UNIT_PRICE')}
            readOnly={!row.isCustom}
            minWidth={125}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.quantity', 'Quantity')),
      dataIndex: 'quantity',
      width: '10vw',
      render: function rowSelector(quantity: number, row: Reference) {
        return (
          <QuantityInput
            value={quantity}
            saveFunction={handleUpdate(row.itemId, 'QUANTITY')}
            removeRow={() => removeRow(row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.discount', 'Discount')),
      dataIndex: 'discount',
      width: '10vw',
      render: function rowSelector(discount: string, row: Reference) {
        return (
          <DiscountInput
            value={discount}
            saveFunction={handleUpdate(row.itemId, 'DISCOUNT')}
            discountType={row.discountType}
            saveDiscountType={handleUpdate(row.itemId, 'DISCOUNT_TYPE')}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      width: '8vw',
      render: function rowSelector(priceVatExcluded: string) {
        return (
          <Flex justify={'center'} align={'center'}>
            <Text type={'light_14'}>
              {textFormatter.formatCurrency(isNaN(Number(priceVatExcluded)) ? 0 : priceVatExcluded, currency)}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat.title', 'VAT')),
      dataIndex: 'vatPercentage',
      width: '8vw',
      render: function rowSelector(vat: number, row: Reference) {
        return (
          <VatInput value={vat} saveFunction={handleUpdate(row.itemId, 'VAT_PERCENTAGE')} readOnly={!row.isCustom} />
        );
      },
    },
  ];

  if (sparePartsView === GarageView) {
    tableColumns.push(
      {
        title: GetTitle(t('common.price.garage_price', 'Garage Price')),
        dataIndex: 'garagePrice',
        width: '6vw',
        render: function rowSelector(_garagePrice: string, row: Reference) {
          return <EstimateGaragePrice row={row} currency={currency} handleUpdate={handleUpdate} />;
        },
      },
      {
        title: GetTitle(t('common.price.margin', 'Margin')),
        dataIndex: 'margin',
        width: '6vw',
        render: function rowSelector(_margin: string, row: Reference) {
          return <EstimateGarageMargin estimateId={estimateId} row={row} currency={currency} />;
        },
      },
    );
  }

  if (data.length === 0) {
    return null;
  }
  return (
    <>
      <STable<Reference>
        columns={[...tableColumns, Table.EXPAND_COLUMN]}
        dataSource={data}
        pagination={false}
        rowKey={(row) => row.itemId}
        size={'large'}
        expandable={{
          expandedRowRender: (record) => LinkedReferenceTable(estimateId, record, currency, sparePartsView),
          expandIcon: ExpandIcon,
          defaultExpandAllRows: true,
        }}
      />
      <Dialog
        disclosure={disclosure}
        title={t('common.dialog.actionRequired', 'Action required')}
        description={[
          // tableElements.deleteAllDescription,
          t(
            'estimate.noncompatible.confirmationQuestion',
            // eslint-disable-next-line max-len
            'The reference that you are trying to add to your estimate is not compatible with the identified vehicle. Do you want to add it anyway?',
          ),
        ]}
        icon={UnlinkIcon}
        status={'info'}
        handleConfirm={continueAdding}
        handleCancel={revertRow}
      />
    </>
  );
}

function ExpandIcon({ expanded, record, onExpand }: { expanded: boolean; record: Reference; onExpand: any }) {
  if (!record.linkedReferences || record.linkedReferences.length === 0) {
    return null;
  }

  return (
    <Icon
      IconComponent={expanded ? ChevronDownIcon : ChevronUpIcon}
      onClick={(e) => onExpand(record, e)}
      height={15}
      width={15}
    />
  );
}
