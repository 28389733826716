import React, { ReactNode, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useDraggable } from 'react-use-draggable-scroll';
import { ButtonShape } from 'antd/lib/button/button';
import styled, { css } from 'styled-components';
import { ActiveTabParam, ApplicationUrlParam } from 'domains/catalog/Catalog.types';
import { theme, ThemeFontKeyType } from 'styles';
import { Box, BlackButton, GreyButton, WhiteButton } from 'UI';
import { OFFSET_PADDING, OFFSET_SUB_HEADER, useOffsetTop } from 'utils/hooks/useOffsetTop';
import { StickyBoxCustom } from '../StickyBox';
const halfCardCss = css`
  height: 40px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  padding: 7px 15px 7px 15px;
  display: flex;
  align-items: center;
`;

const DragScrollContainer = styled.div<{ gap: number; scrollable?: boolean }>`
  display: flex;
  gap: ${({ gap }) => gap}px;
  overflow: hidden;
  flex-wrap: ${({ scrollable }) => (scrollable ? 'initial' : 'wrap')};
`;

const BlackHalfCardButton = styled.button<{ disabled?: boolean }>`
  ${halfCardCss};
  background-color: ${({ theme, disabled }) => (disabled ? theme.color.grey70 : theme.color.grey25)};
  color: ${({ theme, disabled }) => (disabled ? theme.color.brand_black : 'inherit')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const RedHalfCardButton = styled.button<{ staticWithColor?: string }>`
  ${halfCardCss};
  background-color: ${({ staticWithColor }) => staticWithColor};
  border-color: ${({ staticWithColor }) => staticWithColor};
`;

const BlackHalfCardButtonActive = styled.button`
  ${halfCardCss};
  background-color: ${({ theme }) => theme.color.grey00};
  cursor: pointer;
`;

export interface LinkTabItem {
  key: number;
  link: string;
  uparam?: URLSearchParams;
  additionalAction?: () => void;
  label: ReactNode;
  component?: ReactNode;
  isDisabled?: boolean;
  staticWithColor?: string;
  dataCy?: string;
}

export interface LinkTabProps {
  sticky?: boolean;
  items?: LinkTabItem[];
  variant?: 'greyOutlined' | 'white' | 'blackHalf';
  variantActive?: 'black' | 'greyOutlined';
  shape?: ButtonShape;
  textType?: ThemeFontKeyType;
  textTypeActive?: ThemeFontKeyType;
  urlParam?: ApplicationUrlParam;
  absolute?: boolean;
  gap?: number;
  scrollable?: boolean;
}

export const LinkTabs = ({
  sticky,
  items,
  urlParam = ActiveTabParam,
  shape = 'round',
  variant = 'greyOutlined',
  absolute,
  gap,
  scrollable,
}: LinkTabProps) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const stickyOffset = useOffsetTop('RENAULT_CATALOG') + OFFSET_SUB_HEADER;
  const scrollContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(scrollContainerRef);
  const isActive = (link: string, uparam: URLSearchParams | undefined): boolean => {
    if (!uparam) {
      return window.location.pathname.includes(getUrl(link));
    }
    return urlParams.get(urlParam) === uparam.get(urlParam);
  };

  const getUrl = (itemLink: string) => (absolute ? '' : url) + itemLink;

  const renderActiveButton = ({ uparam, link, additionalAction, label, dataCy }: LinkTabItem, key: number) => {
    if (variant === 'blackHalf') {
      return (
        <BlackHalfCardButtonActive
          key={key}
          onClick={() => {
            const params = uparam ? `?${uparam}` : '';
            additionalAction?.();
            history.push(`${getUrl(link)}${params}`);
          }}
          data-cy={dataCy}
        >
          {label}
        </BlackHalfCardButtonActive>
      );
    } else {
      return (
        <BlackButton
          key={key}
          size={'large'}
          shape={shape}
          onClick={() => {
            const params = uparam ? `?${uparam}` : '';
            additionalAction?.();
            history.push(`${getUrl(link)}${params}`);
          }}
          dataCy={dataCy}
        >
          {label}
        </BlackButton>
      );
    }
  };

  const renderStaticButton = ({ label, dataCy }: LinkTabItem, key: number, staticWithColor?: string) => {
    return (
      <RedHalfCardButton key={key} data-cy={dataCy} staticWithColor={staticWithColor}>
        {label}
      </RedHalfCardButton>
    );
  };

  const renderNonActiveButton = (
    { uparam, link, additionalAction, label, isDisabled = false, dataCy }: LinkTabItem,
    key: number,
  ) => {
    const params = uparam ? `?${uparam}` : '';

    switch (variant) {
      case 'white':
        return (
          <WhiteButton
            key={key}
            size={'large'}
            shape={shape}
            onClick={() => {
              additionalAction?.();
              history.push(`${getUrl(link)}${params}`);
            }}
            disabled={isDisabled}
            dataCy={dataCy}
          >
            {label}
          </WhiteButton>
        );
      case 'blackHalf':
        return (
          <BlackHalfCardButton
            key={key}
            onClick={() => {
              additionalAction?.();
              history.push(`${getUrl(link)}${params}`);
            }}
            disabled={isDisabled}
            data-cy={dataCy}
          >
            {label}
          </BlackHalfCardButton>
        );
      default:
        return (
          <GreyButton
            key={key}
            size={'large'}
            shape={shape}
            onClick={() => {
              additionalAction?.();
              history.push(`${getUrl(link)}${params}`);
            }}
            disabled={isDisabled}
            dataCy={dataCy}
          >
            {label}
          </GreyButton>
        );
    }
  };

  const renderTabs = () => {
    return (
      // <Box background={theme.color.white}>
      <DragScrollContainer ref={scrollContainerRef} {...events} gap={gap ?? 16} scrollable={scrollable}>
        {items
          ?.filter((it) => it.label)
          .map((item, key) => {
            if (item.staticWithColor) {
              return <div key={key}>{renderStaticButton(item, key, item.staticWithColor)}</div>;
            }
            return (
              item &&
              (isActive(item.link, item.uparam) ? (
                <div key={key}>{renderActiveButton(item, key)}</div>
              ) : (
                <div key={key}>{renderNonActiveButton(item, key)}</div>
              ))
            );
          })}
      </DragScrollContainer>
      // </Box>
    );
  };

  return (
    <>
      {sticky ? (
        <StickyBoxCustom offsetTop={stickyOffset} zIndex={899}>
          <Box height={OFFSET_PADDING} background={theme.color.white} />
          {renderTabs()}
        </StickyBoxCustom>
      ) : (
        renderTabs()
      )}
      <Switch>
        {items?.map(
          (item, key) =>
            item && (
              <Route key={`tab-${key}`} path={(absolute ? '' : path) + item.link}>
                {item.component || null}
              </Route>
            ),
        )}
        <Route path={'*'}>
          <Redirect to={'/'} />
        </Route>
      </Switch>
    </>
  );
};
