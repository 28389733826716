import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTER_MY_STORE_BUNDLES } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CogIcon } from 'assets/icons';
import { ReferenceInput } from 'components/ReferenceInput/ReferenceInput';
import PrivateComponent from 'composers/PrivateComponent';
import { getMyStoreEnabled } from 'domains/appContext/AppContext.store';
import {
  addCatalogLaborTimeByCode,
  addReferenceNumber,
  getCurrentEstimateId,
  getEstimateVehicle,
} from 'domains/estimate/Estimate.store';
import { UserRole } from 'domains/user';
import { theme } from 'styles';
import { Flex, Icon, Link, MarginBox, Text } from 'UI';

function HeaderTextStyle({ children }: { children: React.ReactNode }) {
  return <Text type={'h1_banner_light'}>{children}</Text>;
}

export function ReferenceTableHeader() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAddReference = (referenceNumber: string) => {
    dispatch(addReferenceNumber({ referenceNumber }));
  };

  return (
    <>
      <Flex justify={'space-between'} align={'center'}>
        <HeaderTextStyle>
          <Trans i18nKey={'catalog.reference_plural'}>{'References'}</Trans>
        </HeaderTextStyle>
        <ReferenceInput
          placeholder={t('estimate.action.add_known_reference_to_estimate', 'Add reference to your estimate...')}
          onConfirm={handleAddReference}
        />
      </Flex>
      <Flex direction={'column'}>
        <Text type={'text_dim'}>
          <Trans i18nKey={'estimate.references.table_sub_header_text_1'}>
            {'Add Rparts store referenced items or other references.'}
          </Trans>
        </Text>
        <Text type={'text_dim'}>
          <Trans i18nKey={'estimate.references.table_sub_header_text_2'}>
            {'Please, make sure to fill in "Unit price" and your "VAT rate".'}
          </Trans>
        </Text>
        <MarginBox mb={15} />
      </Flex>
    </>
  );
}

export function LaborTimeTableHeader() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentEstimateId = useSelector(getCurrentEstimateId);
  const vehicle = useSelector((state: RootState) => getEstimateVehicle(state, currentEstimateId));

  const handleAddLaborTime = (laborTimeCode: string) => {
    dispatch(addCatalogLaborTimeByCode(laborTimeCode));
  };

  return (
    <Flex justify={'space-between'} align={'center'}>
      <HeaderTextStyle>
        <Trans i18nKey={'catalog.parts.category.car_parts.labor_times'}>{'Labor times'}</Trans>
      </HeaderTextStyle>
      {vehicle && (
        <ReferenceInput
          placeholder={t('estimate.action.add_labor_time_to_estimate', 'Add a labor time to your estimate...')}
          onConfirm={handleAddLaborTime}
        />
      )}
    </Flex>
  );
}

export const WasteRecyclingTableHeader = () => (
  <HeaderTextStyle>
    <Trans i18nKey={'catalog.waste_recycling'}>{'Waste recycling'}</Trans>
  </HeaderTextStyle>
);

export const TiresTableHeader = () => (
  <HeaderTextStyle>
    <Trans i18nKey={'catalog.tires'}>{'Tires'}</Trans>
  </HeaderTextStyle>
);

export const FreeBundlesTableHeader = () => {
  const myStoreEnabled = useSelector(getMyStoreEnabled);

  return (
    <Flex justify={'space-between'} align={'center'}>
      <HeaderTextStyle>
        <Trans i18nKey={'estimate.free_bundles.title'}>{'Free bundles'}</Trans>
      </HeaderTextStyle>
      {myStoreEnabled && (
        <PrivateComponent
          requiredRights={[UserRole.R2, UserRole.R3, UserRole.R3_MOTRIO]}
          render={() => (
            <Link to={ROUTER_MY_STORE_BUNDLES}>
              <Icon IconComponent={CogIcon} color={theme.color.grey85} height={22} width={23} />
            </Link>
          )}
        />
      )}
    </Flex>
  );
};

export function OtherTableHeader() {
  return (
    <HeaderTextStyle>
      <Trans i18nKey={'common.other'}>{'Other'}</Trans>
    </HeaderTextStyle>
  );
}
