/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import Loader from 'components/Loader';
import { toCreateInformationModel } from 'domains/information/Information.mapper';
import { useFetchInformation } from 'domains/information/Information.requests';
import { getInformationDetails, setCreateInformation } from 'domains/information/Information.store';
import { Empty } from 'UI';
import { hasData } from 'utils';
import { CreateInformation } from '../CreateInformation';

export const EditInformation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { informationId } = useParams<{ informationId: string }>();
  const information = useSelector((state: RootState) => getInformationDetails(state, informationId));

  useFetchInformation(informationId);

  useEffect(() => {
    if (!hasData(information)) return;
    dispatch(setCreateInformation(toCreateInformationModel({ information })));
  }, [dispatch, information]);

  return (
    <DataContainer
      data={information}
      Error={() => (
        <ErrorWithLabel label={t('common.backend_error', 'Data temporarily unavailable, please try again later.')} />
      )}
      Loading={() => <Loader height={'50vh'} />}
      NotFound={() => <Empty />}
    >
      <CreateInformation oldInformationState={toCreateInformationModel({ information })} />
    </DataContainer>
  );
};
