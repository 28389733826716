import styled from 'styled-components';
import { Flex, Text } from 'UI';

export const MaintenanceCard = styled(Flex)<{ active: boolean; data?: boolean }>`
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme, active }) => (active ? theme.color.info : theme.color.white)};
  padding: ${({ data }) => (data ? '30px 60px 30px 60px' : '30px 15px 30px 15px')};

  :hover {
    cursor: ${({ data }) => (data ? 'initial' : 'pointer')};
  }
`;

export const SText = styled(Text)`
  text-align: center;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.color.grey95};
  height: 54px;
  width: 100%;
`;

export const SectionContent = styled(Flex)`
  width: 100%;
`;

export const StickCard = styled(Flex)`
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.color.white};
  padding: 5px 75px;
`;

export const SBadgeCheckD = styled.div`
  position: relative;
  left: 7px;
  top: 12px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: '#356';
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 0px;
  margin-right: -25px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.blue};
`;
