import React from 'react';
import styled from 'styled-components';
import { Box, MarginBox, Text, YellowButton } from 'UI';
import { Left } from 'UI/Carousel/Carousel.styled';

export type BackgroundPositionCss = 'left' | 'center' | 'right' | 'top' | 'bottom' | string;
type Cursor = 'initial' | 'pointer';

const SBackground = styled.div<{
  height: number;
  backgroundPosition?: BackgroundPositionCss;
  cursor: Cursor;
}>`
  background-repeat: no-repeat;
  background-position: ${({ backgroundPosition }) => backgroundPosition ?? 'center'};
  background-size: cover;
  cursor: ${({ cursor }) => cursor};
`;

export interface BannerProps {
  image: string;
  title?: string;
  description?: string;
  buttonLabel?: string;
  onClick?: () => void;
  backgroundPosition?: BackgroundPositionCss;
  base64?: boolean;
  height?: number;
  onlyImage?: boolean;
  dark?: boolean;
  cursor?: Cursor;
}

export const Banner = ({
  image,
  title,
  description,
  buttonLabel,
  onClick,
  backgroundPosition,
  base64,
  height = 400,
  onlyImage = false,
  dark,
  cursor = 'initial',
}: BannerProps) => {
  const backgroundImage = { backgroundImage: `url("${base64 ? 'data:image/jpeg;base64,' : '/images/'}${image}")` };
  return (
    <SBackground
      backgroundPosition={backgroundPosition}
      onClick={onlyImage ? onClick : () => undefined}
      style={backgroundImage}
      height={height}
      cursor={cursor}
    >
      {!onlyImage && (
        <MarginBox ml={90}>
          <Left>
            <Box height={60} />
            <Text type={dark ? 'h1_banner_dark' : 'h1_banner_light'}>{title}</Text>
            <Text type={dark ? 'dark_14_white_100' : 'light_14_black_85'}>{description}</Text>
            <Box height={32} />
            {buttonLabel && <YellowButton onClick={onClick}>{buttonLabel}</YellowButton>}
          </Left>
        </MarginBox>
      )}
      <img alt="ads" src={`data:image/jpeg;base64,${image}`} style={{ visibility: 'hidden', width: '100%' }} />
    </SBackground>
  );
};
