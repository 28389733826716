import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TechnicalDataL1 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL1';
import { TechnicalDataL2 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL2';
import { TechnicalDataL3 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL3';
import { RootState } from 'app/AppStore';
import { useFetchIAMTechnicalDataDetails } from 'domains/catalog/Catalog.requests';
import {
  getVehicleTechnicalDataCategoryL3Data,
  getVehicleTechnicalDataCategoryL3Ids,
} from 'domains/catalog/Catalog.store';
import { INTERVALS_TAB, MaintenanceTabsType } from 'domains/catalog/Catalog.types';
import { Flex, MarginBox, Pipeline, Text } from 'UI';
import { MaintenanceCard, SectionContent, SectionHeader } from './MaintenancePlan.styled';

interface MaintenancePlanTableProps {
  tab: MaintenanceTabsType;
  technicalData: TechnicalDataL1 | undefined;
}

const MaintenancePlanTable = ({ tab, technicalData }: MaintenancePlanTableProps) => {
  const { t } = useTranslation();
  const categoryL3Ids = useSelector((state: RootState) =>
    getVehicleTechnicalDataCategoryL3Ids(state, technicalData?.id),
  );

  useFetchIAMTechnicalDataDetails(categoryL3Ids ?? [], technicalData?.id);

  if (!technicalData) {
    return <></>;
  }

  const L2Section = ({ l2 }: { l2: TechnicalDataL2 }) => {
    return (
      <>
        <SectionHeader>
          <Flex align={'center'} minHeight={54}>
            <Flex>
              <MarginBox mx={25}>
                <Text type={'h5_bold'} disableGutter>
                  {l2.label}
                </Text>
              </MarginBox>
            </Flex>
            {tab === INTERVALS_TAB && (
              <Flex justify={'center'}>
                <Text type={'h5_bold'} disableGutter>
                  {t('catalog.maintenance.carry_out', 'Carry out')}
                </Text>
              </Flex>
            )}
          </Flex>
        </SectionHeader>
        {l2.l3List.map((l3, index) => {
          const isLastLine = index === l2.l3List.length - 1;
          return <L3DataLine l3={l3} key={`${l3.id}-${index}`} isLastLine={isLastLine} />;
        })}
      </>
    );
  };

  return (
    <>
      <MaintenanceCard active={false} data>
        <Flex direction={'column'} align={'center'} justify={'center'}>
          {technicalData.l2List.map((l2, index) => {
            return <L2Section l2={l2} key={index} />;
          })}
        </Flex>
      </MaintenanceCard>
    </>
  );
};

const L3DataLine = ({ l3, isLastLine }: { l3: TechnicalDataL3; isLastLine?: boolean }) => {
  const l3categoryData = useSelector((state: RootState) => getVehicleTechnicalDataCategoryL3Data(state, l3.id));
  const l3DataRaw = l3categoryData?.technicalDataContentList;

  const isFirstValueWithoutVariant = l3DataRaw && l3DataRaw[0].variant === undefined;
  const firstValueWithoutVariant = isFirstValueWithoutVariant ? l3DataRaw[0] : undefined;
  const l3Data = l3DataRaw?.filter((l3d) => l3d.variant !== undefined);

  return (
    <>
      <SectionContent direction={'column'}>
        <Flex direction={'column'}>
          <Flex align={'center'} minHeight={54}>
            <Flex>
              <MarginBox mx={25}>
                <Text type={'h6_black'}>{`${l3.label}`}</Text>
              </MarginBox>
            </Flex>
            {firstValueWithoutVariant && (
              <React.Fragment>
                <Flex>
                  <MarginBox mx={15}>
                    <Text type={'light_14_bold_black_85'}>{`${firstValueWithoutVariant.value} ${
                      firstValueWithoutVariant.unit ?? ''
                    }`}</Text>
                  </MarginBox>
                </Flex>
                {firstValueWithoutVariant.additionalInformation && (
                  <Flex>
                    <MarginBox mx={15}>{firstValueWithoutVariant.additionalInformation}</MarginBox>
                  </Flex>
                )}
              </React.Fragment>
            )}
          </Flex>
        </Flex>
        {l3Data?.map((l3d, index) => {
          return (
            <React.Fragment key={index}>
              <Flex direction={'column'}>
                <Flex align={'center'} minHeight={54}>
                  <Flex>
                    <MarginBox mx={25}>
                      <Text type={'light_14_black_85'}>{`${l3d.variant ?? ''}`}</Text>
                    </MarginBox>
                  </Flex>
                  <Flex justify={'center'}>
                    <Flex>
                      <MarginBox mx={15}>
                        <Text type={'light_14_bold_black_85'}>{`${l3d.value} ${l3d.unit ?? ''}`}</Text>
                      </MarginBox>
                    </Flex>
                    {l3d.additionalInformation && (
                      <Flex>
                        <MarginBox mx={15}>{l3d.additionalInformation}</MarginBox>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </React.Fragment>
          );
        })}
        {!isLastLine && <Pipeline size={'100%'} horizontal />}
      </SectionContent>
    </>
  );
};

export default MaintenancePlanTable;
