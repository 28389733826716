import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { useLoadMoreCurrentPromotions } from 'domains/promotion/Promotion.requests';
import { getCurrentPromotionsData } from 'domains/promotion/Promotion.store';
import PromotionCard from 'pages/PromotionPage/PromotionCardsContainer/PromotionCard';
import { Box, CenterFlex, Grid, InfiniteScroll, MarginBox, Spin, Text } from 'UI';
import { ERROR, LOADING, NO_DATA, NOT_FOUND, useBreakpointSelectorFull } from 'utils';

const PromotionsNoData = ({ status }: { status: NO_DATA }) => {
  const { t } = useTranslation();
  const renderStatusComponent = () => {
    switch (status) {
      case LOADING:
        return <Spin />;
      case NOT_FOUND:
        return (
          <>
            <Text type={'h2'}>
              {t('promotion.page.promotions.currently_unavailable', 'No promotions currently available,')}
            </Text>
            <Text type={'h2'}>
              {t('promotion.page.promotions.currently_unavailable.description', 'please come back later.')}
            </Text>
          </>
        );
      case ERROR:
        return <ErrorWithLabel />;
      default:
        <></>;
    }
  };
  return (
    <CenterFlex>
      <Box height={'20vh'} align={'center'}>
        {renderStatusComponent()}
      </Box>
    </CenterFlex>
  );
};

const PromotionCardsContainer = () => {
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const promotionsData = useSelector(getCurrentPromotionsData);
  const { promotions, loadMore } = useLoadMoreCurrentPromotions();

  return (
    <DataContainer
      data={promotions.status}
      NotFound={() => <PromotionsNoData status={NOT_FOUND} />}
      Error={() => <PromotionsNoData status={ERROR} />}
      Loading={() => <PromotionsNoData status={LOADING} />}
      Skeleton={() => <PromotionsNoData status={undefined} />}
    >
      <InfiniteScroll loadMore={loadMore} hasMore={promotions.hasNextPage}>
        {promotionsData && promotionsData.length > 0 ? (
          <Grid
            columns={breakpointSelectorFull({
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 3,
            })}
            gutter={[
              [30, 30],
              [30, 30],
            ]}
          >
            {promotionsData.map((promotion) => (
              <PromotionCard key={promotion.promotionId} promotion={promotion} />
            ))}
          </Grid>
        ) : (
          <PromotionsNoData status={NOT_FOUND} />
        )}
      </InfiniteScroll>
      <MarginBox mt={200} />
    </DataContainer>
  );
};
export default PromotionCardsContainer;
