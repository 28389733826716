/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryResponse';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Popover } from 'antd';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import { useDebouncedCallback } from 'use-debounce';
import { ROUTER_CART, ROUTER_CATALOG_VEHICLE, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CarIcon, TrashAltIcon, UnlinkIcon } from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { Dialog, useDisclosure } from 'components/Dialog';
import { calculateDiscountPrice, DiscountCorner, getPromotionColor } from 'components/Discount/Discount';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import QuantityBox from 'components/QuantityBox';
import { PriceErrorDisplay } from 'components/ReferencePriceSection';
import StockDisplay from 'components/StockInfo';
import { useIsOilChunkUser } from 'domains/appContext/AppContext.requests';
import { get750ChunkReferences, getChunkReferences } from 'domains/appContext/AppContext.store';
import {
  attachOtherReferenceToVehicleRequest,
  clearActionStatuses,
  getBasketExternalSection,
  getBasketId,
  getBasketOtherSection,
  getBasketVehicles,
  removeSingleReferenceRequest,
  setBasket,
  setFileReferencesUploadStatusErrorRows,
  setReferencesIsSelected,
  updateReferenceMarkRequest,
  updateReferenceQuantity,
  updateReferenceQuantityRequest,
  updateReferenceUrgencyFlag,
  updateUrgencyFlagRequest,
} from 'domains/basket/Basket.store';
import {
  ExternalBasketSectionLocal,
  OtherSectionLocal,
  ReferenceLocal,
  VehicleLocal,
} from 'domains/basket/Basket.types';
import { IAM, STANDARD } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getDiscount, getPrice, getStockInfo } from 'domains/references';
import { getCurrency, getUserCommercialLink } from 'domains/user';
import {
  Box,
  Checkbox,
  ColoredText,
  Flex,
  Icon,
  Link,
  MarginBox,
  notifyTop,
  PencilRoundButton,
  Spin,
  Text,
  TrashAltButton,
  WithTooltip,
} from 'UI';
import { getData, isQueryBMM, NOT_FOUND } from 'utils';
import { textFormatter } from 'utils/format';
import {
  AttachToVehicleContent,
  AttachToVehicleHeader,
  ExternalStrip,
  OverlayBox,
  RefInput,
  SPopover,
  STable,
  TireNameContainer,
  TooltipContent,
} from './CartReferenceTable.styled';

const getBasketReferenceType = (vehicleKey: string | undefined, externalBasketId: string | undefined) => {
  return vehicleKey ? 'VEHICLE' : externalBasketId ? 'EXTERNAL_BASKET' : 'OTHER';
};

const Stock = ({
  referenceNumber,
  vehicleKey,
  disabledRefs,
  setDisableRefs,
}: {
  referenceNumber: string;
  vehicleKey: string | undefined;
  disabledRefs: string[];
  setDisableRefs: (ref: string) => void;
}) => {
  const stockInfo = useSelector((state: RootState) => getStockInfo(state, referenceNumber));
  if (stockInfo?.searchStatus === NOT_FOUND && !disabledRefs.includes(referenceNumber)) {
    setDisableRefs(referenceNumber);
  }

  return (
    <Flex>
      <StockDisplay vehicleKey={vehicleKey} referenceNumber={referenceNumber} isApplicableToCurrentVehicle narrow />
    </Flex>
  );
};

const Selected = ({
  isSelected,
  reference,
  vehicleKey,
  externalBasketId,
}: {
  isSelected: boolean;
  reference: ReferenceLocal;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  disabledRefs: string[];
}) => {
  const dispatch = useDispatch();
  const vehicles = useSelector(getBasketVehicles);
  const otherSection = useSelector(getBasketOtherSection);
  const externalBaskets = useSelector(getBasketExternalSection);
  const basketId = useSelector(getBasketId);

  const handleCheckChange = () => {
    if (vehicleKey) {
      const thisVehicle = vehicles?.find((vehicle) => vehicle?.vehicleDetail?.vehicleKey === vehicleKey);
      const newThisVehicle = {
        ...thisVehicle,
        references: thisVehicle?.references?.map((ref) =>
          ref.referenceNumber === reference.referenceNumber ? { ...ref, isSelected: !ref.isSelected } : ref,
        ),
      } as VehicleLocal;
      const newVehicles = vehicles?.map((vehicle) =>
        vehicle?.vehicleDetail?.vehicleKey === vehicleKey ? newThisVehicle : vehicle,
      );

      dispatch(
        setBasket({
          basketId: basketId ?? '',
          vehicles: newVehicles,
          otherSection,
          externalBaskets,
        }),
      );
    } else if (externalBasketId) {
      const thisExternal = externalBaskets?.find((extBasket) => extBasket.externalBasketId === externalBasketId);
      const newThisExternal = {
        ...thisExternal,
        references: thisExternal?.references?.map((ref) =>
          ref.referenceNumber === reference.referenceNumber ? { ...ref, isSelected: !ref.isSelected } : ref,
        ),
      } as ExternalBasketSectionLocal;
      const newExternals = externalBaskets?.map((external) =>
        external.externalBasketId === externalBasketId ? newThisExternal : external,
      );

      dispatch(
        setBasket({
          basketId: basketId ?? '',
          vehicles,
          otherSection,
          externalBaskets: newExternals,
        }),
      );
    } else {
      const newRef =
        otherSection?.references.map((ref) =>
          ref.referenceNumber === reference.referenceNumber ? { ...ref, isSelected: !ref.isSelected } : ref,
        ) ?? [];
      const newOther: OtherSectionLocal = {
        orderMark: otherSection?.orderMark ?? '',
        references: newRef,
      };

      dispatch(
        setBasket({
          basketId: basketId ?? '',
          vehicles,
          otherSection: newOther,
          externalBaskets,
        }),
      );
    }
  };

  return <Checkbox checked={isSelected} onChange={handleCheckChange} />;
};

const BasketQuantityInput = ({
  currentQuantity,
  vehicleKey,
  externalBasketId,
  reference,
  markRemovedReferences,
}: {
  currentQuantity: number;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  reference: ReferenceLocal;
  markRemovedReferences: (numberOfReferences: number) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stock = useSelector((state: RootState) => getStockInfo(state, reference.referenceNumber));
  const chunkReferences = useSelector(getChunkReferences);
  const chunk750References = useSelector(get750ChunkReferences);
  const isOilUser = useIsOilChunkUser();

  const onChangeQuantity = useCallback(
    (quantity: number | string) => {
      if (quantity === 0) {
        markRemovedReferences(1);
        dispatch(
          removeSingleReferenceRequest({
            vehicleKey,
            externalBasketId,
            reference: reference.referenceNumber,
            basketReferenceType: getBasketReferenceType(vehicleKey, externalBasketId),
          }),
        );
      } else {
        dispatch(
          updateReferenceQuantityRequest({
            vehicleKey,
            externalBasketId,
            referenceNumber: reference.referenceNumber,
            newQuantity: quantity as number,
            basketReferenceType: getBasketReferenceType(vehicleKey, externalBasketId),
            origin: reference.origin,
            supplierCode: reference.supplierCode,
            referenceSource: reference.referenceSource,
            referenceType: reference.type ?? STANDARD,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, externalBasketId, markRemovedReferences, reference.referenceNumber, vehicleKey],
  );

  const onMaxValueOverflow = useCallback(() => {
    if (stock?.data?.maxConfirmedQuantity !== undefined) {
      dispatch(
        updateReferenceQuantity({
          externalBasketId,
          vehicleKey,
          referenceNumber: reference.referenceNumber,
          newQuantity: stock?.data?.maxConfirmedQuantity,
        }),
      );
    }
    notifyTop(
      'info',
      t(
        'catalog.reference.stock.notification.parts_unavailable',
        'Sorry, Looks like the desired number of parts is currently unavailable.',
      ),
    );
  }, [dispatch, reference.referenceNumber, stock?.data?.maxConfirmedQuantity, t, vehicleKey, externalBasketId]);

  const getChunk = () => {
    if (stock?.data?.warehouses.some((wh) => wh.type === 'LOCAL')) {
      return 1;
    }
    return isOilUser ? chunkReferences.get(reference.referenceNumber) : undefined;
  };

  return (
    <QuantityBox
      value={currentQuantity}
      onChange={onChangeQuantity}
      maxValue={stock?.data?.maxConfirmedQuantity}
      minValue={1}
      onMaxValueOverflow={onMaxValueOverflow}
      chunk={getChunk()}
      isMin750Chunk={isOilUser && chunk750References.includes(reference.referenceNumber)}
    />
  );
};

function TrashButton({
  referenceNumber,
  vehicleKey,
  externalBasketId,
  markRemovedReferences,
}: {
  referenceNumber: string;
  markRemovedReferences: (numberOfReferences: number) => void;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vehicles = useSelector(getBasketVehicles);

  // ToDo - refactor to the global store
  const referenceCount = vehicles?.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)?.references
    ?.length;

  const disclosure = useDisclosure();
  const { onOpen } = disclosure;

  const handleConfirm = () => {
    dispatch(clearActionStatuses());
    dispatch(setFileReferencesUploadStatusErrorRows([]));
    dispatch(
      removeSingleReferenceRequest({
        basketReferenceType: getBasketReferenceType(vehicleKey, externalBasketId),
        vehicleKey,
        externalBasketId,
        reference: referenceNumber,
      }),
    );
    markRemovedReferences(1);
  };

  function handleRemoveButton() {
    dispatch(
      setReferencesIsSelected({
        isSelected: true,
        referenceIds: [referenceNumber],
        vehicleKey,
      }),
    );
    if (vehicleKey && referenceCount === 1) {
      onOpen();
    } else {
      dispatch(
        removeSingleReferenceRequest({
          basketReferenceType: getBasketReferenceType(vehicleKey, externalBasketId),
          vehicleKey,
          externalBasketId: externalBasketId,
          reference: referenceNumber,
        }),
      );
      markRemovedReferences(1);
    }
  }

  return (
    <div id={'trash_button_select'}>
      <TrashAltButton onClick={handleRemoveButton} />
      <Dialog
        disclosure={disclosure}
        title={t('common.dialog.actionRequired', 'Action required')}
        description={[
          t('cart.action.removalText1', 'Deleting this reference will delete your vehicle basket.'),
          t('common.dialog.confirmationQuestion', 'Would you like to confirm?'),
        ]}
        icon={TrashAltIcon}
        status={'info'}
        handleConfirm={handleConfirm}
      />
    </div>
  );
}

const AttachToVehicle = ({ vehicles, referenceNumber }: { vehicles: VehicleLocal[]; referenceNumber: string }) => {
  const [activeSelecting, setActiveSelecting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleAttach(vehicleKey: string) {
    dispatch(attachOtherReferenceToVehicleRequest({ vehicleKey, referenceNumber }));
  }

  const getVehicleInfo = (vehicleDetail: VehicleDetail) => {
    switch (vehicleDetail.catalogSource) {
      case 'DATAHUB':
        return `${vehicleDetail.dataHubVehicle?.name ?? ''} ${
          !isQueryBMM(vehicleDetail.vehicleIdentifiedBy) ? vehicleDetail.vehicleIdentifiedBy : ''
        }`;
      case 'IAM':
        return `${vehicleDetail.vehicleBrand ?? vehicleDetail.iamVehicle?.name ?? ''} ${
          vehicleDetail.iamVehicle?.modelType ?? ''
        } ${!isQueryBMM(vehicleDetail.vehicleIdentifiedBy) ? vehicleDetail.vehicleIdentifiedBy : ''}`;
    }
  };

  return (
    <SPopover
      afterVisibleChange={(visible) => setActiveSelecting(visible)}
      trigger={'click'}
      placement={'rightTop'}
      zIndex={200}
      content={
        <MarginBox>
          <Flex direction={'column'}>
            <AttachToVehicleHeader>
              <Flex minHeight={45} align={'center'}>
                <Text type={'text_dim'}>{t('cart.action.attachToVehicle', 'Attach reference to vehicle')}</Text>
              </Flex>
            </AttachToVehicleHeader>
            {vehicles.map((vehicle) => (
              <AttachToVehicleContent
                key={vehicle.vehicleDetail.vehicleKey}
                onClick={() => handleAttach(vehicle.vehicleDetail.vehicleKey)}
                align={'center'}
              >
                <Text type={'light_14_black_65'}>{getVehicleInfo(vehicle.vehicleDetail)}</Text>
              </AttachToVehicleContent>
            ))}
          </Flex>
        </MarginBox>
      }
    >
      <WithTooltip title={t('cart.action.link_ref_to_exist_vehicle', 'Link a ref to an existing vehicle')}>
        <Icon IconComponent={CarIcon} size={24} color={activeSelecting ? 'black' : 'grey'} />
      </WithTooltip>
    </SPopover>
  );
};

const ReferenceMark = ({
  vehicleKey,
  externalBasketId,
  referenceNumber,
  referenceMark,
}: {
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  referenceNumber: string;
  referenceMark: string | undefined;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRefMarkUpdate = useDebouncedCallback((value) => {
    dispatch(
      updateReferenceMarkRequest({
        basketReferenceType: getBasketReferenceType(vehicleKey, externalBasketId),
        vehicleKey,
        externalBasketId,
        referenceNumber,
        referenceMark: value,
      }),
    );
  }, 500);

  return (
    <Popover
      content={
        <RefInput
          initialValue={referenceMark}
          onChange={(value) => {
            handleRefMarkUpdate(value);
          }}
          placeholder={t('order.add_reference_mark', 'Type your reference mark here...')}
          bordered
          showCount
          maxLength={17}
        />
      }
      trigger={'click'}
      placement={'bottomLeft'}
      zIndex={200}
    >
      <PencilRoundButton
        bgColor={referenceMark ? 'selected' : 'grey95'}
        color={referenceMark ? 'white' : 'brand_black'}
        hoverColor={'brand_black'}
        tooltip={t('order.add_reference_mark.add_comment', 'Add comment')}
      />
    </Popover>
  );
};

const ProductPrice = ({ reference, currency }: { reference: ReferenceLocal; currency?: string }) => {
  const price = useSelector((state: RootState) => getPrice(state, reference.referenceNumber));
  const priceData = getData(price);
  return (
    <DataContainer data={price} Loading={() => <Spin size={'small'} />} Error={() => <PriceErrorDisplay narrow />}>
      <Text type={'text_dim'}>
        {textFormatter.formatCurrency(priceData?.clientView?.recommendedPriceVatExcluded ?? 0, currency)}
      </Text>
    </DataContainer>
  );
};

const Discount = ({ referenceNumber }: { referenceNumber: string }) => {
  const { t } = useTranslation();
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));
  const priceData = getData(price);

  return (
    <DataContainer
      data={price}
      Loading={() => <Spin size={'small'} />}
      Error={() => (
        <ErrorWithLabel
          label={t('common.price.discount.backend_error', 'Discount temporarily unavailable, please try again later.')}
          narrow
        />
      )}
    >
      <Text type={'text_dim'}>{textFormatter.formatPercentDecimal(Number(priceData?.garageView?.discountRate))}</Text>
    </DataContainer>
  );
};
const PriceWithDiscounts = ({
  reference,
  currency,
  showTotal,
}: {
  reference: ReferenceLocal;
  currency?: string;
  showTotal?: boolean;
}) => {
  const discount = useSelector((state: RootState) => getDiscount(state, reference.referenceNumber));
  const price = useSelector((state: RootState) => getPrice(state, reference.referenceNumber));
  const priceData = getData(price);

  const discountPrice = discount
    ? calculateDiscountPrice(discount, getData(price))
    : Number(priceData?.garageView?.vatExcludedPrice ?? 0);
  const totalPrice = showTotal ? discountPrice * reference.quantity : discountPrice;

  return (
    <DataContainer data={price} Loading={() => <Spin size={'small'} />} Error={() => <PriceErrorDisplay narrow />}>
      <ColoredText
        type={'text_dim'}
        color={getPromotionColor({
          staticType: undefined,
          referencePromotionType: discount?.promotionType,
        })}
      >
        {textFormatter.formatCurrency(totalPrice, currency)}
      </ColoredText>
    </DataContainer>
  );
};

const ProductNameView = ({
  referenceType,
  name,
  brand,
  tireDimensions,
  catalogSource,
}: {
  referenceType: string;
  name: string;
  brand?: string;
  tireDimensions?: string;
  catalogSource?: CatalogSource;
}) => {
  if (referenceType === STANDARD) {
    return (
      <Text
        cursor={catalogSource !== IAM ? 'pointer' : 'initial'}
        type={'text_bold'}
        transform={'capitalize'}
        hoverUnderLine={catalogSource !== IAM}
      >
        {name}
      </Text>
    );
  }
  return (
    <TireNameContainer direction={'column'}>
      <WithTooltip title={`${brand ?? ''} ${tireDimensions ? `- ${tireDimensions}` : ''} ${name ?? ''}`}>
        <Flex direction={'row'}>
          <Text type={'text_bold'}>{brand}</Text>
          <MarginBox mr={3} />
          {name && <Text type={'text'} ellipsis noWrap>{`- ${name}`}</Text>}
        </Flex>
        {tireDimensions && <Text type={'text'}>{tireDimensions}</Text>}
      </WithTooltip>
    </TireNameContainer>
  );
};

const dataColumns = (
  dispatch: Dispatch,
  t: TFunction,
  vehicleKey: string | undefined,
  externalBasketId: string | undefined,
  markRemovedReferences: (numberOfReferences: number) => void,
  vehicles: VehicleLocal[],
  disabledRefs: string[],
  setDisableRefs: (ref: string) => void,
  currency?: string,
  catalogSource?: CatalogSource,
) => [
  {
    title: <>{externalBasketId ? <ExternalStrip /> : ''}</>,
    dataIndex: 'isSelected',
    key: 'rowSelection',
    render: function rowSelector(isSelected: boolean, reference: ReferenceLocal) {
      return (
        <>
          <DiscountCorner reference={reference.referenceNumber} type={'cart'} />
          <OverlayBox>
            <Selected
              reference={reference}
              isSelected={isSelected}
              vehicleKey={vehicleKey}
              externalBasketId={externalBasketId}
              disabledRefs={disabledRefs}
            />
          </OverlayBox>
        </>
      );
    },
  },
  {
    title: t('cart.reference_table.product', 'Product'),
    dataIndex: 'product',
    key: 'product',
    render: function renderProductName(_text: string, reference: ReferenceLocal) {
      const redirectUrl =
        vehicleKey && catalogSource
          ? `${getCatalogSourceUrl(catalogSource)}/${vehicleKey}${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}/${
              reference.referenceNumber
            }`
          : `${ROUTER_PRODUCT}/${reference.referenceNumber}`;
      if (catalogSource === IAM) {
        return (
          <ProductNameView
            referenceType={reference.type}
            name={reference.name}
            brand={reference.brand}
            tireDimensions={reference.tireDimensions}
            catalogSource={catalogSource}
          />
        );
      }
      return (
        <Link to={redirectUrl}>
          <ProductNameView
            referenceType={reference.type}
            name={reference.name}
            brand={reference.brand}
            tireDimensions={reference.tireDimensions}
            catalogSource={catalogSource}
          />
        </Link>
      );
    },
  },
  {
    title: t('cart.reference_table.reference', 'Reference'),
    dataIndex: 'referenceNumber',
    key: 'referenceNumber',
    render: function renderReferenceNumber(referenceNumber: string, reference: ReferenceLocal, index: number) {
      const getAttachToVehicle = () => {
        if (index === 0) {
          return (
            <FirstHelpPopin streamId={ROUTER_CART} popinId={`${ROUTER_CART}_link_to_veh`} placement={'right'}>
              <AttachToVehicle vehicles={vehicles} referenceNumber={referenceNumber} />
            </FirstHelpPopin>
          );
        }
        return <AttachToVehicle vehicles={vehicles} referenceNumber={referenceNumber} />;
      };

      return (
        <div id={`cart-reference-row-${referenceNumber}`}>
          <Flex align={'center'}>
            <Text type={'text_dim'}>{referenceNumber}</Text>
            {!reference.isCompatible && !externalBasketId && (
              <>
                <MarginBox ml={15} />
                <WithTooltip
                  title={t('estimate.reference.noncompatible', 'Not compatible with selected vehicle')}
                  placement={'top'}
                >
                  <TooltipContent>
                    <Flex size={0} align={'center'} justify={'center'}>
                      <Icon IconComponent={UnlinkIcon} size={16} color={'black'} display={'inline'} />
                    </Flex>
                  </TooltipContent>
                </WithTooltip>
              </>
            )}
            {!vehicleKey && !externalBasketId && vehicles.length > 0 && (
              <Flex>
                <Box width={15} />
                {getAttachToVehicle()}
              </Flex>
            )}
          </Flex>
        </div>
      );
    },
  },
  {
    title: t('cart.reference_table.stock', 'Stock'),
    dataIndex: 'referenceNumber',
    key: 'stock',
    render: function renderStock(referenceNumber: string) {
      return (
        <Stock
          referenceNumber={referenceNumber}
          vehicleKey={vehicleKey}
          disabledRefs={disabledRefs}
          setDisableRefs={setDisableRefs}
        />
      );
    },
  },
  {
    title: t('common.price.pcl', 'PCL'),
    dataIndex: 'pclPrice',
    key: 'pclPrice',
    render: function renderPrice(_text: string, reference: ReferenceLocal) {
      return <ProductPrice reference={reference} currency={currency} />;
    },
  },
  {
    title: t('cart.reference_table.discount', 'Discount'),
    dataIndex: 'discount',
    key: 'discount',
    render: function renderPrice(_text: string, reference: ReferenceLocal) {
      return <Discount referenceNumber={reference?.referenceNumber} />;
    },
  },
  {
    title: t('common.price.pnm', 'Price PNM'),
    dataIndex: 'pnmPrice',
    key: 'pnmPrice',
    width: 90,
    render: function renderPrice(_text: string, reference: ReferenceLocal) {
      return <PriceWithDiscounts reference={reference} currency={currency} />;
    },
  },
  {
    title: t('order.urgent_delivery', 'Urgent delivery'),
    dataIndex: 'urgentDelivery',
    key: 'urgentDelivery',
    render: function renderUrgentDelivery(isUrgent: boolean | undefined, ref: ReferenceLocal) {
      const priceToDisplay = isNaN(Number(ref.price?.vatExcludedUrgentDeliveryAdditionalPrice))
        ? ref.price.priceVatExcluded
        : Number(ref.price.vatExcludedUrgentDeliveryAdditionalPrice) * ref.quantity;
      return (
        <Flex>
          <Checkbox
            checked={ref.isUrgent}
            onChange={() => {
              dispatch(
                updateUrgencyFlagRequest({
                  basketReferenceType: getBasketReferenceType(vehicleKey, externalBasketId),
                  vehicleKey,
                  externalBasketId,
                  referenceNumber: ref.referenceNumber,
                  isUrgent: !ref.isUrgent,
                }),
              );
              dispatch(
                updateReferenceUrgencyFlag({
                  vehicleKey,
                  externalBasketId,
                  referenceNumbers: [ref.referenceNumber],
                  isUrgent: !ref.isUrgent,
                }),
              );
            }}
          />
          <Box width={18} />
          {textFormatter.formatCurrency(priceToDisplay, currency)}
        </Flex>
      );
    },
  },
  {
    title: t('common.quantity', 'Quantity'),
    dataIndex: 'quantity',
    key: 'quantity',
    render: function renderQuantity(currentQuantity: number, ref: ReferenceLocal) {
      return (
        <Flex justify={'center'}>
          <BasketQuantityInput
            currentQuantity={currentQuantity}
            vehicleKey={vehicleKey}
            externalBasketId={externalBasketId}
            reference={ref}
            markRemovedReferences={markRemovedReferences}
          />
        </Flex>
      );
    },
  },
  {
    title: t('common.price.VAT_excl_price', 'VAT Excl. Price'),
    dataIndex: 'priceVatExcluded',
    key: 'vatExclPrice',
    render: function renderPrice(_text: string, reference: ReferenceLocal) {
      return <PriceWithDiscounts reference={reference} currency={currency} showTotal />;
    },
  },
  {
    title: '',
    dataIndex: 'referenceNumber',
    key: 'referenceActions',
    render: function renderActions(referenceNumber: string, reference: ReferenceLocal, index: number) {
      const getReferenceMark = () => {
        if (
          (vehicles.length > 0 && vehicles[0].vehicleDetail.vehicleKey === vehicleKey && index === 0) ||
          (vehicles.length === 0 && !vehicleKey && index === 0)
        ) {
          return (
            <FirstHelpPopin streamId={ROUTER_CART} popinId={`${ROUTER_CART}_add_comment`} placement={'bottom'}>
              <ReferenceMark
                vehicleKey={vehicleKey}
                referenceNumber={referenceNumber}
                externalBasketId={externalBasketId}
                referenceMark={reference.note}
              />
            </FirstHelpPopin>
          );
        }
        return (
          <ReferenceMark
            vehicleKey={vehicleKey}
            externalBasketId={externalBasketId}
            referenceNumber={referenceNumber}
            referenceMark={reference.note}
          />
        );
      };

      return (
        <Flex>
          <Box width={33}>{getReferenceMark()}</Box>
          <Box width={12} />
          <TrashButton
            referenceNumber={referenceNumber}
            vehicleKey={vehicleKey}
            externalBasketId={externalBasketId}
            markRemovedReferences={markRemovedReferences}
          />
        </Flex>
      );
    },
  },
];

interface CartReferenceTableProps {
  vehicleKey?: string;
  externalBasketId?: string;
  markRemovedReferences: (numberOfReferences: number) => void;
  disabledRefs: string[];
  highlightedReference: string | undefined;
  setDisableRefs: (ref: string) => void;
}

const CartReferenceTable = ({
  vehicleKey,
  externalBasketId,
  markRemovedReferences,
  disabledRefs,
  highlightedReference,
  setDisableRefs,
}: CartReferenceTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const commercialLink = useSelector(getUserCommercialLink);
  const vehicles = useSelector(getBasketVehicles);
  const otherSection = useSelector(getBasketOtherSection);
  const externalBaskets = useSelector(getBasketExternalSection);
  const currency = useSelector(getCurrency);
  const vehicleReferences = vehicles?.find((vehicle) => vehicle?.vehicleDetail?.vehicleKey === vehicleKey)?.references;
  const vehicleCatalogSource = vehicles?.find((vehicle) => vehicle?.vehicleDetail?.vehicleKey === vehicleKey)
    ?.vehicleDetail?.catalogSource;
  const otherReferences = otherSection?.references;
  const externalReferences = externalBaskets?.find((extBasket) => extBasket?.externalBasketId === externalBasketId)
    ?.references;

  const columns = dataColumns(
    dispatch,
    t,
    vehicleKey,
    externalBasketId,
    markRemovedReferences,
    vehicles,
    disabledRefs,
    setDisableRefs,
    currency,
    vehicleCatalogSource,
  );

  return (
    <STable<ReferenceLocal>
      rowKey={(row) => row.referenceNumber}
      columns={
        commercialLink !== null && commercialLink.canPlaceUrgentOrders
          ? columns
          : columns.filter((column) => column.dataIndex !== 'urgentDelivery')
      }
      dataSource={
        vehicleKey
          ? vehicleReferences?.map((vehicleRef) => ({ ...vehicleRef, key: vehicleRef.referenceNumber }))
          : externalBasketId
          ? externalReferences?.map((extRef) => ({ ...extRef, key: extRef.referenceNumber }))
          : otherReferences?.map((otherRef) => ({ ...otherRef, key: otherRef.referenceNumber }))
      }
      rowClassName={(record) =>
        `${disabledRefs.includes(record.referenceNumber) ? 'disabled-row' : ''} ${
          record.referenceNumber === highlightedReference && 'highlighted-row'
        }`
      }
      pagination={false}
      data-cy={'reference-table'}
    />
  );
};

export default CartReferenceTable;
