import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  IamCatalogBrandsType,
  IAMCatalogSourceParam,
  OEM_BRAND_KEY,
  OTHER_BRAND_KEY,
} from 'domains/catalog/Catalog.types';
import { isValidIamCatalogType } from 'domains/catalog/Catalog.utils';
import { getIamCatalogBrandsView, setIamCatalogBrandsView } from 'domains/user';
import { SBrandsSwitch, SSwitchButton } from 'pages/CatalogPage/IAM/BrandsSwitch/BrandsSwitch.styled';
import { BaseIconButtonProps, MarginBox, TagButtonRound, TagsButtonRound, Text } from 'UI';

export const BrandsSwitch = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const urlStoreCatalogSourceRaw = params.get(IAMCatalogSourceParam);
  const storeCatalogSource = useSelector(getIamCatalogBrandsView);
  const selectedIamCatalogBrandsView = isValidIamCatalogType(urlStoreCatalogSourceRaw)
    ? urlStoreCatalogSourceRaw
    : storeCatalogSource;

  useEffect(() => {
    if (!isValidIamCatalogType(urlStoreCatalogSourceRaw)) {
      params.set(IAMCatalogSourceParam, selectedIamCatalogBrandsView);
      history.replace(`${location.pathname}?${params}`);
    }
    if (storeCatalogSource !== selectedIamCatalogBrandsView) {
      dispatch(setIamCatalogBrandsView(selectedIamCatalogBrandsView));
    }
  }, [dispatch, history, params, selectedIamCatalogBrandsView, storeCatalogSource, urlStoreCatalogSourceRaw]);

  return (
    <SBrandsSwitch>
      <SwitchButton
        value={OTHER_BRAND_KEY}
        label={t('catalog.parts.category.other_brand', 'Other Brand')}
        icon={TagButtonRound}
      />
      <MarginBox ml={30} />
      <SwitchButton
        value={OEM_BRAND_KEY}
        label={t('catalog.parts.category.oem_brands', 'OEM brands')}
        icon={TagsButtonRound}
      />
    </SBrandsSwitch>
  );
};

interface SwitchButtonProps {
  value: IamCatalogBrandsType;
  label: string;
  icon: React.FunctionComponent<BaseIconButtonProps>;
}

const SwitchButton = (props: SwitchButtonProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Icon = props.icon;
  const storeCatalogSource = useSelector(getIamCatalogBrandsView);
  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  function onClick() {
    params.set(IAMCatalogSourceParam, props.value);
    dispatch(setIamCatalogBrandsView(props.value));
    history.replace(`${location.pathname}?${params}`);
  }

  return (
    <SSwitchButton direction={'row'} align={'center'} onClick={onClick} isSelected={storeCatalogSource === props.value}>
      <Icon
        size={48}
        boxSize={'xl'}
        color={'grey_light'}
        bgColor={'brand_black'}
        hoverColor={'grey_light'}
        hoverFill={'brand_black'}
      />
      <MarginBox ml={30} />
      <Text type={'h4_paragraph_title'} cursor={'pointer'} transform={'sentence-case'}>
        {props.label}
      </Text>
    </SSwitchButton>
  );
};
