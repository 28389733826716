import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import { EyeIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { useDecodeImage } from 'domains/catalog/Catalog.hooks';
import { findPlateUrlInVehicleTree, getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { DATAHUB, HighlightIndex, PARAM_STANDARD, PlateViewModeParam } from 'domains/catalog/Catalog.types';
import { useGetCatalogSourceUrlFromLocation } from 'domains/catalog/Catalog.utils';
import { useFetchUrlImage } from 'domains/pictures/Pictures.requests';
import { getPicture } from 'domains/pictures/Pictures.store';
import { getDHReferences } from 'domains/references';
import { Box, CenteredSpin, defaultBoxShadow, Flex, Icon, Link, MarginBox, Text } from 'UI';
import { getData } from 'utils';
import { svgElementPlateId } from 'utils/svg/common';
import { manipulateLinesInSvg } from 'utils/svg/manipulatePlateSvg';
import { SSvgFlex, SvgDiv } from '../../PlateDetailCardImage/PlateDetailCardImage.styled';
import { EmptyOrErrorPlate } from '../../PlateThumbnailSection/EmptyOrErrorPlate/EmptyOrErrorPlate';

const SContainer = styled.div`
  cursor: pointer;
  border-radius: 12px;

  :hover {
    background-color: ${({ theme }) => theme.color.grey85};
  }

  padding: 0 5px 0 5px;
`;

export const FloatingContainer = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 700px;
  background-color: white;
  padding: 5px 5px 5px 10px;
  overflow-x: auto;
  z-index: 801;
  position: absolute;
  border-radius: 8px;
  ${defaultBoxShadow}
`;

export const LinkToPlate = ({ link, children }: { link: string | undefined; children?: ReactNode }) =>
  link ? <Link to={link}>{children}</Link> : <>{children}</>;

export const FloatingImage = ({ id, plateId, index }: { id: string; plateId: string; index: number | undefined }) => {
  const catalogSourceUrl = useGetCatalogSourceUrlFromLocation();
  const imageBase64 = useSelector((state: RootState) => getPicture(state, id));
  const svgElement = useDecodeImage(imageBase64);
  const platePath = useSelector((state: RootState) => findPlateUrlInVehicleTree(state, plateId));
  const [highlightedIndex, setHighlightedIndex] = useState<string | null>(null);
  useFetchUrlImage(id, DATAHUB);

  const link = platePath
    ? `${catalogSourceUrl}/${platePath}` + (index ? `&${HighlightIndex}=${index?.toString()}` : '')
    : undefined;

  useEffect(() => {
    manipulateLinesInSvg(`${plateId}-${index}`, undefined, highlightedIndex, svgElementPlateId);
  }, [highlightedIndex, imageBase64, index, plateId, setHighlightedIndex]);

  useEffect(() => {
    setTimeout(() => {
      if (index) {
        setHighlightedIndex(String(index));
      }
    }, 500);
  });

  return (
    <>
      <Box maxHeight={600} width={400} align={'center'}>
        <DataContainer
          data={svgElement}
          Skeleton={() => (
            <LinkToPlate link={link}>
              <Box height={300}>
                <CenteredSpin />
              </Box>
            </LinkToPlate>
          )}
        >
          <LinkToPlate link={link}>
            {svgElement && typeof svgElement !== 'string' && svgElement.type === 'error' ? (
              <Box height={300}>
                <EmptyOrErrorPlate />
              </Box>
            ) : (
              <SSvgFlex>
                <SvgDiv id={`${svgElementPlateId}${plateId}-${index}`}>{svgElement}</SvgDiv>
              </SSvgFlex>
            )}
          </LinkToPlate>
        </DataContainer>
      </Box>
    </>
  );
};

export const SeePlateLink = ({
  referenceNumber,
  seePlateState,
}: {
  referenceNumber: string;
  seePlateState: [string | undefined, (x: string | undefined) => void];
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const catalogSourceUrl = useGetCatalogSourceUrlFromLocation();
  const [getSeePlate, setSeePlate] = seePlateState;
  const showPlate = getSeePlate === referenceNumber;
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referenceDetails = useSelector((state: RootState) =>
    getDHReferences(state, { vehicleKey, referenceNumbers: [referenceNumber] }),
  );
  const reference = referenceDetails[0];
  const plateLines = getData(reference)?.referencePlateLines;
  const hasAnyPlate = (plateLines?.length ?? 0) > 0;
  const hasMultiplePlates = (plateLines?.length ?? 0) > 1;
  const firstPlateIndex = plateLines?.[0]?.index;
  const firstPlateId = plateLines?.[0]?.vehiclePlate?.id;

  const firstPlatePath = useSelector((state: RootState) =>
    findPlateUrlInVehicleTree(state, hasAnyPlate && firstPlateId ? firstPlateId : ''),
  );
  const firstPlateLink = firstPlatePath
    ? `${catalogSourceUrl}/${firstPlatePath}` +
      (firstPlateIndex ? `&${HighlightIndex}=${firstPlateIndex?.toString()}` : '') +
      `&${PlateViewModeParam}=${PARAM_STANDARD}`
    : undefined;

  const handleClick = useCallback(() => {
    if (hasAnyPlate && firstPlateLink) {
      history.push(firstPlateLink);
    }
  }, [hasAnyPlate, firstPlateLink, history]);
  const handleMouseEnter = useCallback(() => {
    if (!hasMultiplePlates) {
      setSeePlate(referenceNumber);
    } else {
      setSeePlate(!showPlate ? referenceNumber : undefined);
    }
  }, [showPlate, hasMultiplePlates, referenceNumber, setSeePlate]);
  const handleMouseLeave = useCallback(() => {
    setSeePlate(undefined);
  }, [setSeePlate]);

  return (
    <>
      {hasAnyPlate && (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <SContainer onClick={handleClick}>
            <Flex direction={'row'}>
              <Icon IconComponent={EyeIcon} color={showPlate ? 'black' : 'grey'} height={20} width={20} />
              <MarginBox mx={3}>
                <Text
                  type={'light_12'}
                  displayStyle={showPlate ? undefined : 'link'}
                  cursor={'pointer'}
                  dataCy={'link-see-plan'}
                >
                  {t('catalog.reference_card.action.see_plate', 'See plate')}
                </Text>
              </MarginBox>
            </Flex>
          </SContainer>
          {showPlate && (
            <FloatingContainer>
              <DataContainer
                data={plateLines}
                Loading={() => (
                  <Box width={400} height={400}>
                    <CenteredSpin />
                  </Box>
                )}
              >
                {plateLines?.map((plateLine, index) => (
                  <Box width={400} key={`plate-image-${index}`}>
                    {plateLine.vehiclePlate?.imageUrl && plateLine.vehiclePlate?.id && (
                      <FloatingImage
                        id={plateLine.vehiclePlate?.imageUrl}
                        plateId={plateLine.vehiclePlate?.id}
                        key={`picture-${index}`}
                        index={plateLine.index}
                      />
                    )}
                  </Box>
                ))}
              </DataContainer>
            </FloatingContainer>
          )}
        </div>
      )}
    </>
  );
};
