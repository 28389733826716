import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FamilyReferences,
  GetIAMFamilyReferencesResponse,
} from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { List } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { CheckIcon, MapPin } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import Loader from 'components/Loader';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getIAMReferences } from 'domains/references';
import { ClientView } from 'domains/user';
import { SBadge } from 'pages/CatalogPage/IAM/MaintenancePlan/CustomBadge/CustomBadge.styled';
import {
  BlueTipCard,
  FamilyCard,
  GreySpacer,
  LabelFamily,
  SelReferenceCard,
} from 'pages/CatalogPage/IAM/MaintenancePlan/Families/Families.styled';
import { ReferencePriceInfo } from 'pages/CatalogPage/IAM/MaintenancePlan/Families/ReferencePriceInfo';
import { SBadgeCheckD } from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlan.styled';
import ReferenceCardsContainer from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer';
import { theme } from 'styles';
import { capitalize, Flex, Icon, MarginBox, Text, WithTooltip } from 'UI';
import { getData, NO_DATA } from 'utils';

const FamilyStatusIcon = ({ order, completed, active }: { order: number; completed: boolean; active: boolean }) => {
  if (completed) {
    return (
      <SBadgeCheckD>
        <Icon size={14} color={theme.color.blue} IconComponent={CheckIcon} />
      </SBadgeCheckD>
    );
  }

  if (active) {
    return (
      <SBadge count={order} checked={false} disabled={false} title={''} offset={[20, 9]}>
        <Text type={'light_14_black_65'} />
      </SBadge>
    );
  } else {
    return (
      <SBadge count={order} checked={false} disabled={true} title={''} offset={[20, 9]}>
        <Text type={'light_14_black_65'} />
      </SBadge>
    );
  }
};

const ReferencesInfo = ({ selectedReferences }: { selectedReferences: Map<string, string[]> }) => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const allSelectedReferences = Array.from(selectedReferences.values())
    .filter((r) => r.length > 0)
    .flat();

  const referencesRaw = useSelector((state: RootState) =>
    getIAMReferences(state, {
      referenceNumbers: allSelectedReferences,
      vehicleKey,
    }),
  );
  const references = getData(referencesRaw);

  return (
    <Flex direction={'column'}>
      <MarginBox mt={30} />
      <Flex minHeight={46}>
        <MarginBox mr={15} />

        <BlueTipCard>
          <Icon size={16} color={theme.color.white} IconComponent={MapPin} />
          <MarginBox mr={15} />
          <Text type={'dark_14_white_bold'}>
            <Trans i18nKey={'catalog.maintenance.new_maintenance_plan.selected_references'}>Selected references:</Trans>
          </Text>
        </BlueTipCard>

        {allSelectedReferences &&
          allSelectedReferences.map((ref) => {
            const refCatalog = references?.find((item) => item.referenceNumber === ref);
            return (
              <SelReferenceCard key={ref}>
                <Text type={'text_landing_md'}>{`${capitalize(
                  refCatalog?.brand?.toLowerCase() ?? refCatalog?.supplier?.toLowerCase() ?? '',
                )}  ${ref}`}</Text>
              </SelReferenceCard>
            );
          })}
      </Flex>
      <MarginBox mt={30} />
      <GreySpacer />
      <MarginBox mt={30} />
    </Flex>
  );
};
const Families = ({
  familyIds,
  familiesData,
  onSelectReferences,
  selectedReferences,
}: {
  familyIds?: string[];
  familiesData: NO_DATA | GetIAMFamilyReferencesResponse;
  onSelectReferences: (family: string, references: string[]) => void;
  selectedReferences: Map<string, string[]>;
}) => {
  const [selectedFamily, setSelectedFamily] = useState<string>('');

  const familiesWithReferences = useMemo(
    () =>
      familyIds
        ?.map((familyId) => getData(familiesData)?.families?.find((familyRef) => familyRef.id === familyId))
        .filter((familyRef): familyRef is FamilyReferences => familyRef !== undefined),
    [familyIds, familiesData],
  );

  const references = useMemo(
    () => familiesWithReferences?.find((familyReferences) => familyReferences.id === selectedFamily)?.references ?? [],
    [familiesWithReferences, selectedFamily],
  );

  const isActive = (family: string): boolean => family === selectedFamily;

  useEffect(() => {
    setSelectedFamily(familiesWithReferences?.slice(0, 1)[0]?.id || '');
  }, [familiesWithReferences]);

  const onSelectFamily = (item: string) => {
    setSelectedFamily(item);
  };

  function onSelectReference(referenceNumber: string) {
    onSelectReferences(selectedFamily, [referenceNumber]);
  }

  return familiesWithReferences && familiesWithReferences.length > 0 ? (
    <FamilyCard active={false}>
      <DataContainer
        data={familiesData}
        Loading={() => <Loader height={'400px'} />}
        NotFound={() => <></>}
        Error={() => <></>}
      >
        <Flex direction={'column'} size={0}>
          <Flex>
            <List
              style={{ cursor: 'pointer' }}
              dataSource={familiesWithReferences}
              renderItem={(family, order) =>
                family.references &&
                family.references.length > 0 && (
                  <List.Item
                    style={{ padding: '0 0 0 0' }}
                    onClick={() => onSelectFamily(family.id)}
                    key={family.id + uuidv4()}
                  >
                    <Flex direction={'row'}>
                      <Flex direction={'column'}>
                        <FamilyStatusIcon
                          order={order + 1}
                          completed={(selectedReferences.get(family.id) ?? []).length > 0}
                          active={isActive(family.id)}
                        />
                      </Flex>
                      <Flex direction={'column'}>
                        <LabelFamily width={275} active={isActive(family.id)}>
                          <Flex direction={'row'}>
                            <Flex direction={'column'} maxWidth={160}>
                              <WithTooltip title={family.label} placement={'top'}>
                                <Text type={'light_14_black_65'} disableGutter transform={'capitalize'} ellipsis>
                                  {family.label}
                                </Text>
                              </WithTooltip>
                            </Flex>
                            <ReferencePriceInfo family={family} selectedReferences={selectedReferences} />
                          </Flex>
                        </LabelFamily>
                      </Flex>
                    </Flex>
                  </List.Item>
                )
              }
            />
          </Flex>
        </Flex>
        <Flex direction={'column'} size={2}>
          <ReferencesInfo selectedReferences={selectedReferences} />
          <Flex direction={'column'}>
            <ReferenceCardsContainer
              sparePartsView={ClientView}
              referenceNumbers={references}
              laborTimesWrapper={undefined}
              selectedReferences={selectedReferences.get(selectedFamily)}
              onSelect={onSelectReference}
              setNumOfProducts={() => undefined}
              rangeFilters={[]}
              textFilters={[]}
              setTextFilters={() => undefined}
              setRangeFilters={() => undefined}
              isMaintenance
            />
          </Flex>
        </Flex>
      </DataContainer>
    </FamilyCard>
  ) : (
    <></>
  );
};

export default Families;
