import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FamilyReferences } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { RootState } from 'app/AppStore';
import { ChevronDownIcon, ChevronUpIcon, LevelUp } from 'assets/icons';
import { getBannersCount } from 'domains/information/Information.store';
import { buildOperationLabel } from 'domains/maintenancePlan/MaintenancePlan.mapper';
import {
  addReferencesRequest,
  getLastSearchServiceFamilies,
  getServiceOperationsReferences,
  removeOperationRequest,
  removeReferencesRequest,
  selectOperationRequest,
  setReferencesRequest,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { ServiceOperationLocal } from 'domains/maintenancePlan/MaintenancePlan.types';
import { SBadge } from 'pages/CatalogPage/IAM/MaintenancePlan/CustomBadge/CustomBadge.styled';
import Families from 'pages/CatalogPage/IAM/MaintenancePlan/Families';
import { SPanel } from 'pages/CatalogPage/IAM/MaintenancePlan/Operation/Operation.styled';
import { theme } from 'styles';
import { CollapseWithKey, Flex, Icon, MarginBox, Text, WithTooltip, Checkbox } from 'UI';
import { getData } from 'utils';

const RenderOperation = ({ operation }: { operation: ServiceOperationLocal }) => {
  const selectedReferencesByOperation = useSelector(getServiceOperationsReferences);
  const references = getData(selectedReferencesByOperation);
  const referencesFromStore = references?.get(operation.id)?.reduce((acc, item) => {
    const referenceNumbers = acc.get(item.family) || [];
    referenceNumbers.push(item.referenceNumber);
    acc.set(item.family, referenceNumbers);
    return acc;
  }, new Map<string, string[]>());
  const dispatch = useDispatch();
  const panelIdPrefix = 'panel-operation--';
  const { t } = useTranslation();
  const familiesData = useSelector(getLastSearchServiceFamilies);
  const [activeKey, setActiveKey] = useState<string[]>(operation.selected ? [panelIdPrefix + operation.id] : ['']);
  const [selectedReferences, setSelectedReferences] = useState<Map<string, string[]>>(
    referencesFromStore ?? new Map<string, string[]>(),
  );
  const isBanner = useSelector((state: RootState) => getBannersCount(state, 'OTHER_BRANDS_CATALOG')) > 0;

  useEffect(() => {
    if (operation.selected) {
      setActiveKey([panelIdPrefix + operation.id]);
    } else {
      setActiveKey([]);
    }
  }, [operation.selected, operation.id]);

  function handleChange(id: string, checked: boolean) {
    if (!checked) {
      setActiveKey([]);
    }
    if (checked) {
      dispatch(selectOperationRequest({ operationId: id }));
    } else {
      dispatch(removeOperationRequest({ operationId: id }));
    }
  }

  function isSelected(): boolean {
    return !!operation.selected;
  }

  const isActive = () => {
    return activeKey.includes(panelIdPrefix + operation.id);
  };

  const handleCollapseChange2 = (key: string | string[]) => {
    setActiveKey(typeof key === 'string' ? [key] : key);
  };

  function onSelectReferences(operationId: string, family: string, references: string[], scrollToId: string) {
    updateSelectedReferences(operationId, family, references, true);
    const divElementAbove = document.getElementById(scrollToId);
    if (divElementAbove) {
      const rect = divElementAbove.getBoundingClientRect();
      const offsetTop = rect.top + window.scrollY;
      window.scrollTo({
        top: offsetTop - (isBanner ? 170 : 100),
        behavior: 'smooth',
      });
    }
  }

  const updateSelectedReferences = (
    operationId: string,
    family: string,
    references: string[],
    limitCount: boolean,
  ): void => {
    setSelectedReferences((prevState) => {
      const updatedMap = new Map(prevState);
      if (updatedMap.has(family)) {
        const existingValues = updatedMap.get(family) as string[];

        if (existingValues.includes(references[0])) {
          dispatch(removeReferencesRequest({ operationId, references }));
          updatedMap.set(
            family,
            existingValues.filter((v) => v !== references[0]),
          );
        } else {
          dispatch(addReferencesRequest({ operationId, family, references }));
          if (limitCount) {
            updatedMap.set(family, [references[0]]);
            dispatch(setReferencesRequest({ operationId, family, references }));
          } else {
            updatedMap.set(family, [...existingValues, ...references]);
          }
        }
      } else {
        dispatch(addReferencesRequest({ operationId, family, references }));
        updatedMap.set(family, references);
      }

      return updatedMap;
    });
  };

  const scrollOperationId = 'scroll-operation-';

  return (
    <>
      <div id={scrollOperationId + operation.id} />
      <CollapseWithKey
        iconInactive={
          <Flex>
            <Icon IconComponent={ChevronDownIcon} height={15} width={15} />
          </Flex>
        }
        iconActive={
          <Flex>
            <Icon IconComponent={ChevronUpIcon} height={15} width={15} />
          </Flex>
        }
        noBorder
        position={'end'}
        noShadow
        noMargin
        key={'service-operation-collapse-' + operation.id}
        activeKey={activeKey}
        onChange={handleCollapseChange2}
      >
        <SPanel
          id={'panel_operation--' + operation.id}
          collapsible={isSelected() ? 'header' : 'disabled'}
          showArrow={isSelected()}
          header={
            <Flex direction={'row'} justify={'space-between'}>
              <Flex justify={'flex-start'} size={1}>
                <Checkbox
                  checked={isSelected()}
                  onChange={(v) => {
                    handleChange(operation.id, v);
                    if (v) {
                      setActiveKey([panelIdPrefix + operation.id]);
                    } else {
                      setActiveKey([]);
                    }
                  }}
                  label={
                    <>
                      {isActive() ? (
                        <Text type={'link_bold'} decoration={'underline'}>
                          {buildOperationLabel(operation)}
                        </Text>
                      ) : (
                        <Text type={'light_14_black_65'}>{buildOperationLabel(operation)}</Text>
                      )}
                    </>
                  }
                />
              </Flex>
              <Flex justify={'flex-end'} size={0}>
                <Flex direction={'row'}>
                  <MarginBox mr={5} mt={2}>
                    {Array.from(selectedReferences.values()).filter((r) => r.length > 0).length > 0 && (
                      <Text type={'text_dim_bold'}>
                        {Array.from(selectedReferences.values()).filter((r) => r.length > 0).length}/
                      </Text>
                    )}
                  </MarginBox>
                  <WithTooltip
                    title={t('catalog.maintenance.new_maintenance_plan.product_families', 'Product families')}
                  >
                    <SBadge
                      count={
                        operation.familyIds
                          ?.map((familyId) =>
                            getData(familiesData)?.families?.find((familyRef) => familyRef.id === familyId),
                          )
                          .filter((familyRef): familyRef is FamilyReferences => familyRef !== undefined).length ??
                        operation.familyIds?.length
                      }
                      checked={false}
                      disabled={false}
                      title={''}
                      offset={[0, 0]}
                    />
                  </WithTooltip>
                  <MarginBox mr={175}></MarginBox>
                </Flex>
              </Flex>
            </Flex>
          }
          key={panelIdPrefix + operation.id}
        >
          <Flex direction={'column'} key={operation.id}>
            <MarginBox mt={0} mb={0}>
              <MarginBox mt={0}>
                <Flex direction={'row'}>
                  <Families
                    familyIds={operation.familyIds}
                    familiesData={familiesData}
                    selectedReferences={selectedReferences}
                    onSelectReferences={(family: string, references: string[]) =>
                      onSelectReferences(operation.id, family, references, scrollOperationId + operation.id)
                    }
                  />
                </Flex>
              </MarginBox>
            </MarginBox>
            <MarginBox ml={27} mt={5}>
              {operation.subOperations?.map((subOperation) => (
                <Flex direction={'column'} key={subOperation.id}>
                  <Flex direction={'row'}>
                    <MarginBox ml={0} mr={5}>
                      <Icon IconComponent={LevelUp} size={24} color={theme.color.info} />
                    </MarginBox>
                    <Text type={'text_dim_bold'}>{subOperation.label}</Text>
                  </Flex>
                  <Flex direction={'column'}>
                    {subOperation.operations?.map((op) => (
                      <Flex direction={'column'} key={'flex-label-' + op.id}>
                        <MarginBox ml={50} mt={10} mb={15}>
                          <Text type={'light_14_black_65'} key={'label- ' + op.id}>
                            {op.label} - {op.repairLabel}
                          </Text>
                        </MarginBox>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              ))}
            </MarginBox>
          </Flex>
        </SPanel>
      </CollapseWithKey>
    </>
  );
};

export default RenderOperation;
