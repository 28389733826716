import { ReferenceSource } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { FilterAndSort } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterAndSort';
import { FilterArray } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterArray';
import { FilterBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterBy';
import { SortBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/SortBy';
import { MyStoreBundleItem } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddFreeBundlesFromMyStore';
import { LinkedReference } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddReferenceFromCatalog';
import { AddVehicle } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddVehicle';
import { FilterAndSortField } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/FilterAndSortField';
import { AddReferenceByRefNumber } from '@1po/1po-bff-fe-spec/generated/estimate/response/AddReferenceByRefNumber';
import {
  GetEstimate,
  LinkedReference as LinkedRef,
} from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { GetEstimateHistory } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimateHistory';
import { GetSettings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { call, put, putResolve, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  setLastSearchedVehicleKey,
} from 'domains/catalog/Catalog.store';
import {
  fillFromMaintenancePlan,
  sendAddBundlesFromMyStore,
  sendAddCatalogLaborTime,
  sendAddCatalogReference,
  sendAddCatalogReferencesToBasket,
  sendAddCustomItem,
  sendAddCustomSetting,
  sendAddLaborTimeByCode,
  sendAddReferenceByReferenceNumber,
  sendAddReferenceNumber,
  sendAddTire,
  sendAddTireByReferenceNumber,
  sendAddVehicle,
  sendCancelDeletionRequest,
  sendClearItems,
  sendDeleteEstimateRequest,
  sendEstimateToDMS,
  sendGetEstimateById,
  sendGetHistory,
  sendGetLatestEstimate,
  sendGetRepairOrderRequest,
  sendGetSettings,
  sendRemoveCustomSetting,
  sendRemoveItem,
  sendUpdateClientContact,
  sendUpdateFreeBundle,
  sendUpdateLaborTime,
  sendUpdateObservations,
  sendUpdateOtherItem,
  sendUpdateReference,
  sendUpdateSettings,
  sendUpdateTire,
  sendUpdateVehicle,
  sendUpdateWasteRecycling,
  sendUpdateWasteRecyclingSettings,
} from 'domains/estimate/Estimate.api';
import * as actions from 'domains/estimate/Estimate.store';
import {
  addReferenceForValidation,
  createNewEstimate,
  getCatalogReferences,
  getCatalogTires,
  getCurrentEstimateId,
  getEstimateHistoryCursor,
  getEstimateHistorySearch,
  getEstimateVehicleKey,
  getReferences,
  getTires,
  removeReferenceForValidation,
  resetEstimateHistory,
  setEstimateDataStatus,
  setEstimateHidden,
  setEstimateHistoryResponse,
  setEstimateHistorySearchStatus,
  setEstimateRemoved,
  setEstimateResponse,
  setHistoryEstimate,
  setSettings,
} from 'domains/estimate/Estimate.store';
import { RequestReference } from 'domains/estimate/Estimate.types';
import { DHReferenceLocal, getDHReferences, getLoadedPrices, ReferencePriceType } from 'domains/references';

import { getDmsWorkshopId, getTradingProfile, getUserContext } from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { notifyTop } from 'UI/Notification/notification';
import { AppTranslation, hasData, LOADING, NO_DATA, sagaGuard } from 'utils';
import { select } from 'utils/domainStore';

export function* fetchLatestEstimateRequest(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleKey = yield* select(getLastSearchedVehicleKey);

  if (!tradingProfile?.buyerId) return;

  yield put(sendGetLatestEstimate({ vehicleKey }));
}

export function* fetchEstimateResponse(action: WsResponse<GetEstimate>): SagaIterator {
  yield put(setEstimateResponse(action.payload));
}

export function* fetchEstimateByIdResponse(action: WsResponse<GetEstimate>): SagaIterator {
  yield put(setEstimateResponse(action.payload));
  yield put(setHistoryEstimate(action.payload));
}

export function* fetchDeleteEstimateByIdResponse(action: WsResponse<string>): SagaIterator {
  const estimateId = action.payload;

  yield put(setEstimateRemoved({ estimateId }));
}

export function* fetchHideEstimateByIdResponse(action: WsResponse<string>): SagaIterator {
  const estimateId = action.payload;
  yield put(setEstimateHidden({ estimateId, isHidden: true }));
}

export function* fetchShowEstimateByIdResponse(action: WsResponse<string>): SagaIterator {
  const estimateId = action.payload;
  yield put(setEstimateHidden({ estimateId, isHidden: false }));
}

export function* fetchEstimateCreatedResponse(action: WsResponse<string>): SagaIterator {
  yield put(resetEstimateHistory());
  yield put(actions.fetchEstimateHistoryRequest());
  yield put(actions.fetchEstimateByIdRequest(action.payload));
}

export function* fetchEstimateHistoryRequest(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const cursor = yield* select(getEstimateHistoryCursor);
  const search = yield* select(getEstimateHistorySearch);

  if (!tradingProfile?.buyerId) return;

  let orFilter = undefined;
  if (search) {
    orFilter = new FilterArray<FilterAndSortField>('OR', [
      new FilterBy<FilterAndSortField>('VIN', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('VRN', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('SEQUENCE_NUMBER', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('FULL_NAME', search, 'CONTAINS'),
    ]);
  }
  const filterAndSort: FilterAndSort<FilterAndSortField> = {
    filter: orFilter,
    sorts: [new SortBy('SEQUENCE_NUMBER', 'DESC')],
    cursor,
  };

  yield put(setEstimateHistorySearchStatus(LOADING));
  yield put(sendGetHistory({ garageId: tradingProfile.buyerId, filterAndSort }));
}

export function* fetchEstimateByIdRequest({
  payload,
}: ReturnType<typeof actions.fetchEstimateByIdRequest>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = payload;

  if (!tradingProfile?.buyerId || !estimateId) {
    return;
  }

  yield put(setEstimateDataStatus({ estimateId, status: LOADING }));
  yield put(sendGetEstimateById({ estimateId, garageId: tradingProfile.buyerId }));
}

export function* fetchEstimateHistoryResponse(action: WsResponse<GetEstimateHistory>): SagaIterator {
  yield put(setEstimateHistoryResponse(action.payload));
}

export function* addCatalogLaborTime({ payload }: ReturnType<typeof actions.addCatalogLaborTime>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);

  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendAddCatalogLaborTime({
      garageId: tradingProfile.buyerId,
      estimateId,
      vehicleDetail,
      ...payload,
    }),
  );
}

export function* addCustomSetting({ payload }: ReturnType<typeof actions.addCustomSetting>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const { itemType } = payload;
  const itemId = uuidv4();

  if (!tradingProfile?.buyerId) return;

  yield put(sendAddCustomSetting({ itemType, itemId, garageId: tradingProfile.buyerId }));
}

export function* removeCustomSetting({ payload }: ReturnType<typeof actions.removeCustomSetting>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const { itemType, itemId } = payload;

  if (!tradingProfile?.buyerId) return;

  yield put(sendRemoveCustomSetting({ itemType, itemId, garageId: tradingProfile.buyerId }));
}

export function* addBundleFromMyStore({ payload }: ReturnType<typeof actions.addBundleFromMyStore>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  const converted: MyStoreBundleItem[] = payload.map((i) => ({
    id: i.id || '',
    code: i.code || '',
    designation: i.designation || '',
    price: i.price,
  }));

  yield put(
    sendAddBundlesFromMyStore({
      estimateId: estimateId,
      garageId: tradingProfile?.buyerId || '',
      vehicleDetail: vehicleDetail,
      myStoreBundles: [...converted],
    }),
  );
}

function mapLinkedReference(
  reference: RequestReference,
  linkedRef: DHReferenceLocal,
  linkedRefPrices: { prices: NO_DATA | ReferencePriceType; reference: string }[],
) {
  const ratio =
    reference.linkedReferences?.find((linkedRef) => linkedRef.referenceNumber === linkedRef.referenceNumber)?.ratio ??
    1;
  const price = linkedRefPrices.find((refPrice) => refPrice.reference === linkedRef.referenceNumber)?.prices;
  const unitPrice =
    hasData(price) && price.clientView?.recommendedPriceVatExcluded
      ? price.clientView.recommendedPriceVatExcluded?.toString()
      : '';
  const priceVatExcluded =
    hasData(price) && price.clientView?.recommendedPriceVatExcluded
      ? price.clientView.recommendedPriceVatExcluded?.toString()
      : '';
  const garagePrice =
    hasData(price) && price.garageView?.vatExcludedPrice ? price.garageView.vatExcludedPrice?.toString() : '';
  const vatPercentage = hasData(price) && price.garageView?.vatPercentage ? price.garageView.vatPercentage : 20;
  const discountRate = hasData(price) ? price.garageView?.discountRate : '';
  return {
    referenceNumber: linkedRef.referenceNumber,
    designation: linkedRef.name,
    catalogSource: 'DATAHUB',
    quantity: ratio,
    unitPrice,
    priceVatExcluded,
    vatPercentage,
    garagePrice,
    discountRate,
    ratio,
    isCompatible: linkedRef.isApplicableToCurrentVehicle ?? true,
  } as LinkedReference;
}

export function* addCatalogReference({ payload }: ReturnType<typeof actions.addCatalogReference>): SagaIterator {
  const { reference } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const linkedRefNumbers = reference.linkedReferences?.map((linkedRef) => linkedRef.referenceNumber) ?? [];
  const linkedRefDetails = yield* select((state: RootState) =>
    getDHReferences(state, {
      vehicleKey: estimateVehicleKey,
      referenceNumbers: linkedRefNumbers,
    }),
  );
  const linkedRefPrices = yield* select((state: RootState) => getLoadedPrices(state, linkedRefNumbers ?? []));

  const linkedReferencesWithRatio = linkedRefDetails.map((r) => mapLinkedReference(reference, r, linkedRefPrices));

  const referenceWithLinkedDetails = {
    ...reference,
    linkedReferences: linkedReferencesWithRatio.filter((linkedRef) => linkedRef.garagePrice),
  };

  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendAddCatalogReference({
      garageId: tradingProfile.buyerId,
      vehicleDetail,
      reference: referenceWithLinkedDetails,
      estimateId,
    }),
  );
}

export function* addCustomReference(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,
      garageId: tradingProfile.buyerId,
      itemType: 'REFERENCE',
    }),
  );
}

export function* addCustomLaborTime(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,
      garageId: tradingProfile.buyerId,
      itemType: 'LABOR_TIME',
    }),
  );
}

export function* addCustomWasteRecycling(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,
      garageId: tradingProfile.buyerId,
      itemType: 'WASTE_RECYCLING',
    }),
  );
}

export function* addCustomFreeBundle(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();
  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,
      garageId: tradingProfile.buyerId,
      itemType: 'FREE_BUNDLE',
    }),
  );
}

export function* addCatalogTire({ payload }: ReturnType<typeof actions.addCatalogTire>): SagaIterator {
  const { reference } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendAddTire({
      garageId: tradingProfile.buyerId,
      vehicleDetail,
      reference,
      estimateId,
      userContext,
    }),
  );
}

export function* addCustomTire(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,
      garageId: tradingProfile.buyerId,
      itemType: 'TIRE',
    }),
  );
}

export function* addOtherItem(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,
      garageId: tradingProfile.buyerId,
      itemType: 'OTHER_ITEM',
    }),
  );
}

export function* updateReference({ payload }: ReturnType<typeof actions.updateReference>): SagaIterator {
  const { itemId, parentItemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  if (field === 'REFERENCE_NUMBER' && newValue.length >= 10) {
    yield put(
      actions.addCatalogReferenceByReferenceNumber({
        referenceNumber: newValue,
        searchType: 'CUSTOM_ITEM',
        itemType: 'STANDARD',
        itemId,
      }),
    );
    return;
  }

  yield put(
    sendUpdateReference({
      garageId: tradingProfile.buyerId,
      itemId,
      parentItemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateLaborTime({ payload }: ReturnType<typeof actions.updateLaborTime>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateLaborTime({
      garageId: tradingProfile.buyerId,
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateTire({ payload }: ReturnType<typeof actions.updateTire>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  if (field == 'REFERENCE_NUMBER' && newValue.length >= 10) {
    yield put(
      actions.addCatalogTireByReferenceNumber({
        referenceNumber: newValue,
        searchType: 'CUSTOM_ITEM',
        itemType: 'TIRE',
        itemId,
      }),
    );
    return;
  }

  yield put(
    sendUpdateTire({
      garageId: tradingProfile.buyerId,
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateOtherItem({ payload }: ReturnType<typeof actions.updateOtherItem>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateOtherItem({
      garageId: tradingProfile.buyerId,
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateFreeBundle({ payload }: ReturnType<typeof actions.updateFreeBundle>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateFreeBundle({
      garageId: tradingProfile.buyerId,
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateWasteRecycling({ payload }: ReturnType<typeof actions.updateWasteRecycling>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateWasteRecycling({
      garageId: tradingProfile.buyerId,
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* removeReference({ payload }: ReturnType<typeof actions.removeReference>): SagaIterator {
  const { itemId, parentItemId } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      garageId: tradingProfile.buyerId,
      itemId,
      parentItemId,
      itemType: 'REFERENCE',
      estimateId,
    }),
  );
}

export function* removeLaborTime({ payload }: ReturnType<typeof actions.removeLaborTime>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      garageId: tradingProfile.buyerId,
      itemId: payload,
      itemType: 'LABOR_TIME',
      estimateId,
    }),
  );
}

export function* removeWasteRecycling({ payload }: ReturnType<typeof actions.removeWasteRecycling>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      garageId: tradingProfile.buyerId,
      itemId: payload,
      itemType: 'WASTE_RECYCLING',
      estimateId,
    }),
  );
}

export function* removeTire({ payload }: ReturnType<typeof actions.removeTire>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      garageId: tradingProfile.buyerId,
      itemId: payload,
      itemType: 'TIRE',
      estimateId,
    }),
  );
}

export function* removeOtherItem({ payload }: ReturnType<typeof actions.removeOtherItem>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      garageId: tradingProfile.buyerId,
      itemId: payload,
      itemType: 'OTHER_ITEM',
      estimateId,
    }),
  );
}

export function* removeFreeBundle({ payload }: ReturnType<typeof actions.removeFreeBundle>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      garageId: tradingProfile.buyerId,
      itemId: payload,
      itemType: 'FREE_BUNDLE',
      estimateId,
    }),
  );
}

export function* removeAllReferences(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ garageId: tradingProfile.buyerId, estimateId, itemType: 'REFERENCE' }));
}

export function* removeAllLaborTimes(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ garageId: tradingProfile.buyerId, estimateId, itemType: 'LABOR_TIME' }));
}

export function* removeAllWasteRecycling(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ garageId: tradingProfile.buyerId, estimateId, itemType: 'WASTE_RECYCLING' }));
}

export function* removeAllFreeBundles(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ garageId: tradingProfile.buyerId, estimateId, itemType: 'FREE_BUNDLE' }));
}

export function* removeAllTires(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ garageId: tradingProfile.buyerId, estimateId, itemType: 'TIRE' }));
}

export function* removeAllOtherItems(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ garageId: tradingProfile.buyerId, estimateId, itemType: 'OTHER_ITEM' }));
}

export function* deleteEstimateRequest({ payload }: ReturnType<typeof actions.deleteEstimateRequest>): SagaIterator {
  const { estimateId } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const currentEstimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendDeleteEstimateRequest({
      garageId: tradingProfile.buyerId,
      estimateId,
    }),
  );

  yield put(setEstimateHidden({ estimateId, isHidden: true }));

  if (currentEstimateId === estimateId) {
    yield put(createNewEstimate({ vehicle: undefined }));
    yield put(setLastSearchedVehicleKey(undefined));
  }
}

export function* cancelEstimateDeletionRequest({
  payload,
}: ReturnType<typeof actions.cancelEstimateDeletion>): SagaIterator {
  const { estimateId } = payload;
  const tradingProfile = yield* select(getTradingProfile);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendCancelDeletionRequest({
      garageId: tradingProfile.buyerId,
      estimateId,
    }),
  );

  yield put(setEstimateHidden({ estimateId, isHidden: false }));
}

export function* updateClient({ payload }: ReturnType<typeof actions.updateClient>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !estimateId) return;
  const { newValue, field } = payload;

  yield put(
    sendUpdateClientContact({
      garageId: tradingProfile.buyerId,
      estimateId,
      originalVehicle: vehicleDetail,
      newValue,
      field,
    }),
  );
}

export function* updateObservations({ payload }: ReturnType<typeof actions.updateObservations>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateObservations({
      garageId: tradingProfile.buyerId,
      estimateId,
      originalVehicle: vehicleDetail,
      newValue: payload,
    }),
  );
}

export function* addVehicle(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !vehicleDetail || !estimateId) return;

  const request: AddVehicle = {
    estimateId,
    garageId: tradingProfile.buyerId,
    vehicleDetail,
  };

  yield put(sendAddVehicle(request));
}

export function* updateVehicle({ payload }: ReturnType<typeof actions.updateVehicle>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !vehicleDetail || !estimateId) return;
  const { newValue, field } = payload;
  yield put(
    sendUpdateVehicle({
      garageId: tradingProfile.buyerId,
      estimateId,
      newValue,
      originalVehicle: vehicleDetail,
      field,
    }),
  );
}

export function* updateSettings({ payload }: ReturnType<typeof actions.updateSettings>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;

  yield put(sendUpdateSettings({ ...payload, garageId: tradingProfile.buyerId }));
}

export function* updateWasteRecyclingSettings({
  payload,
}: ReturnType<typeof actions.updateWasteRecyclingSettings>): SagaIterator {
  const { itemId, field, newValue } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;

  yield put(sendUpdateWasteRecyclingSettings({ itemId, garageId: tradingProfile.buyerId, field, newValue }));
}

export function* addCatalogReferenceByReferenceNumber({
  payload,
}: ReturnType<typeof actions.addCatalogReferenceByReferenceNumber>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!estimateId) return;
  const { referenceNumber, itemId, itemType, searchType } = payload;

  yield putResolve(
    addReferenceForValidation({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
      itemType,
      searchType,
    }),
  );
  yield put(
    sendAddReferenceByReferenceNumber({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
      itemType,
      searchType,
    }),
  );
}

export function* addReferenceNumber({ payload }: ReturnType<typeof actions.addReferenceNumber>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!estimateId) return;
  const { referenceNumber, itemId } = payload;

  yield put(
    sendAddReferenceNumber({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
    }),
  );
}

export function* addCatalogTireByReferenceNumber({
  payload,
}: ReturnType<typeof actions.addCatalogTireByReferenceNumber>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!estimateId) return;
  const { referenceNumber, itemId, itemType, searchType } = payload;

  if (searchType === 'CUSTOM_ITEM') {
    yield putResolve(
      addReferenceForValidation({
        userContext,
        referenceNumber,
        vehicleDetail,
        estimateId,
        itemId,
        itemType,
        searchType,
      }),
    );
  }

  yield put(
    sendAddTireByReferenceNumber({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
      itemType,
      searchType,
    }),
  );
}

export function* removeKnownReferenceValidation({
  payload,
}: ReturnType<typeof actions.removeKnownReferenceValidation>): SagaIterator {
  yield putResolve(removeReferenceForValidation(payload));
}

export function* addCatalogLaborTimeByCode({
  payload,
}: ReturnType<typeof actions.addCatalogLaborTimeByCode>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !vehicleDetail || !estimateId) return;

  const laborTimeCode = payload;
  yield put(
    sendAddLaborTimeByCode({
      garageId: tradingProfile.buyerId,
      userContext,
      laborTimeCode,
      vehicleDetail,
      estimateId,
    }),
  );
}

export function* fetchSettingsRequest(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;
  yield put(sendGetSettings({ garageId: tradingProfile.buyerId }));
}

export function* fetchSettingsResponse(action: WsResponse<GetSettings>): SagaIterator {
  const { estimateSettings } = action.payload;
  yield put(setSettings(estimateSettings));
}

export function* addCatalogReferencesToBasket(): SagaIterator {
  const userContext = yield* select(getUserContext);
  const currentVehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  if (!estimateId) {
    return;
  }
  const references = yield* select((state: RootState) => getCatalogReferences(state, estimateId));
  const linkedReferences = references.flatMap((ref) => (ref.linkedReferences ? ref.linkedReferences : []));

  const tires = yield* select((state: RootState) => getCatalogTires(state, estimateId));
  const referencesSum =
    (references ? references.length : 0) +
    (tires ? tires.length : 0) +
    (linkedReferences ? linkedReferences.length : 0);

  if (referencesSum === 0) {
    return;
  }

  // sum qty for duplicate main and linked references
  const mainRefNumbers = references.map((ref) => ref.referenceNumber);
  const linkedRefNumbers = linkedReferences.map((ref) => ref.referenceNumber);
  const duplicateRefNumbers = mainRefNumbers.filter((mainRef) => linkedRefNumbers.includes(mainRef));
  const mainReferencesWithSumQty = [...references];

  duplicateRefNumbers.forEach((refNumber) => {
    while (linkedReferences.some((r) => r.referenceNumber === refNumber)) {
      const linkedRefIndex = linkedReferences.findIndex((r) => r.referenceNumber === refNumber);
      const mainRefIndex = references.findIndex((r) => r.referenceNumber === refNumber);
      mainReferencesWithSumQty[mainRefIndex] = {
        ...references[mainRefIndex],
        quantity: mainReferencesWithSumQty[mainRefIndex].quantity + (linkedReferences[linkedRefIndex]?.quantity ?? 0),
      };
      linkedReferences.splice(linkedRefIndex, 1);
    }
  });

  const linkedReferencesWithSumQty = [...linkedReferences].reduce((acc: LinkedRef[], next: LinkedRef) => {
    const indexOfDuplicate = acc.findIndex((linkedRef) => linkedRef.referenceNumber === next.referenceNumber);
    if (indexOfDuplicate === -1) {
      acc.push(next);
      return acc;
    }
    const duplicateToMerge = acc[indexOfDuplicate];
    acc[indexOfDuplicate] = { ...duplicateToMerge, quantity: duplicateToMerge.quantity + next.quantity };
    return acc;
  }, []);

  const referenceDetails = [...mainReferencesWithSumQty, ...linkedReferencesWithSumQty].map((ref) => {
    return {
      quantity: ref.quantity,
      referenceNumber: ref.referenceNumber,
      referenceSource: ref.referenceSource ?? 'STANDARD',
      origin: ref.origin,
      supplierCode: ref.supplierCode,
    };
  });

  const tiresDetails = [...tires].map((ref) => {
    return {
      quantity: ref.quantity,
      referenceNumber: ref.referenceNumber,
      referenceSource: 'STANDARD' as ReferenceSource,
    };
  });

  yield put(
    sendAddCatalogReferencesToBasket({
      userContext,
      vehicleDetail: estimateVehicleKey ? currentVehicleDetail : undefined,
      referenceDetails: [...referenceDetails, ...tiresDetails],
    }),
  );
}

export function* addKnowReferenceByRefNumberResponse(action: WsResponse<AddReferenceByRefNumber>): SagaIterator {
  const { estimateId, result, referenceNumber, itemType } = action.payload;
  const currentEstimateId = yield* select(getCurrentEstimateId);

  if (currentEstimateId === estimateId) {
    if (result !== 'OK') {
      const notificationMessage =
        result === 'UNKNOWN_REFERENCE'
          ? AppTranslation.t('catalog.reference.error.unknown_reference', 'Unknown reference')
          : AppTranslation.t(
              'cart.action.add_reference.missing_price',
              'Price currently unavailable, please try again later.',
            );
      yield call(notifyTop, 'error', notificationMessage, null);
    } else {
      let ref = undefined;
      let tire = undefined;

      switch (itemType) {
        case 'STANDARD': {
          const references = yield* select((state: RootState) => getReferences(state, currentEstimateId));
          ref = references.find((r) => r.referenceNumber === referenceNumber);
          break;
        }
        case 'TIRE': {
          const tires = yield* select((state: RootState) => getTires(state, currentEstimateId));
          tire = tires.find((r) => r.referenceNumber === referenceNumber);
          break;
        }
      }

      if (ref || tire) {
        const msg = AppTranslation.t(
          'estimate.action.result.reference_listed',
          'This reference is already listed in your estimate',
        );
        const decs = AppTranslation.t(
          'estimate.action.result.reference_listed.description',
          '1 unit has been added to reference {{ referenceNumber }}',
          {
            referenceNumber,
          },
        );
        yield call(notifyTop, 'info', msg, decs);
      }
    }
  }
}

export function* addTireByRefNumberResponse(action: WsResponse<AddReferenceByRefNumber>): SagaIterator {
  const { estimateId, result, referenceNumber } = action.payload;
  const currentEstimateId = yield* select(getCurrentEstimateId);

  if (currentEstimateId === estimateId) {
    if (result !== 'OK') {
      const notificationMessage =
        result === 'UNKNOWN_REFERENCE'
          ? AppTranslation.t('catalog.reference.error.unknown_reference', 'Unknown reference')
          : AppTranslation.t(
              'cart.action.add_reference.missing_price',
              'Price currently unavailable, please try again later.',
            );
      yield call(notifyTop, 'error', notificationMessage, null);
    } else {
      const msg = AppTranslation.t(
        'estimate.action.result.tire_reference_listed.description',
        'Tire reference has been added to the tire section of the estimate',
        {
          referenceNumber,
        },
      );
      yield call(notifyTop, 'success', msg);
    }
  }
}

export function* sendEstimateToDMSResponse(): SagaIterator {
  yield call(
    notifyTop,
    'success',
    AppTranslation.t('estimate.dms.export.success', 'The estimation has been successfully sent to your DMS'),
    null,
  );
}

export function* fillEstimateFromMaintenancePlan({
  payload,
}: ReturnType<typeof actions.fillEstimateFromMaintenancePlan>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const { vehicleDetail, laborTimes, references } = payload;
  if (!vehicleDetail) return;

  yield put(
    fillFromMaintenancePlan({
      garageId: tradingProfile.buyerId,
      references,
      laborTimes,
      vehicleDetail,
      estimateId,
    }),
  );
}

export function* createEstimateFromDMSRequest({
  payload,
}: ReturnType<typeof actions.createEstimateFromDMSRequest>): SagaIterator {
  const { orderNumber } = payload;
  const userContext = yield* select(getUserContext);
  const workshopId = yield* select(getDmsWorkshopId);

  if (!workshopId) {
    return;
  }

  yield put(sendGetRepairOrderRequest({ userContext, workshopId, orderNumber }));
}

export function* sendEstimateToDMSRequest(): SagaIterator {
  const estimateId = yield* select(getCurrentEstimateId);
  const workshopId = yield* select(getDmsWorkshopId);

  if (!workshopId || !estimateId) {
    return;
  }

  yield put(sendEstimateToDMS({ estimateId, workshopId }));
}

export function* EstimateSagas(): SagaIterator {
  yield takeEvery(actions.fetchEstimateByIdRequest.type, sagaGuard(fetchEstimateByIdRequest));
  yield takeEvery(actions.fetchEstimateByIdResponse.type, sagaGuard(fetchEstimateByIdResponse));
  yield takeEvery(actions.fetchDeleteEstimateByIdResponse.type, sagaGuard(fetchDeleteEstimateByIdResponse));
  yield takeEvery(actions.fetchHideEstimateByIdResponse.type, sagaGuard(fetchHideEstimateByIdResponse));
  yield takeEvery(actions.fetchShowEstimateByIdResponse.type, sagaGuard(fetchShowEstimateByIdResponse));
  yield takeEvery(actions.fetchLatestEstimateRequest.type, sagaGuard(fetchLatestEstimateRequest));
  yield takeEvery(actions.fetchEstimateResponse.type, sagaGuard(fetchEstimateResponse));
  yield takeEvery(actions.fetchEstimateCreatedResponse.type, sagaGuard(fetchEstimateCreatedResponse));
  yield takeEvery(actions.fetchEstimateHistoryRequest.type, sagaGuard(fetchEstimateHistoryRequest));
  yield takeEvery(actions.fetchEstimateHistoryResponse.type, sagaGuard(fetchEstimateHistoryResponse));

  yield takeEvery(actions.addCatalogLaborTime.type, sagaGuard(addCatalogLaborTime));
  yield takeEvery(actions.addCatalogReference.type, sagaGuard(addCatalogReference));
  yield takeEvery(actions.addReferenceNumber.type, sagaGuard(addReferenceNumber));
  yield takeEvery(actions.addCatalogTire.type, sagaGuard(addCatalogTire));
  yield takeLatest(actions.addCatalogReferenceByReferenceNumber.type, sagaGuard(addCatalogReferenceByReferenceNumber));
  yield takeLatest(actions.addCatalogTireByReferenceNumber.type, sagaGuard(addCatalogTireByReferenceNumber));
  yield takeLatest(actions.addCatalogLaborTimeByCode.type, sagaGuard(addCatalogLaborTimeByCode));
  yield takeEvery(actions.addCustomReference.type, sagaGuard(addCustomReference));
  yield takeEvery(actions.addCustomLaborTime.type, sagaGuard(addCustomLaborTime));
  yield takeEvery(actions.addCustomWasteRecycling.type, sagaGuard(addCustomWasteRecycling));
  yield takeEvery(actions.addCustomFreeBundle.type, sagaGuard(addCustomFreeBundle));
  yield takeEvery(actions.addCustomTire.type, sagaGuard(addCustomTire));
  yield takeEvery(actions.addOtherItem.type, sagaGuard(addOtherItem));

  yield takeLatest(actions.updateReference.type, sagaGuard(updateReference));
  yield takeLatest(actions.updateLaborTime.type, sagaGuard(updateLaborTime));
  yield takeLatest(actions.updateTire.type, sagaGuard(updateTire));
  yield takeLatest(actions.updateOtherItem.type, sagaGuard(updateOtherItem));
  yield takeLatest(actions.updateWasteRecycling.type, sagaGuard(updateWasteRecycling));
  yield takeLatest(actions.updateFreeBundle.type, sagaGuard(updateFreeBundle));
  yield takeLatest(actions.updateSettings.type, sagaGuard(updateSettings));
  yield takeLatest(actions.updateWasteRecyclingSettings.type, sagaGuard(updateWasteRecyclingSettings));
  yield takeLatest(actions.addCustomSetting.type, sagaGuard(addCustomSetting));
  yield takeLatest(actions.removeCustomSetting.type, sagaGuard(removeCustomSetting));
  yield takeLatest(actions.addBundleFromMyStore.type, sagaGuard(addBundleFromMyStore));

  yield takeEvery(actions.removeReference.type, sagaGuard(removeReference));
  yield takeEvery(actions.removeLaborTime.type, sagaGuard(removeLaborTime));
  yield takeEvery(actions.removeFreeBundle.type, sagaGuard(removeFreeBundle));
  yield takeEvery(actions.removeWasteRecycling.type, sagaGuard(removeWasteRecycling));
  yield takeEvery(actions.removeTire.type, sagaGuard(removeTire));
  yield takeEvery(actions.removeOtherItem.type, sagaGuard(removeOtherItem));

  yield takeLatest(actions.removeAllReferences.type, sagaGuard(removeAllReferences));
  yield takeLatest(actions.removeAllLaborTimes.type, sagaGuard(removeAllLaborTimes));
  yield takeLatest(actions.removeAllFreeBundles.type, sagaGuard(removeAllFreeBundles));
  yield takeLatest(actions.removeAllWasteRecycling.type, sagaGuard(removeAllWasteRecycling));
  yield takeLatest(actions.removeAllTires.type, sagaGuard(removeAllTires));
  yield takeLatest(actions.removeAllOtherItems.type, sagaGuard(removeAllOtherItems));
  yield takeLatest(actions.deleteEstimateRequest.type, sagaGuard(deleteEstimateRequest));
  yield takeLatest(actions.cancelEstimateDeletion.type, sagaGuard(cancelEstimateDeletionRequest));

  yield takeLatest(actions.addVehicle.type, sagaGuard(addVehicle));
  yield takeLatest(actions.updateVehicle.type, sagaGuard(updateVehicle));
  yield takeLatest(actions.updateClient.type, sagaGuard(updateClient));
  yield takeLatest(actions.updateObservations.type, sagaGuard(updateObservations));

  yield takeLatest(actions.removeKnownReferenceValidation.type, sagaGuard(removeKnownReferenceValidation));

  yield takeLatest(actions.fetchSettingsRequest.type, sagaGuard(fetchSettingsRequest));
  yield takeLatest(actions.fetchSettingsResponse.type, sagaGuard(fetchSettingsResponse));

  yield takeLatest(actions.addCatalogReferencesToBasket.type, sagaGuard(addCatalogReferencesToBasket));
  yield takeLatest(actions.addKnowReferenceByRefNumberResponse.type, sagaGuard(addKnowReferenceByRefNumberResponse));
  yield takeLatest(actions.addTireByRefNumberResponse.type, sagaGuard(addTireByRefNumberResponse));

  yield takeLatest(actions.createEstimateFromDMSRequest.type, sagaGuard(createEstimateFromDMSRequest));
  yield takeLatest(actions.sendEstimateToDMSRequest.type, sagaGuard(sendEstimateToDMSRequest));
  yield takeLatest(actions.sendEstimateToDMSResponse.type, sagaGuard(sendEstimateToDMSResponse));

  yield takeLatest(actions.fillEstimateFromMaintenancePlan.type, sagaGuard(fillEstimateFromMaintenancePlan));
}
