import { v4 as uuidV4 } from 'uuid';
import { RequestState } from 'domains/webSockets/enum/RequestState';
import { WS_SEND_REQUEST } from 'domains/webSockets/WebSocket.types';
import { ELEVEN_SEC, WebSocketAction, WebSocketActionTimeout } from './domain.types';

export function wsSendAction(
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  payload: any,
  timeout: WebSocketActionTimeout = ELEVEN_SEC,
): WebSocketAction {
  return {
    url,
    payload,
    timeout,
    traceId: uuidV4(),
    type: WS_SEND_REQUEST,
    state: RequestState.SENT,
    created: new Date(),
  };
}

export function wsSendActionNoTimeout(
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  payload: any,
): WebSocketAction {
  return {
    type: WS_SEND_REQUEST,
    url,
    payload,
    timeout: undefined,
    traceId: uuidV4(),
    state: RequestState.SENT,
    created: new Date(),
  };
}
