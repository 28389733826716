import { Level3Detail } from '@1po/1po-bff-fe-spec/generated/catalog/tree/request/Level3Detail';
import { BusinessError } from '@1po/1po-bff-fe-spec/generated/error/BusinessError';
import { WsResponse } from 'domains/webSockets/WebSocket.types';

export const getFirstVehicleKey = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'VEHICLE_KEY')?.value;
};

export const getFirstIAMVehicleCode = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'VEHICLE_IAM_CODE')?.value;
};

export const getFirstSearchText = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'SEARCH_TEXT')?.value;
};

export const getFirstNodeId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'NODE_ID')?.value;
};

export const getFirstGarageId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'GARAGE_ID')?.value;
};

export const getFirstPlateId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'PLATE_ID')?.value;
};

export const getAllMultiPlateIds = (action: WsResponse): Level3Detail[] | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail
    ?.filter(({ name }) => name === 'MULTI_PLATE_ID')
    .map((ref) => ref.value)
    .map((id) => {
      return { nodeId: id, plateIds: [] };
    });
};
export const getFirstReference = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'REFERENCE')?.value;
};

export const getAllDocumentsId = (action: WsResponse): string[] | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.filter(({ name }) => name === 'DOCUMENTS_ID').map((ref) => ref.value);
};

export const getAllReferences = (action: WsResponse): string[] | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.filter(({ name }) => name === 'REFERENCE').map((ref) => ref.value);
};

export const getGenericPart = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'GENERIC_PART')?.value;
};

export const getFirstBrand = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'BRAND')?.value;
};

export const getFirstModel = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'MODEL')?.value;
};

export const getFirstModelType = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'MODEL_TYPE')?.value;
};

export const getFirstPromotionId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'PROMOTION_ID')?.value;
};

export const getFirstPictureKey = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'IMAGE_KEY')?.value;
};

export const getFirstSearchId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'SEARCH_ID')?.value;
};

export const getFirstInformationId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'INFORMATION_ID')?.value;
};
export const getInformationsStatus = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'STATUS_TYPE')?.value;
};

export const getFirstVIN = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'VIN')?.value;
};

export const getFirstSellerId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'SELLER_ID')?.value;
};

export const getFirstProvider = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'PROVIDER')?.value;
};

export const getDMSErrorMessages = (action: WsResponse): string[] | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.filter(({ name }) => name === 'DMS_ERROR_MSG').map((ref) => ref.value);
};

export const getDMSErrorType = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'DMS_ERROR_TYPE')?.value;
};

export const getTireSearchStatus = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'SEARCH_PARAMS_BASE_64')?.value;
};

export const getFirstEstimateId = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'ESTIMATE_ID')?.value;
};

export const getFirstOrderNumber = (action: WsResponse): string | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.find(({ name }) => name === 'ORDER_NUMBER')?.value;
};

export const getLaborTimeIds = (action: WsResponse): string[] | undefined => {
  const data = action.payload as BusinessError;
  return data?.errorDetail?.filter(({ name }) => name === 'LABOR_TIME_IDS').map((e) => e.value);
};
