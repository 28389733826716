import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/PromotionBanner';
import { ROUTER_PROMOTION } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import Loader from 'components/Loader';
import { getPicture } from 'domains/pictures/Pictures.store';
import { setPromotionBannerShowInCarousel } from 'domains/promotion/Promotion.store';
import { Banner } from 'UI';
import { NOT_FOUND } from 'utils';
import { DataContainer } from '../DataContainer';

const PromotionBannerWrapper = ({ banner }: { banner: PromotionBanner }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const imageBase64 = useSelector((state: RootState) => getPicture(state, banner.imageUrl));

  useEffect(() => {
    dispatch(
      setPromotionBannerShowInCarousel({
        id: banner.promotionId,
        show: imageBase64 !== NOT_FOUND && imageBase64 !== undefined,
      }),
    );
  }, [dispatch, imageBase64, banner.promotionId]);

  return (
    <DataContainer
      data={imageBase64}
      Loading={() => <Loader height={'400px'} />}
      NotFound={() => <></>}
      Error={() => <></>}
    >
      <Banner
        key={banner.promotionId}
        image={imageBase64 ?? ''}
        base64
        title={banner.title}
        description={banner.catchLine}
        buttonLabel={'Action'}
        onClick={() => history.push(`${ROUTER_PROMOTION}/${banner.promotionId}`)}
        onlyImage
        cursor={'pointer'}
      />
    </DataContainer>
  );
};

export default PromotionBannerWrapper;
