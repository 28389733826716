import React, { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, InputProps } from 'UI/Input';
import { textFormatter } from 'utils/format';

export interface InputNumberProps
  extends Omit<InputProps, 'value' | 'initialValue' | 'onBlur' | 'onBlurDebounced' | 'onChangeDebounced'> {
  value?: number;
  initialValue?: number;
  forceBlur?: boolean;
  onBlur?: (val?: number, e?: FocusEvent<HTMLInputElement>) => void;
  onBlurDebounced?: (val?: number, e?: FocusEvent<HTMLInputElement>) => void;
  onChangeDebounced?: (val?: number, e?: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  precisionMin?: number;
  precisionMax?: number;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
}

function clampValue(val: number, min: number | undefined, max: number | undefined) {
  const m = max && val > max ? max : val;
  return min && m < min ? min : m;
}

export function InputNumber(props: InputNumberProps) {
  // For now, minus sign is not supported
  const testString = useMemo(() => {
    const thousandSeparator = textFormatter.getThousandSeparator();
    const decimalSeparator = textFormatter.getDecimalSeparator();
    const enableThousandSeparator = !(props.max && props.max < 1000);
    const enableDecimalSeparator = !!props.precisionMin || !!props.precisionMax;
    return new RegExp(
      `[^0-9${enableThousandSeparator ? thousandSeparator : ''}${enableDecimalSeparator ? decimalSeparator : ''}]`,
    );
  }, [props.max, props.precisionMin, props.precisionMax]);

  const parser = useCallback(
    (x?: string): number | undefined =>
      x !== undefined && x !== '' ? clampValue(textFormatter.parseLocaleNumber(x), props.min, props.max) : undefined,
    [props.min, props.max],
  );

  const formatter = useCallback(
    (x?: number): string =>
      x !== undefined ? textFormatter.formatNumber(x, props.precisionMax, props.precisionMin) : '',
    [props.precisionMin, props.precisionMax],
  );

  const formatInitValue = useCallback((): string => formatter(props.value ?? props.initialValue) ?? '', [
    props.value,
    props.initialValue,
    formatter,
  ]);

  const [inputValue, setInputValue] = useState<string>(() => formatInitValue());

  useEffect(() => {
    setInputValue(formatInitValue);
  }, [formatInitValue]);

  return (
    <Input
      bordered
      {...props}
      value={inputValue}
      initialValue={formatter(props.initialValue)}
      maxValue={props.max}
      onChange={(val) => {
        if (testString.test(val)) return;
        setInputValue(val);
        props.onChange && props.onChange(val);
      }}
      onChangeDebounced={(val, e) => {
        if (val.length === 0) return;
        // Why ParserFormatterParser (PFP)? We need to set "props.precision"
        const numberPFP = parser(formatter(parser(String(val))));
        props.onChangeDebounced && props.onChangeDebounced(numberPFP, e);
      }}
      onBlur={(val, e) => {
        if (val.length === 0) {
          setInputValue(formatInitValue);
          props.forceBlur && props.onBlur && props.onBlur(-1, e);
          return;
        }
        const numberPF = formatter(parser(val));
        setInputValue(numberPF);
        const numberPFP = parser(numberPF);
        props.onBlur && props.onBlur(numberPFP, e);
      }}
      onBlurDebounced={(val, e) => {
        if (val.length === 0) return;
        // Why ParserFormatterParser (PFP)? We need to set "props.precision"
        const numberPFP = parser(formatter(parser(val)));
        props.onBlurDebounced && props.onBlurDebounced(numberPFP, e);
      }}
    />
  );
}
