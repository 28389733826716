import React from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { TFunction } from 'i18next';
import { ROUTER_PROMOTION } from 'app/AppRouter';
import { AngleRightIcon } from 'assets/icons';
import {
  SeeMoreSection,
  SPromotionCard,
} from 'pages/PromotionPage/PromotionCardsContainer/PromotionCard/PromotionCard.styled';
import PromotionCardImage from 'pages/PromotionPage/PromotionCardsContainer/PromotionCard/PromotionCardImage';
import { theme } from 'styles';
import { Flex, Icon, Link, MarginBox, Text } from 'UI';
import { getMomentDate } from 'utils/date';

const getDiscountText = (t: TFunction, promotion: Promotion) => {
  const referenceSections = promotion.references !== undefined ? promotion.references.length : 0;
  const cfSections = promotion.commercialFamilyCodes !== undefined ? promotion.commercialFamilyCodes.length : 0;
  const discountSections = referenceSections + cfSections;

  if (discountSections > 1) {
    const maxRefSectionDiscount =
      promotion.references?.reduce((acc, refSection) => {
        return refSection.discount > acc ? refSection.discount : acc;
      }, 0) ?? 0;
    const maxCFSectionDiscount =
      promotion.commercialFamilyCodes?.reduce(
        (acc, refSection) => (refSection.discount > acc ? refSection.discount : acc),
        0,
      ) ?? 0;
    const discount = maxRefSectionDiscount > maxCFSectionDiscount ? maxRefSectionDiscount : maxCFSectionDiscount;

    return t('backoffice.promotion.banner.discount.up_to', 'Up to {{ discount }}% off', { discount });
  }
  const firstRefDiscount = promotion.references?.find((r) => r.discount > 0)?.discount;
  const firstCFDiscount = promotion.commercialFamilyCodes?.find((r) => r.discount > 0)?.discount ?? 0;
  const discount = firstRefDiscount ?? firstCFDiscount;

  return t('backoffice.promotion.banner.discount', 'Get {{ discount }}% off', { discount });
};

const getDateTexts = (
  t: TFunction,
  startDate: string | undefined,
  endDate: string | undefined,
  remainingQuantity: number,
) => {
  const promotionStart = startDate ?? '';
  return endDate
    ? `${t('backoffice.promotion.from_date_to_date', 'From {{promotionStart}} to {{promotionEnd}}', {
        promotionStart,
        promotionEnd: endDate,
      })}`
    : `${t('backoffice.promotion.units_left', '{{count}} units left', {
        count: remainingQuantity,
      })}.`;
};

interface PromotionCardProps {
  promotion: Promotion;
}

const PromotionCard = ({ promotion }: PromotionCardProps) => {
  const { t } = useTranslation();
  const startDate = getMomentDate(promotion?.publicationStart);
  const endDate = getMomentDate(promotion?.publicationEnd)?.subtract(1, 'd');

  return (
    <Link to={`${ROUTER_PROMOTION}/${promotion.promotionId}`}>
      <SPromotionCard minHeight={350}>
        <PromotionCardImage image={promotion.image}>
          <Flex direction={'column'} align={'stretch'}>
            <SeeMoreSection direction={'row'} justify={'space-between'} align={'center'}>
              <MarginBox mx={15}>
                <Flex direction={'column'} justify={'center'}>
                  <Text type={'h5_light_white'}>{getDiscountText(t, promotion)}</Text>
                  <Text type={'text_white'}>
                    {getDateTexts(t, startDate?.format('MMMM Do, YYYY'), endDate?.format('MMMM Do, YYYY'), 0)}
                  </Text>
                </Flex>
              </MarginBox>
              <Flex justify={'flex-end'} align={'center'} maxWidth={150}>
                <Text type={'lead_dim'} displayStyle={'link'} cursor={'pointer'} hoverUnderLine>
                  <Flex direction={'row'} align={'center'}>
                    {t('common.action.see_more', 'See more')}
                    <MarginBox mr={15} />
                    <Icon IconComponent={AngleRightIcon} size={18} color={theme.color.info} />
                    <MarginBox mr={15} />
                  </Flex>
                </Text>
              </Flex>
            </SeeMoreSection>
          </Flex>
        </PromotionCardImage>
      </SPromotionCard>
    </Link>
  );
};

export default PromotionCard;
