import styled from 'styled-components';

export const SSearchResults = styled.div`
  background: white;
  z-index: 500;
  position: absolute;
  top: 47px;
  display: flex;
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
  white-space: nowrap;
  border-radius: 10px;
  box-shadow: 0 5px 5px 0 ${({ theme }) => theme.color.grey75};
`;

export const SItem = styled.div`
  padding-left: 10px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.color.grey90};
  }
`;
