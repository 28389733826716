import React from 'react';
import { useSelector } from 'react-redux';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { ReferenceField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateReference';
import { LinkedReference, Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Reference';
import { RootState } from 'app/AppStore';
import { getDiscountTextStyle } from 'components/Discount';
import { getDiscount } from 'domains/references';
import { SInputPrice, TooltipContent } from 'pages/EstimatePage/TableSection/TableSection.styled';
import { CenterFlex, Flex, Text, WithTooltip } from 'UI';
import { textFormatter } from 'utils';

export const calculateEstimateDiscountPrice = (
  discount: ReferenceDiscount | undefined,
  clientPrice: string,
  garagePrice: string,
  discountRate: string,
): number => {
  if (!discount) {
    return Number(garagePrice);
  }
  const basePrice = Number(clientPrice);
  const groupDiscount = Number(discountRate);

  switch (discount.discountType) {
    case 'PCL': {
      const joinDiscount = discount.discount + groupDiscount;
      return (basePrice / 100) * (100 - Math.min(joinDiscount, 100));
    }
    case 'PNMRA': {
      const partialPrice = (basePrice / 100) * (100 - groupDiscount);
      return (partialPrice / 100) * (100 - discount.discount);
    }
    default:
      return 0;
  }
};

export const EstimateGaragePrice = ({
  row,
  currency,
  handleUpdate,
}: {
  row: Reference | LinkedReference;
  currency: string;
  handleUpdate: (itemId: string, field: ReferenceField) => (newValue: string) => void;
}) => {
  const discount = useSelector((state: RootState) => getDiscount(state, row.referenceNumber));
  const totalGaragePrice = (Number(row.garagePrice) * row.quantity).toFixed(2);

  return (
    <>
      {row.isCustom ? (
        <Flex justify={'center'} align={'center'} minWidth={125}>
          <WithTooltip title={row.garagePrice && `${totalGaragePrice} ${currency}`} placement={'top'}>
            <TooltipContent>
              <SInputPrice
                initialValue={totalGaragePrice}
                onBlur={(value) => {
                  const newVal = value ? (Number(value) / row.quantity).toFixed(2) : undefined;
                  handleUpdate(row.itemId, 'GARAGE_PRICE')(newVal ?? '');
                }}
                bordered
                placeholder={'-'}
                currency={currency}
              />
            </TooltipContent>
          </WithTooltip>
        </Flex>
      ) : (
        <CenterFlex>
          <Text type={'light_14'} displayStyle={getDiscountTextStyle(discount)}>
            {textFormatter.formatCurrency(
              calculateEstimateDiscountPrice(discount, row.unitPrice, row.garagePrice, row.discountRate) * row.quantity,
              currency,
            )}
          </Text>
        </CenterFlex>
      )}
    </>
  );
};
