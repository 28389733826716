import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { WebSocketAction } from 'utils';
import { RequestState } from './enum/RequestState';
import { WebSocketState, WS_CONNECT, WS_DISCONNECT, WS_NAMESPACE } from './WebSocket.types';
import 'map.prototype.tojson';

// Init state
const initialState: WebSocketState = {
  connected: false,
  requests: new Map<string, WebSocketAction>(),
  processedRequests: new Map<string, Date>(), // todo create periodical cleanup - delete everything older than 5 minutes
  countErrorNoConnectionToBESide: 0,
};

// Middleware actions
export const wsConnect = createAction(WS_CONNECT);
export const wsDisconnect = createAction(WS_DISCONNECT);

// Slice
const slice = createSlice({
  name: WS_NAMESPACE,
  initialState,
  reducers: {
    setConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.connected = payload;
    },
    addRequest: (state, { payload }: PayloadAction<WebSocketAction>) => {
      state.requests.set(payload.traceId, payload);
    },
    addProcessedRequest: (state, { payload }: PayloadAction<string>) => {
      state.processedRequests.set(payload, new Date());
    },
    removeRequest: (state, { payload }: PayloadAction<string>) => {
      state.requests.delete(payload);
    },
    removeProcessedRequest: (state, { payload }: PayloadAction<string>) => {
      state.processedRequests.delete(payload);
    },
    setErrorNoConnectionToBESide: (
      state,
      { payload }: PayloadAction<{ connectionUnavailable: boolean; handleCount?: boolean }>,
    ) => {
      const { connectionUnavailable, handleCount } = payload;
      //ONEPORTAL-3685
      if (connectionUnavailable) {
        const toAdd = handleCount ? 1 : 3;
        state.countErrorNoConnectionToBESide += toAdd;
      } else {
        state.countErrorNoConnectionToBESide = 0;
      }
    },
  },
});

// Actions
export const {
  setConnected,
  addRequest,
  addProcessedRequest,
  removeRequest,
  removeProcessedRequest,
  setErrorNoConnectionToBESide,
} = slice.actions;

// Getters/Selectors
export const isConnected = createSelector(
  (state: RootState) => state.websockets.connected,
  (connected) => connected,
);

export const getRequests = createSelector(
  (state: RootState) => state.websockets.requests,
  (requests) => requests,
);

export const getProcessedRequests = createSelector(
  (state: RootState) => state.websockets.processedRequests,
  (processedRequests) => processedRequests,
);

export const getRequest = createSelector(
  getRequests,
  (_state: RootState, trackingId: string) => trackingId,
  (requests, trackingId) => requests.get(trackingId),
);

export const getProcessedRequest = createSelector(
  getProcessedRequests,
  (_state: RootState, trackingId: string) => trackingId,
  (processedRequests, trackingId) => processedRequests.get(trackingId),
);

export const getPendingRequests = createSelector(getRequests, (requests) =>
  [...requests.values()].filter((r) => r.state === RequestState.PENDING),
);

export const getPendingRequestsSorted = createSelector(getPendingRequests, (pending) =>
  [...pending].sort((a: WebSocketAction, b: WebSocketAction) => a.created.getTime() - b.created.getTime()),
);

export const getErrorNoConnectionToBESide = createSelector(
  (state: RootState) => state.websockets.countErrorNoConnectionToBESide,
  (countErrorNoConnectionToBESide) => countErrorNoConnectionToBESide >= 3,
);

// Export reducer
export default slice.reducer;
