import { ReactNode } from 'react';
import { Collapse as CollapseAnt } from 'antd';
import styled from 'styled-components';

const { Panel: PanelAnt } = CollapseAnt;

export const SPanel = styled(PanelAnt)<{ headerBorder?: boolean; children?: ReactNode }>`
  background-color: ${({ theme }) => theme.color.white};

  .ant-collapse-content {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    border: none;
  }

  .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    padding: 0px 0px 0px 0px;
  }

  .ant-collapse-content-active {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    border: none;
  }

  .ant-collapse-header-text {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 15px;
    margin-right: 0px;
    flex: auto !important;
  }

  .ant-collapse-header {
    border-top: 0px solid ${({ theme }) => theme.color.grey85};
    border-bottom: 1px solid ${({ theme }) => theme.color.grey85};
    border-left: 0px solid ${({ theme }) => theme.color.grey85};
    border-right: 0px solid ${({ theme }) => theme.color.grey85};
    box-shadow: ${({ theme }) => `0px 0px 0px 0px ${theme.color.grey95}`};
  }

  .ant-slider-track {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }

  .ant-slider:hover .ant-slider-rail {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }

  .ant-slider-handle {
    background-color: white;
    border-color: ${({ theme }) => theme.color.grey20};
  }

  .ant-slider-handle:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.color.clear_blue};
  }
`;
