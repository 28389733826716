import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReferenceField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateReference';
import { LinkedReference, Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { useTheme } from 'styled-components';
import { DotCircleIcon, UnlinkIcon } from 'assets/icons';
import { removeReference, updateReference } from 'domains/estimate/Estimate.store';
import { GarageView } from 'domains/user';
import { EstimateGarageMargin } from 'pages/EstimatePage/TableSection/EstimateGarageMargin';
import { EstimateGaragePrice } from 'pages/EstimatePage/TableSection/EstimateGaragePrice';
import {
  DiscountInput,
  EstimatePriceInput,
  GetTitle,
  QuantityInput,
  TextInput,
  VatInput,
} from 'pages/EstimatePage/TableSection/tableComponents';
import {
  ExpandedReferenceDotCircleWrapper,
  ExpandedReferenceWrapper,
  LinkedReferencesBackgroundWrapper,
  STable,
  TooltipContent,
} from 'pages/EstimatePage/TableSection/TableSection.styled';
import { Flex, Icon, MarginBox, Pipeline, Text, WithTooltip } from 'UI';
import { textFormatter } from 'utils';

export function LinkedReferenceTable(
  estimateId: string,
  parentReference: Reference,
  currency: string,
  sparePartsView?: string,
) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function removeLinkedRow(itemId: string) {
    dispatch(removeReference({ itemId, parentItemId: parentReference.itemId }));
  }

  const handleUpdate = (field: ReferenceField, itemId: string) => (newValue: string) => {
    dispatch(updateReference({ itemId, parentItemId: parentReference.itemId, field, newValue }));
  };

  if (!parentReference.linkedReferences || parentReference.linkedReferences.length === 0) {
    return null;
  }

  const tableColumns = [
    {
      title: GetTitle(t('catalog.reference', 'Reference')),
      dataIndex: 'referenceNumber',
      width: '8vw',
      render: function rowSelector(referenceNumber: string, row: LinkedReference) {
        return (
          <Flex justify={'center'} align={'center'}>
            <TextInput
              value={referenceNumber}
              saveFunction={() => undefined}
              removeRow={() => removeLinkedRow(row.itemId)}
              readOnly={!row.isCustom}
              minWidth={140}
            />
            {!row.isCompatible && (
              <WithTooltip
                title={t('estimate.reference.noncompatible', 'Not compatible with selected vehicle')}
                placement={'top'}
              >
                <TooltipContent>
                  <Flex size={0} align={'center'} justify={'center'}>
                    <Icon IconComponent={UnlinkIcon} size={16} color={'black'} display={'inline'} />
                  </Flex>
                </TooltipContent>
              </WithTooltip>
            )}
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      render: function rowSelector(designation: string, row: LinkedReference) {
        return <TextInput value={designation} saveFunction={() => undefined} readOnly={!row.isCustom} />;
      },
    },
    {
      title: GetTitle(t('common.price.unit_price', 'Unit Price')),
      dataIndex: 'unitPrice',
      width: '10vw',
      render: function rowSelector(unitPrice: string, row: LinkedReference) {
        return (
          <EstimatePriceInput
            value={unitPrice}
            saveFunction={() => undefined}
            readOnly={!row.isCustom}
            minWidth={125}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.quantity', 'Quantity')),
      dataIndex: 'quantity',
      width: '10vw',
      render: function rowSelector(quantity: number, row: LinkedReference) {
        return (
          <QuantityInput
            value={quantity}
            saveFunction={handleUpdate('QUANTITY', row.itemId)}
            removeRow={() => removeLinkedRow(row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.discount', 'Discount')),
      dataIndex: 'discount',
      width: '10vw',
      render: function rowSelector(discount: string, row: LinkedReference) {
        return (
          <DiscountInput
            value={discount}
            saveFunction={handleUpdate('DISCOUNT', row.itemId)}
            discountType={row.discountType}
            saveDiscountType={handleUpdate('DISCOUNT_TYPE', row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      width: '8vw',

      render: function rowSelector(priceVatExcluded: string) {
        return (
          <Flex justify={'center'} align={'center'}>
            <Text type={'light_14'}>
              {textFormatter.formatCurrency(isNaN(Number(priceVatExcluded)) ? 0 : priceVatExcluded, currency)}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat.title', 'VAT')),
      dataIndex: 'vatPercentage',
      width: '8vw',
      render: function rowSelector(vat: number, row: LinkedReference) {
        return <VatInput value={vat} saveFunction={() => undefined} readOnly={!row.isCustom} />;
      },
    },
    {
      title: undefined,
      dataIndex: 'empty-column-for-padding',
      width: 30,
      render: function rowSelector() {
        return <></>;
      },
    },
  ];

  if (sparePartsView === GarageView) {
    tableColumns.push(
      {
        title: GetTitle(t('common.price.garage_price', 'Garage Price')),
        dataIndex: 'garagePrice',
        width: '6vw',
        render: function rowSelector(_garagePrice: string, row: LinkedReference) {
          return (
            <EstimateGaragePrice
              row={row}
              currency={currency}
              handleUpdate={(_itemId: string, _field: ReferenceField) => (_newValue: string) => undefined}
            />
          );
        },
      },
      {
        title: GetTitle(t('common.price.margin', 'Margin')),
        dataIndex: 'margin',
        width: '6vw',
        render: function rowSelector(_margin: string, row: LinkedReference) {
          return (
            <MarginBox mr={55}>
              <EstimateGarageMargin estimateId={estimateId} row={row} currency={currency} />
            </MarginBox>
          );
        },
      },
    );
  }

  return (
    <ExpandedReferenceWrapper>
      <LinkedReferencesBackgroundWrapper>
        <Pipeline size={0} offsetRatio={100} color={theme.color.clear_blue} />
        <ExpandedReferenceDotCircleWrapper>
          <Icon IconComponent={DotCircleIcon} size={8} color={theme.color.clear_blue} noPointer />
        </ExpandedReferenceDotCircleWrapper>
        <MarginBox ml={5} mt={-15}>
          <Text type={'link_bold'}>{t('estimate.linked_references', 'Linked references')}</Text>
        </MarginBox>
      </LinkedReferencesBackgroundWrapper>

      <MarginBox mt={20} />
      <STable<LinkedReference>
        columns={tableColumns}
        dataSource={parentReference.linkedReferences}
        pagination={false}
        rowKey={(row) => row.itemId}
        size={'large'}
        showHeader={false}
        style={{ zIndex: 1 }}
      />
    </ExpandedReferenceWrapper>
  );
}
