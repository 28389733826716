import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { fetchSearchBMMVehicleEnginesSaga } from 'domains/catalog/Catalog.store';
import { IAMVehicleBrandLocal, VehicleBrandLocal } from 'domains/catalog/Catalog.types';
import { Box, Flex, MarginBox, Select, SelectOptionSingle } from 'UI';
import { getData, hasData, NO_DATA } from 'utils';
import {
  FormControl,
  SearchByModelAcceptButton,
  SearchByModelOptions,
  SearchByModelState,
  SearchByModelStateValue,
  SelectStep,
} from './SearchByModel';

const formMapping: Array<keyof SearchByModelStateValue> = ['brand', 'modelFamily', 'modelType', 'engine', 'gearbox'];

const getOptions = (items: Array<{ name?: string; code: string }> | NO_DATA) =>
  items && typeof items !== 'string'
    ? items.map((item) => ({ title: item?.name ?? item.code, value: item.code }))
    : items;

const getModelOptions = (searchByModelOptions: SearchByModelOptions, searchByModelState: SearchByModelState) => (
  formIndex: number,
): SelectOptionSingle[] | NO_DATA => {
  if (searchByModelOptions && typeof searchByModelOptions.brands !== 'string') {
    if (formIndex === 0) {
      return getOptions(searchByModelOptions?.brands);
    }

    const models = (searchByModelOptions?.brands?.filter((x) => x.source === 'DH') as VehicleBrandLocal[])?.find(
      ({ code }) => code === searchByModelState?.brand,
    )?.models;
    if (formIndex === 1) {
      return getOptions(models);
    }

    const modelTypes = models?.find(({ code }) => code === searchByModelState?.modelFamily)?.types;
    if (formIndex === 2) {
      return getOptions(modelTypes);
    }

    const engines = modelTypes?.find(({ code }) => code === searchByModelState?.modelType)?.engines;
    if (formIndex === 3) {
      return getOptions(engines);
    }

    if (formIndex === 4) {
      return getOptions(getData(engines)?.find(({ code }) => code === searchByModelState?.engine)?.gearboxes);
    }
  }
  return [];
};

const getModelStateTitle = (searchByModelState: SearchByModelState, searchByModelOptions: SearchByModelOptions) => {
  const options = getModelOptions(searchByModelOptions, searchByModelState);
  if (searchByModelState[formMapping[0]]) {
    return formMapping
      .map((propName, i) => {
        const opt = options(i);
        return hasData(opt) ? opt.find((option) => option && option.value === searchByModelState[propName])?.title : '';
      })
      .filter((x) => x)
      .join(' - ');
  } else {
    return '';
  }
};

const getModelStateIndex = (searchByModelState: SearchByModelState) =>
  formMapping.reduce(
    (acc, next, index) =>
      acc === 0 && searchByModelState[formMapping[formMapping.length - 1 - index]] ? formMapping.length - index : acc,
    0,
  );

const RenaultOrDaciaRightColumn = ({
  options,
  formControl,
  pendingQuery,
  setPendingQuery,
  requestId,
  formStateIndex,
  isFormCompleted,
}: {
  options: Array<SelectOptionSingle[] | NO_DATA>;
  formControl: FormControl;
  formStateIndex: number;
  pendingQuery: string | undefined;
  setPendingQuery: (x: string | undefined) => void;
  requestId: string;
  isFormCompleted: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction={'column'}>
      <Flex minWidth={270}>
        <DataContainer
          data={options[3]}
          Error={() => (
            <Box height={40}>
              <Flex direction={'column'} align={'center'}>
                <MarginBox mt={10} />
                <ErrorWithLabel
                  label={t('catalog.vehicle.engine.backend_error', 'Engine data temporarily unavailable')}
                  narrow
                />
              </Flex>
            </Box>
          )}
          Skeleton={() => (
            <Flex minWidth={270}>
              <Select
                placeholder={t('catalog.vehicle.engine', 'Engine')}
                disabled={formStateIndex < 3}
                bordered
                search
                alignLeft
                size={'middle'}
                onChange={() => undefined}
              />
            </Flex>
          )}
        >
          <SelectStep
            placeholder={t('catalog.vehicle.engine', 'Engine')}
            formIndex={3}
            formControl={formControl}
            options={options[3] as SelectOptionSingle[]}
            formMapping={formMapping}
            formStateIndex={formStateIndex}
          />
        </DataContainer>
      </Flex>
      <Box height={15} />
      <Flex>
        <DataContainer
          data={options[4]}
          Error={() => (
            <Flex justify={'center'}>
              <ErrorWithLabel
                label={t('catalog.vehicle.gearbox.backend_error', 'Gearbox data temporarily unavailable')}
                narrow
              />
            </Flex>
          )}
          Skeleton={() => (
            <Flex minWidth={270}>
              <Select
                placeholder={t('catalog.vehicle.gearbox', 'Gearbox')}
                disabled={formStateIndex < 4}
                bordered
                search
                alignLeft
                size={'middle'}
                onChange={() => undefined}
              />
            </Flex>
          )}
        >
          <SelectStep
            placeholder={t('catalog.vehicle.gearbox', 'Gearbox')}
            formIndex={4}
            formControl={formControl}
            options={options[4] as SelectOptionSingle[]}
            formMapping={formMapping}
            formStateIndex={formStateIndex}
          />
        </DataContainer>
      </Flex>
      <Box height={15} />
      <SearchByModelAcceptButton
        catalogSource={'DATAHUB'}
        setPendingQuery={setPendingQuery}
        pendingQuery={pendingQuery}
        isFormCompleted={isFormCompleted}
        formControl={formControl}
        requestId={requestId}
      />
    </Flex>
  );
};

export const SearchByModelDH = ({
  brands,
  formControl,
  pendingQuery,
  setPendingQuery,
  setFormStateTitle,
  formStateTitle,
}: {
  brands: Array<VehicleBrandLocal | IAMVehicleBrandLocal> | NO_DATA;
  formControl: FormControl;
  pendingQuery: string | undefined;
  setPendingQuery: (x: string | undefined) => void;
  formStateTitle: string;
  setFormStateTitle: (x: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formState = formControl.formState;
  const formOptions = { brands } as SearchByModelOptions;
  const formStateIndex = useMemo(() => getModelStateIndex(formState), [formState]);
  const formStateTitleNew = getModelStateTitle(formState, formOptions);
  const isFormCompleted = formStateIndex === formMapping.length;
  // eslint-disable-next-line max-len
  const requestId = `${formState.brand}_${formState.modelFamily}_${formState.modelType}_${formState.engine}_${formState.gearbox}`;
  const options = formMapping.map((propName, index) => getModelOptions(formOptions, formState)(index));

  useEffect(() => {
    if (formStateIndex === 3 && hasData(brands)) {
      const brandCode = formState.brand;
      const modelFamilyCode = formState.modelFamily;
      const modelTypeCode = formState.modelType;

      if (brandCode && modelFamilyCode && modelTypeCode) {
        const brand = (brands.filter((x) => x.source === 'DH') as VehicleBrandLocal[]).find(
          (b) => b.code === brandCode,
        );
        const model = brand?.models.find((m) => m.code === modelFamilyCode);
        const modelType = model?.types.find((mdlType) => mdlType.code === modelTypeCode);

        if (modelType && !modelType.engines) {
          dispatch(
            fetchSearchBMMVehicleEnginesSaga({
              brandCode,
              modelTypeCode,
              modelCode: modelFamilyCode,
            }),
          );
        }
      }
    }
  }, [brands, dispatch, formState, formStateIndex]);

  useEffect(() => {
    if (formStateTitleNew !== formStateTitle) {
      setFormStateTitle(formStateTitleNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStateTitle, formStateTitleNew]);

  return (
    <>
      <>
        <Flex direction={'column'} maxHeight={144}>
          {options && (hasData(options[0]) || hasData(options[1]) || hasData(options[2])) && (
            <>
              <Flex>
                {Array.isArray(options[0]) && options[0]?.length > 0 ? (
                  <SelectStep
                    placeholder={t('catalog.parts.search.by_model.brand', 'Brand')}
                    formIndex={0}
                    formControl={formControl}
                    options={options[0] as SelectOptionSingle[]}
                    formMapping={formMapping}
                    formStateIndex={formStateIndex}
                  />
                ) : (
                  <Select onChange={() => undefined} placeholder={t('catalog.parts.search.by_model.brand', 'Brand')} />
                )}
              </Flex>
              <Box height={15} />
              <Flex>
                <SelectStep
                  placeholder={t('catalog.parts.search.by_model.model_family', 'Model Family')}
                  formIndex={1}
                  formControl={formControl}
                  options={options[1] as SelectOptionSingle[]}
                  formMapping={formMapping}
                  formStateIndex={formStateIndex}
                />
              </Flex>
              <Box height={15} />
              <Flex>
                <SelectStep
                  placeholder={t('catalog.parts.search.by_model.model_type', 'Model Type')}
                  formIndex={2}
                  formControl={formControl}
                  options={options[2] as SelectOptionSingle[]}
                  formMapping={formMapping}
                  formStateIndex={formStateIndex}
                />
              </Flex>
            </>
          )}
        </Flex>
        <Box width={30} />
        <Box width={30} />
        <Flex direction={'column'}>
          <RenaultOrDaciaRightColumn
            requestId={requestId}
            options={options}
            formControl={formControl}
            isFormCompleted={isFormCompleted}
            pendingQuery={pendingQuery}
            setPendingQuery={setPendingQuery}
            formStateIndex={formStateIndex}
          />
        </Flex>
      </>
    </>
  );
};
