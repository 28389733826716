import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { getExplodedTreeItem, getSearchVehicleResult } from 'domains/catalog/Catalog.store';
import { MultiPlate, PlateImage } from 'domains/catalog/Catalog.types';
import { formatCategoryLabelToDataCy } from 'domains/catalog/Catalog.utils';
import { Flex, MarginBox, sentenceCase, Text, WithTooltip } from 'UI';
import { ERROR, getData, LOADING, NO_DATA, NOT_FOUND } from 'utils';
import PlateNoDataCard from './PlateNoDataCard';
import PlatePreviewCard from './PlatePreviewCard';
import { SubcategoryCard } from './Subcategory.styled';

interface SubcategoriesViewProps {
  query: string;
  carPartGroupId: string;
  categoryId: string;
  subcategoryId: string;
  label: string;
  multiplates: Array<MultiPlate> | NO_DATA;
  plates: Map<string, PlateImage | undefined>;
  onSelectSubcategory: (subcategoryId: string) => void;
  onSelectMultiplate: (subcategoryId: string, plateIds: string[]) => void;
}

const renderContent = (
  subcategoryId: string,
  multiplates: Array<MultiPlate> | NO_DATA,
  plates: Map<string, PlateImage | undefined>,
  onSelectMultiplate: (subcategoryId: string, plateIds: string[]) => void,
  hasPlates: boolean,
  hasReferences: boolean | undefined,
  hasLaborTimes: boolean | undefined,
  hasReferencesAndLaborTime: boolean | undefined,
) => {
  if (hasPlates && Array.isArray(multiplates)) {
    return multiplates?.map((multiplate, index) => {
      if (multiplate && multiplate.plateIds?.length > 0) {
        const plate = plates.get(multiplate.plateIds[0]);
        if (plate) {
          return (
            <PlatePreviewCard
              key={index}
              plate={plate}
              relatedPlateIds={multiplate.plateIds}
              onClick={(e: any) => {
                onSelectMultiplate(subcategoryId, multiplate.plateIds);
                e.stopPropagation();
              }}
            />
          );
        }
      }
    });
  }

  return (
    <PlateNoDataCard
      status={undefined}
      hasReferences={hasReferences}
      hasLaborTimes={hasLaborTimes}
      hasReferencesAndLaborTime={hasReferencesAndLaborTime}
    />
  );
};

const Subcategory = ({
  query,
  carPartGroupId,
  categoryId,
  subcategoryId,
  label,
  multiplates,
  plates,
  onSelectSubcategory,
  onSelectMultiplate,
}: SubcategoriesViewProps) => {
  const hasPlates = Array.isArray(multiplates) && multiplates.length > 0 && plates && plates.size > 0;
  const vehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const tree = getData(vehicleResult?.explodedTree);
  const subcategory = getExplodedTreeItem(tree, [carPartGroupId, categoryId, subcategoryId]);
  const hasReferences = (subcategory && subcategory?.plateIds.length > 0) || subcategory?.containsMarketingReferences;
  const hasLaborTimes = subcategory && subcategory?.laborTimeIds.length > 0;
  const hasReferencesAndLaborTime = hasReferences && hasLaborTimes;
  const cardLabel = sentenceCase(label);
  return (
    <Flex>
      <SubcategoryCard
        onClick={() => {
          onSelectSubcategory(subcategoryId);
        }}
        title={
          <WithTooltip title={cardLabel}>
            <Text type={'h4_white'} dataCy={formatCategoryLabelToDataCy(cardLabel, 'title')}>
              {cardLabel}
            </Text>
          </WithTooltip>
        }
        data-cy={formatCategoryLabelToDataCy(cardLabel, 'tab')}
      >
        <MarginBox my={15} mx={7.5}>
          <DataContainer
            data={multiplates}
            Skeleton={() => (
              <PlateNoDataCard
                status={undefined}
                hasReferences={hasReferences}
                hasLaborTimes={hasLaborTimes}
                hasReferencesAndLaborTime={hasReferencesAndLaborTime}
              />
            )}
            Loading={() => (
              <PlateNoDataCard
                status={LOADING}
                hasReferences={hasReferences}
                hasLaborTimes={hasLaborTimes}
                hasReferencesAndLaborTime={hasReferencesAndLaborTime}
              />
            )}
            NotFound={() => (
              <PlateNoDataCard
                status={NOT_FOUND}
                hasReferences={hasReferences}
                hasLaborTimes={hasLaborTimes}
                hasReferencesAndLaborTime={hasReferencesAndLaborTime}
              />
            )}
            Error={() => (
              <PlateNoDataCard
                status={ERROR}
                hasReferences={hasReferences}
                hasLaborTimes={hasLaborTimes}
                hasReferencesAndLaborTime={hasReferencesAndLaborTime}
              />
            )}
          >
            {renderContent(
              subcategoryId,
              multiplates,
              plates,
              onSelectMultiplate,
              hasPlates,
              hasReferences,
              hasLaborTimes,
              hasReferencesAndLaborTime,
            )}
          </DataContainer>
        </MarginBox>
      </SubcategoryCard>
    </Flex>
  );
};

export default Subcategory;
