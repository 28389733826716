import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Multiplate } from '@1po/1po-bff-fe-spec/generated/catalog/tree/response/Multiplate';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { PlateImage } from 'domains/catalog/Catalog.types';
import { MarginBox } from 'UI';
import { NO_DATA } from 'utils';
import PlateThumbnailCard from './PlateThumbnailCard';
import { SPlateErrorCard } from './PlateThumbnailCard/PlateThumbnailCard.styled';
import { SBox, ScrollContainerPlates } from './PlateThumbnailSection.styled';

export const PlateThumbnailSection = ({
  multiplates,
  handleSelectMultiplate,
  plateImage,
  selectedPlateId,
}: {
  multiplates: Multiplate[] | NO_DATA;
  handleSelectMultiplate: (plateIds: string[]) => void;
  plateImage: PlateImage | undefined;
  selectedPlateId: string | undefined;
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (selectedPlateId) {
      const selectedPlate = document.getElementsByClassName('thumbnail_id' + selectedPlateId);
      selectedPlate[0]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedPlateId]);

  return (
    <SBox height={574}>
      <ScrollContainerPlates>
        <MarginBox>
          <DataContainer
            data={multiplates}
            NotFound={() => <></>}
            Error={() => (
              <SPlateErrorCard active={false}>
                <ErrorWithLabel label={t('catalog.plate.backend_error', 'Plate temporarily unavailable')} />
              </SPlateErrorCard>
            )}
          >
            {Array.isArray(multiplates) &&
              multiplates?.map((multiplate, index) => {
                const selectedPlate = multiplate?.plateIds ? selectedPlateId ?? multiplate?.plateIds[0] : undefined;
                return (
                  multiplate &&
                  selectedPlate &&
                  multiplate.plateIds &&
                  multiplate.plateIds.length > 0 && (
                    <PlateThumbnailCard
                      key={`multiplate-${index}`}
                      plateIds={multiplate.plateIds}
                      onClick={(e: any) => {
                        if (multiplate.plateIds) {
                          handleSelectMultiplate(multiplate.plateIds);
                        }
                        e.stopPropagation();
                      }}
                      active={plateImage?.plateId ? multiplate.plateIds.includes(plateImage.plateId) : false}
                      first={index === 0}
                      selectedPlateId={multiplate.plateIds[0]}
                    />
                  )
                );
              })}
          </DataContainer>
        </MarginBox>
      </ScrollContainerPlates>
    </SBox>
  );
};
