/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { RootState } from 'app/AppStore';
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from 'assets/icons';
import CloseButton from 'components/CloseButton';
import { DataContainer } from 'components/DataContainer';
import { calculateDiscountPrice } from 'components/Discount';
import {
  clearExpiredDiscountsReferences,
  getExpiredDiscountsReferences,
  getReferenceFromBasket,
  notifyUserAboutExpiredDiscountsRequest,
} from 'domains/basket/Basket.store';
import { getBannersCount } from 'domains/information/Information.store';
import { getDiscount, getPrice } from 'domains/references';
import { getCurrency } from 'domains/user';
import { CART_STEPS_HEIGHT } from 'pages/CartPage/CartPage';
import { CART_HEADER_HEIGHT } from 'pages/CartPage/CartStep/CartStep';
import { NotificationWrapper } from 'pages/CartPage/CartStep/ExpiredDiscountsNotification/ExpiredDiscountsNotification.styled';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Spin, Text } from 'UI';
import { getData, textFormatter } from 'utils';
import { scrollToElementSmooth } from 'utils/hooks/useResetScroll';
/* eslint-enable max-len */

const MAX_REFERENCE_SECTION_HEIGHT = 180;
const DEFAULT_VISIBLE_REFERENCES = 3;
const SEE_REFERENCES_BUTTON_HEIGHT = 24;

const NotificationReferenceRow = ({
  referenceNumber,
  expiredDiscount,
  setHighlightedReference,
}: {
  referenceNumber: string;
  expiredDiscount: ReferenceDiscount;
  setHighlightedReference: (referenceNumber: string | undefined) => void;
}) => {
  const { t } = useTranslation();
  const isBanners = useSelector((state: RootState) => getBannersCount(state, 'CART')) > 0;
  const currency = useSelector(getCurrency);
  const discount = useSelector((state: RootState) => getDiscount(state, referenceNumber));
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));
  const reference = useSelector((state: RootState) => getReferenceFromBasket(state, referenceNumber));

  const expiredDiscountPrice = calculateDiscountPrice(expiredDiscount, getData(price));
  const discountPrice = calculateDiscountPrice(discount, getData(price));

  const handleReferenceNumberClick = () => {
    const referenceRow = document.getElementById(`cart-reference-row-${referenceNumber}`);
    if (!referenceRow) {
      return;
    }
    setHighlightedReference(referenceNumber);
    const offset = CART_HEADER_HEIGHT + CART_STEPS_HEIGHT + 30;
    scrollToElementSmooth(referenceRow, isBanners, offset);
  };

  return (
    <Flex direction={'row'} align={'center'}>
      <Text type={'text'} displayStyle={'info'} cursor={'pointer'} hoverUnderLine onClick={handleReferenceNumberClick}>
        {t('cart.dialog.referenceNumber.short', 'Ref: {{ referenceNumber }}', {
          referenceNumber,
        })}
      </Text>
      <MarginBox mr={15} />
      <DataContainer
        data={price}
        Loading={() => <Spin size={'small'} />}
        Error={() => (
          <Text type={'text'}>
            {t('common.price.backend_error', 'Price temporarily unavailable, please try again later.')}
          </Text>
        )}
      >
        <Text type={'text'}>
          {t('cart.end_of_promotions', '{{designation}} went from {{from}} to', {
            designation: reference?.name,
            from: textFormatter.formatCurrency(expiredDiscountPrice, currency),
          })}
        </Text>
        <MarginBox mr={3} />
        <Text type={'text'} displayStyle={'info'}>
          <strong>{textFormatter.formatCurrency(discountPrice, currency)}</strong>
        </Text>
      </DataContainer>
    </Flex>
  );
};

interface CartWarningNotificationProps {
  setHighlightedReference: (referenceNumber: string | undefined) => void;
}

const ExpiredDiscountsNotification = ({ setHighlightedReference }: CartWarningNotificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showReferences, setShowReferences] = useState(false);
  const expiredDiscountsReferences = useSelector(getExpiredDiscountsReferences);

  const handleShowReferences = (show: boolean) => {
    setShowReferences(show);
  };

  const handleClose = () => {
    setHighlightedReference(undefined);
    dispatch(clearExpiredDiscountsReferences());
    dispatch(notifyUserAboutExpiredDiscountsRequest());
  };

  return (
    <>
      {expiredDiscountsReferences.length > 0 && (
        <>
          <NotificationWrapper>
            <ExclamationCircleIcon width={45} height={45} fill={theme.color.warning} />
            <MarginBox ml={15} />
            <Flex direction={'column'} justify={'center'} size={4}>
              <Text type={'text_bold'}>{t('cart.warning', 'Important messages regarding items in your cart:')}</Text>
              <Text type={'text'}>
                {t(
                  'cart.promotion.not_available',
                  'Promotion not available anymore, some items in your cart have changed prices.',
                )}
              </Text>
              <MarginBox mt={15} />
              <Flex
                direction={'column'}
                maxHeight={MAX_REFERENCE_SECTION_HEIGHT}
                overflowY={showReferences ? 'scroll' : 'hidden'}
              >
                {expiredDiscountsReferences
                  .slice(0, showReferences ? expiredDiscountsReferences.length : DEFAULT_VISIBLE_REFERENCES)
                  .map((ref) => {
                    return (
                      // promotionType: 'DISCOUNT' used to pass required props validation, not used in discount calculation
                      <NotificationReferenceRow
                        key={ref.reference}
                        referenceNumber={ref.reference}
                        expiredDiscount={{
                          referenceNumber: ref.reference,
                          discount: ref.discount,
                          promotionType: 'DISCOUNT',
                          discountType: ref.discountType,
                        }}
                        setHighlightedReference={setHighlightedReference}
                      />
                    );
                  })}
              </Flex>
              <MarginBox mt={15} />
              {expiredDiscountsReferences.length > DEFAULT_VISIBLE_REFERENCES && (
                <Flex direction={'row'} align={'center'} maxHeight={SEE_REFERENCES_BUTTON_HEIGHT}>
                  {showReferences ? (
                    <Text
                      type={'h6_light'}
                      onClick={() => handleShowReferences(false)}
                      cursor={'pointer'}
                      hoverUnderLine
                    >
                      {t('cart.action.hide_references', 'Hide references')}
                      <Icon IconComponent={ChevronUpIcon} height={15} width={15} color={theme.color.grey10} ml={5} />
                    </Text>
                  ) : (
                    <Text
                      type={'h6_light'}
                      onClick={() => handleShowReferences(true)}
                      cursor={'pointer'}
                      hoverUnderLine
                    >
                      {t('cart.action.see_references', 'See {{count}} other references', {
                        count: expiredDiscountsReferences.length - DEFAULT_VISIBLE_REFERENCES,
                      })}
                      <Icon IconComponent={ChevronDownIcon} height={15} width={15} color={theme.color.grey10} ml={5} />
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
            <MarginBox ml={15} />
            <CloseButton onClose={() => handleClose()} align={'right'} type={'dark_12_white_bold'} />
          </NotificationWrapper>
          <MarginBox mt={5} />
        </>
      )}
    </>
  );
};

export default ExpiredDiscountsNotification;
