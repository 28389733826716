import { RequestState } from 'domains/webSockets/enum/RequestState';

// in milliseconds
export const ONE_SEC = 1000;
export const FIVE_SEC = 5000;
export const ELEVEN_SEC = 11000;
export const TWENTY_SEC = 20000;
export const HALF_MINUTE = 30000;
export type WebSocketActionTimeout =
  | typeof ONE_SEC
  | typeof FIVE_SEC
  | typeof ELEVEN_SEC
  | typeof TWENTY_SEC
  | typeof HALF_MINUTE;

export interface WebSocketAction {
  type: string; // WS_... specific action (it is WS_SEND_REQUEST in 99.95%)
  url: string;
  payload: any;
  timeout: WebSocketActionTimeout | undefined;
  traceId: string;
  state: RequestState;
  // time when request was created on the frontend side
  created: Date;
}
