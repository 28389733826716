import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TechnicalDataDetailContent } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataDetailContent';
import { TechnicalDataL3 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL3';
import { RootState } from 'app/AppStore';
import { ImageIcon } from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { getIamCatalogFilters, getVehicleTechnicalDataCategoryL3Data } from 'domains/catalog/Catalog.store';
import {
  SListItem,
  TechnicalDataContainer,
  TechnicalDataHeader,
} from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalData.styled';
import { theme } from 'styles';
import { Flex, Icon, Link, MarginBox, Spin, Text } from 'UI';
import { ERROR, LOADING, NO_DATA } from 'utils';

const VehicleTechnicalDataL3Detail = ({
  l3Category,
  l3Data,
}: {
  l3Category: TechnicalDataL3;
  l3Data: TechnicalDataDetailContent;
}) => {
  return (
    <Flex>
      <Flex size={3}>
        <Text type={'light_14_black_85'}>
          {l3Category.label}
          {l3Data.variant && ` ${l3Data.variant}`}
        </Text>
        <MarginBox ml={5} />
      </Flex>
      <Flex>
        {l3Data.value && (
          <>
            <Text type={'light_14_black_85'}>
              {l3Data.value}
              {l3Data.unit && ` ${l3Data.unit}`}
            </Text>
            <MarginBox ml={5} />
          </>
        )}
      </Flex>
      <Flex>
        {l3Data.additionalInformation ? (
          <Text type={'light_14_black_85'}>{l3Data.additionalInformation}</Text>
        ) : (
          <>
            {l3Data.imgUrl && (
              <Link to={l3Data.imgUrl} external newTab>
                <Flex direction={'row'}>
                  <Text
                    type={'light_14_black_85'}
                    displayStyle={'link'}
                    decoration={'underline'}
                    cursor={'pointer'}
                    disableGutter
                  >
                    <Trans i18nKey={'catalog.details.vehicle_technical_data.see_attached_file'}>
                      See attached file
                    </Trans>
                  </Text>
                  <MarginBox mr={10} />
                  <Icon IconComponent={ImageIcon} color={theme.color.clear_blue} width={20} height={15} mt={3} />
                </Flex>
              </Link>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

const VehicleTechnicalDataL3DetailSkeleton = ({
  l3Category,
  status,
}: {
  l3Category: TechnicalDataL3;
  status: NO_DATA;
}) => {
  return (
    <SListItem>
      <Flex direction={'row'}>
        <Flex size={1}>
          <Text type={'light_14_black_85'}>{l3Category.label}</Text>
        </Flex>
        {status === ERROR ? (
          <Flex align={'flex-end'}>
            <ErrorWithLabel narrow />
          </Flex>
        ) : (
          <>
            <Flex />
            <Spin size={'small'} />
            <Flex />
          </>
        )}
      </Flex>
    </SListItem>
  );
};

const l3IdPrefix = 'vehicle-technical_data-category_l3-variant-';
const VehicleTechnicalDataL3 = ({ l3Category }: { l3Category: TechnicalDataL3 }) => {
  const l3categoryData = useSelector((state: RootState) => getVehicleTechnicalDataCategoryL3Data(state, l3Category.id));
  const l3Data = l3categoryData?.technicalDataContentList;

  return (
    <Flex direction={'column'}>
      <DataContainer
        data={l3categoryData?.searchStatus}
        Loading={() => <VehicleTechnicalDataL3DetailSkeleton l3Category={l3Category} status={LOADING} />}
        Error={() => <VehicleTechnicalDataL3DetailSkeleton l3Category={l3Category} status={ERROR} />}
      >
        {l3Data?.map((data, idx) => (
          <SListItem key={`${l3IdPrefix}${idx}`}>
            <VehicleTechnicalDataL3Detail l3Category={l3Category} l3Data={data} />
          </SListItem>
        ))}
      </DataContainer>
    </Flex>
  );
};

const VehicleTechnicalDataL2 = ({
  l3Categories,
  categoryLabel,
}: {
  l3Categories: TechnicalDataL3[];
  categoryLabel: string;
}) => {
  const categoriesFilter = useSelector(getIamCatalogFilters).categories;

  if (categoriesFilter.length > 0 && !categoriesFilter.includes(categoryLabel ?? '')) {
    return null;
  }

  return (
    <Flex direction={'column'}>
      <TechnicalDataContainer>
        <TechnicalDataHeader>
          <Text type={'h6'}>{categoryLabel}</Text>
        </TechnicalDataHeader>
        {l3Categories.map((l3) => (
          <VehicleTechnicalDataL3 key={`vehicle-technical_data-category_l3-${l3.id}`} l3Category={l3} />
        ))}
      </TechnicalDataContainer>
    </Flex>
  );
};

export default VehicleTechnicalDataL2;
