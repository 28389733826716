export var QueryResponseType;
(function (QueryResponseType) {
    QueryResponseType[QueryResponseType["INIT"] = 0] = "INIT";
    QueryResponseType[QueryResponseType["COMMAND_LOOKUP_RESPONSE"] = 1] = "COMMAND_LOOKUP_RESPONSE";
    QueryResponseType[QueryResponseType["COMMAND_RESPONSE_SUCCESS"] = 2] = "COMMAND_RESPONSE_SUCCESS";
    QueryResponseType[QueryResponseType["EVENT_UPDATE"] = 3] = "EVENT_UPDATE";
    QueryResponseType[QueryResponseType["COMMAND_RESPONSE_NO_DATA"] = 4] = "COMMAND_RESPONSE_NO_DATA";
    QueryResponseType[QueryResponseType["COMMAND_RESPONSE_NO_CHANGE"] = 5] = "COMMAND_RESPONSE_NO_CHANGE";
    QueryResponseType[QueryResponseType["COMMAND_RESPONSE_INVALID"] = 6] = "COMMAND_RESPONSE_INVALID";
    QueryResponseType[QueryResponseType["COMMAND_RESPONSE_ERROR"] = 7] = "COMMAND_RESPONSE_ERROR";
})(QueryResponseType || (QueryResponseType = {}));
