/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import PrivateComponent from 'composers/PrivateComponent';
import { getPicture } from 'domains/pictures/Pictures.store';
import {
  fetchPromotionBannersRequestSaga,
  fetchSavePromotionRequestSaga,
  getPublishedStatus,
} from 'domains/promotion/Promotion.store';
import { CreatePromotionStepProps } from 'domains/promotion/Promotion.types';
import { UserRole } from 'domains/user';
import { SCard } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion.styled';
import {
  getBannerPromotionsData,
  getDiscountPromotionsData,
  getFlashPromotionsData,
} from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/PublicationStep.types';
import { TableBanner } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/TableBanner';
import { TableDiscount } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/TableDiscount';
import { TableQuantityLimited } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/TableQuantityLimited';
import { TableTimeLimited } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/TableTimeLimited';
import { CREATE_NEW } from 'pages/BackOfficePage/BackOfficePage.type';
import {
  ArrowLeftButtonRound,
  ArrowRightButtonRound,
  Banner,
  Box,
  CenteredSpin,
  Flex,
  MarginBox,
  StepButtons,
  Text,
  YellowButton,
} from 'UI';
import { notifyTop } from 'UI/Notification/notification';

import { ERROR, hasData, LOADING, NOT_FOUND } from 'utils';

export interface PublicationStepProps extends CreatePromotionStepProps {
  createNext: () => void;
}

export const PublicationStep = ({
  currentStep,
  setCurrentStep,
  totalSteps,
  promotion,
  createNext,
}: PublicationStepProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAdditionalDiscount = promotion.additionalInformation.discount
    ? promotion.additionalInformation.discount > 0
    : false;
  const publishedStatus = useSelector((state: RootState) => getPublishedStatus(state, promotion.promotionId));
  const [showAdditionalSection, setShowAdditionalSection] = useState(false);
  const [showStepButtons, setShowStepButtons] = useState(
    promotion.promotionType !== 'DISCOUNT' ||
      (promotion.promotionType === 'DISCOUNT' && !hasAdditionalDiscount) ||
      (promotion.promotionType === 'DISCOUNT' && showAdditionalSection),
  );

  const promotionImage = showAdditionalSection
    ? promotion.additionalInformation?.image ?? promotion?.image
    : promotion?.image;
  const bannerImage = useSelector((state: RootState) => getPicture(state, promotionImage?.key));
  const imageBase64 = bannerImage ?? `${promotionImage?.base64}`;

  useEffect(() => {
    if (hasData(publishedStatus)) {
      const isActive = (link: string) => history.location.pathname.includes(link);
      if (publishedStatus === true) {
        dispatch(fetchPromotionBannersRequestSaga());
        const message = isActive(CREATE_NEW)
          ? t('backoffice.promotion.creation_result.success', 'Promotion created')
          : t('backoffice.promotion.edit_result.success', 'Promotion edited');
        const description = isActive(CREATE_NEW)
          ? t(
              'backoffice.promotion.creation_result.success.description',
              'The publication of your promotion has been taken into account and will soon be displayed online on Rpartstore',
            )
          : t(
              'backoffice.promotion.edit_result.success.description',
              'The edit of your promotion has been taken into account and will be displayed online on Rpartstore',
            );
        notifyTop('success', message, description);
      } else {
        const message = isActive(CREATE_NEW)
          ? t('backoffice.promotion.creation_result.failed', 'Promotion not created')
          : t('backoffice.promotion.edit_result.failed', 'Promotion not edited');
        notifyTop(
          'warning',
          message,
          t('backoffice.promotion.creation_result.failed.description', 'Promotion was not published'),
        );
      }
    }
  }, [publishedStatus, dispatch, history, t]);

  const handlePublish = () => {
    dispatch(fetchSavePromotionRequestSaga({ shouldPublish: true }));
  };

  const rightButton = () => {
    switch (publishedStatus) {
      case LOADING:
        return <CenteredSpin size={'small'} />;
      case ERROR:
        return (
          <MarginBox my={5}>
            <ErrorWithLabel
              label={t(
                'common.publish.backend_error',
                'Error occurred during promotion creation, please try again later.',
              )}
              narrow
            />
          </MarginBox>
        );
      case NOT_FOUND:
      case undefined:
        return (
          <YellowButton stretch onClick={handlePublish} disabled={hasData(publishedStatus)}>
            {t('common.publish', 'Publish')}
          </YellowButton>
        );
      default:
        return (
          <YellowButton stretch onClick={createNext}>
            {t('common.create_another', 'Create another')}
          </YellowButton>
        );
    }
  };

  const getPublicationItems = () => {
    switch (promotion.promotionType) {
      case 'DISCOUNT': {
        return <TableDiscount data={getDiscountPromotionsData(promotion, showAdditionalSection)} />;
      }
      case 'BANNER': {
        return <TableBanner data={getBannerPromotionsData(promotion)} />;
      }
      case 'PROMOTION_FLASH_TIME_LIMITED': {
        return <TableTimeLimited data={getFlashPromotionsData(promotion)} />;
      }
      case 'PROMOTION_FLASH_QUANTITY_LIMITED': {
        return <TableQuantityLimited data={getFlashPromotionsData(promotion)} />;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <MarginBox mt={45} />
      <Text type={'h2'}>{t('backoffice.promotion.publication_preview', 'Publication preview')}</Text>
      <Text type={'text'}>
        {t(
          'backoffice.promotion.publication_preview.description',
          // eslint-disable-next-line max-len
          'Make sure to use appropriate visual, where users can clearly identify the promoted product and make sure that the text will be accessible.',
        )}
      </Text>
      <MarginBox mt={30} />
      {getPublicationItems()}
      <MarginBox mt={30} />
      {promotion.containsCustomImage && (
        <Flex direction={'column'}>
          <SCard>
            <DataContainer
              data={imageBase64}
              Loading={() => (
                <Box height={250}>
                  <CenteredSpin />
                </Box>
              )}
              Error={() => (
                <Box height={250}>
                  <ErrorWithLabel />
                </Box>
              )}
            >
              <Banner image={imageBase64} base64 onlyImage />
            </DataContainer>
          </SCard>
          <MarginBox mt={30} />
        </Flex>
      )}
      <PrivateComponent
        render={() => (
          <>
            {promotion.promotionType === 'DISCOUNT' && hasAdditionalDiscount && (
              <Flex direction={'column'}>
                <Flex direction={'row'}>
                  <Flex justify={'flex-start'}>
                    {showAdditionalSection && (
                      <ArrowLeftButtonRound onClick={() => setShowAdditionalSection(false)} color={'brand_black'} />
                    )}
                  </Flex>
                  <Flex justify={'flex-end'}>
                    {!showAdditionalSection && (
                      <ArrowRightButtonRound
                        onClick={() => {
                          setShowAdditionalSection(true);
                          if (!showStepButtons) {
                            setShowStepButtons(true);
                          }
                        }}
                        color={'brand_black'}
                        bgColor={'brand'}
                        hoverFill={'brand_dark_80'}
                      />
                    )}
                  </Flex>
                </Flex>
                <MarginBox mt={30} />
              </Flex>
            )}
          </>
        )}
        requiredRights={[UserRole.COUNTRY_ADMIN]}
      />
      {showStepButtons && (
        <Flex direction={'row'}>
          <Flex size={1}>
            <StepButtons
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalItems={totalSteps}
              stretch
              previousEnabled={!hasData(publishedStatus)}
            />
          </Flex>
          <Flex size={1}>{rightButton()}</Flex>
        </Flex>
      )}
    </>
  );
};
