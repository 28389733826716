import { Table } from 'antd';
import styled, { css } from 'styled-components';
import { CleverInput, InputNumber, InputNumberProps, InputPrice, InputPriceProps, Select } from 'UI';

export const STable = styled(Table)`
  thead > tr > th {
    text-align: center;
    white-space: nowrap;
  }

  tbody > tr.ant-table-row {
    :hover {
      box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
    }
  }
` as typeof Table;

const checkEmptyInput = css<{ $isEmpty?: boolean }>`
  border: ${({ theme, $isEmpty }) => ($isEmpty ? `1px solid ${theme.color.red}` : '')};
  background-color: ${({ theme, $isEmpty }) => ($isEmpty ? theme.color.very_light_pink : '')};
`;
const commonSInput = css<{ $isEmpty?: boolean }>`
  text-align: center;

  .ant-input,
  .ant-input-group-addon {
    border: ${({ theme, $isEmpty }) => ($isEmpty ? `1px solid ${theme.color.red}` : '')};
    background-color: ${({ theme, $isEmpty }) => ($isEmpty ? theme.color.very_light_pink : '')};
  }
`;

export const SInputNumber = styled(InputNumber)<InputNumberProps & { $isEmpty?: boolean }>`
  ${commonSInput}
`;

export const SInputPrice = styled(InputPrice)<InputPriceProps & { $isEmpty?: boolean }>`
  ${commonSInput}
`;

export const SInput = styled(CleverInput)<{ checkEmpty: boolean; $isEmpty?: boolean }>`
  ${({ readOnly }) =>
    !readOnly &&
    css`
      :focus {
        border: 1px solid ${({ theme }) => theme.color.brand_light};
        box-shadow: 0 0 0 2px ${({ theme }) => theme.color.brand_background};
        background-color: white;
      }
    `};
  ${({ checkEmpty }) => checkEmpty && checkEmptyInput};
`;

export const TooltipContent = styled.div`
  display: flex;
  flex: 1;
`;

export const SSelect = styled(Select)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;

export const ExpandedLaborTimeWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 15px 60px;
`;

export const ExpandedReferenceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 25px 15px;
`;

export const ExpandedLaborTimeDotCircleWrapper = styled.div`
  position: relative;
  left: -4px;
  z-index: 1;
`;

export const ExpandedReferenceDotCircleWrapper = styled.div`
  position: relative;
  left: -4.5px;
  top: -13px;
  z-index: 1;
`;

export const LinkedReferencesBackgroundWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  left: 45px;
  z-index: 1;
  padding-bottom: 40px;
`;
