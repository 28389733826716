import React from 'react';
import { Trans } from 'react-i18next';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { Document, Page, Text as PdfText, View } from '@react-pdf/renderer';
import { getDiscountColor } from 'components/Discount';
import { PdfOrderStockDisplay } from 'components/Pdf/PdfStockDisplay';
import { PictureCheckCircle, PictureReturn } from 'components/Pdf/PdfSvg';
import {
  BorderlessCard,
  Divider,
  HeadText,
  ItemNameTitle,
  Layout3Columns,
  LeadText,
  PageNumber,
  RowItem,
  Section,
  Spacer,
  Subtitle,
  TextDark,
  TextMid,
  TextMidBold,
  VerticalSpacer,
} from 'components/Pdf/PdfUI';
import { components, fonts, styles } from 'components/Pdf/PdfUI.styles';
import { toDatePdf } from 'domains/order/OrderDate';
import { getDeliveryTypeLabel } from 'domains/order/OrderDelivery';
import { OrderReferenceStatusPdf } from 'domains/order/OrderReferenceStatus';
import { getStatusLabel } from 'domains/order/OrderStatus';
import { theme } from 'styles';
import { isQueryBMM } from 'utils';
import { textFormatter } from 'utils/format';
import { BillingAddressCard } from './BillingAddressCard';
import { ContactCard } from './ContactCard';
import { Header } from './Header';
import { InformationCard } from './InformationCard';
import { OrderPdfData } from './MyOrdersDetailPdfPage';

export const TotalSection = ({ data }: { data: OrderPdfData }) => {
  return (
    <BorderlessCard title={<ItemNameTitle>{data.text.total}</ItemNameTitle>} height={'md'} width={136} alignTitleStart>
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 10, marginBottom: 10 }]}>
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextMid>{data.text.totalVatExcl}</TextMid>
            </View>
          </View>
          <View style={[styles.col]}>
            <TextDark>
              {textFormatter.formatCurrency(Number(data.order.priceVatExcluded), data.order.currency)}
            </TextDark>
          </View>
        </View>
        <Spacer />
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextMid>{data.text.totalVat}</TextMid>
            </View>
          </View>
          <View style={[styles.col]}>
            <TextDark>{textFormatter.formatCurrency(Number(data.order.priceVat), data.order.currency)}</TextDark>
          </View>
        </View>
        <Spacer />
        <Divider />
        <Spacer />
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextMidBold>{data.text.totalVatIncl}</TextMidBold>
            </View>
          </View>
          <View style={[styles.col]}>
            <Section>{textFormatter.formatCurrency(Number(data.order.priceVatIncluded), data.order.currency)}</Section>
          </View>
        </View>
      </View>
    </BorderlessCard>
  );
};

export const InstructionsCard = ({ data }: { data: OrderPdfData }) => {
  return (
    <View
      style={{
        backgroundColor: '#f2f2f2',
        color: 'black',
        padding: '6px 6px 14px 6px',
        width: '322px',
        paddingTop: '10px',
      }}
    >
      <ItemNameTitle>{data.text.instructions}</ItemNameTitle>
      <View style={{ padding: '10px 0 0 0' }}>
        <PdfText style={[fonts.input_text]}>{data.order.instruction}</PdfText>
      </View>
    </View>
  );
};

const getInfoText = (label: React.ReactNode, data: React.ReactNode, vehicle?: boolean) => {
  return (
    <View style={[styles.row, { alignItems: 'center', height: '15px' }]}>
      <TextMidBold>
        {label}
        {!vehicle && ':'}
      </TextMidBold>
      {!vehicle && <VerticalSpacer width={5} />}
      <TextMid>{data}</TextMid>
    </View>
  );
};

const getCarIdentification = (vehicleDetail: VehicleDetail) => {
  const isBMM = isQueryBMM(vehicleDetail.vehicleKey);
  return isBMM ? (
    <></>
  ) : (
    getInfoText(
      <>
        {vehicleDetail.vehicleIdentifiedBy && (
          <>
            {vehicleDetail.vehicleIdentifiedBy === vehicleDetail.vin ? (
              <Trans i18nKey={'catalog.vehicle.vin'}>VIN</Trans>
            ) : (
              <Trans i18nKey={'catalog.vehicle.vrn'}>VRN</Trans>
            )}
            {`: ${vehicleDetail.vehicleIdentifiedBy}`}
          </>
        )}
      </>,
      <></>,
      true,
    )
  );
};
const getInfoVehicle = (vehicleDetail: VehicleDetail | undefined, data: OrderPdfData) => {
  const dataHubVehicle = vehicleDetail?.dataHubVehicle;
  const iamVehicle = vehicleDetail?.iamVehicle;
  return (
    <View style={[styles.column, { alignItems: 'flex-end', gap: '-3px' }]}>
      {vehicleDetail && (
        <>
          {getCarIdentification(vehicleDetail)}
          {dataHubVehicle && !iamVehicle && (
            <>
              {getInfoText(<></>, dataHubVehicle.name, true)}
              {getInfoText(<></>, dataHubVehicle.modelType, true)}
              {getInfoText(<></>, dataHubVehicle.engine, true)}
              {getInfoText(<></>, dataHubVehicle.gearbox, true)}
            </>
          )}
          {iamVehicle && !dataHubVehicle && (
            <>
              {iamVehicle.name && getInfoText(iamVehicle.name, <></>, true)}
              {iamVehicle.modelType && getInfoText(<></>, iamVehicle.modelType, true)}
              {iamVehicle.version && getInfoText(<></>, iamVehicle.version, true)}
              {(iamVehicle.gearbox || iamVehicle.gearboxType) &&
                getInfoText(
                  <></>,
                  `${iamVehicle.gearboxType || ''}${iamVehicle.gearbox && iamVehicle.gearboxType ? ' ' : ''}${
                    iamVehicle.gearbox || ''
                  }`,
                  true,
                )}
            </>
          )}
        </>
      )}
      {!dataHubVehicle && !iamVehicle && (
        <>
          {getInfoText(data.text.referenceList, <></>, true)}
          {getInfoText(<></>, data.text.noVehicleContext, true)}
        </>
      )}
    </View>
  );
};

const InformationHeader = ({ data }: { data: OrderPdfData }) => {
  return (
    <View
      style={[styles.row, { width: '100%', backgroundColor: theme.color.grey95_light, padding: '3px 10px 3px 10px' }]}
    >
      <View style={[styles.column, { gap: '-3px' }]}>
        {getInfoText(data.text.client, data.order.userInfo.garageInfo?.name ?? '')}
        {getInfoText(data.text.date, toDatePdf(data.order.orderDate))}
        {data.order.orderMark && getInfoText(data.text.orderLine, data.order.orderMark)}
        {getInfoText(data.text.deliveryMode, getDeliveryTypeLabel(data.order.deliveryType))}
      </View>
      <View style={[styles.row_reverse]}>{getInfoVehicle(data.order.vehicleDetail, data)}</View>
    </View>
  );
};

const ReferenceCard = ({ reference, data }: { reference: OrderReferenceItem; data: OrderPdfData }) => {
  const stockStatuses = reference.warehouseDeliveryStatuses?.length ?? 0;
  const showStocksMultiline = stockStatuses > 1;
  const referenceCardHeight = 30 + stockStatuses * 5;
  const { price } = reference;
  const discountColor = getDiscountColor({
    referenceNumber: '',
    discount: price.discount,
    promotionType: price.promotionType,
    discountType: price.discountType,
  } as ReferenceDiscount);

  const renderStocks = () => (
    <PdfOrderStockDisplay
      orderId={data.order.internalOrderId}
      referenceNumber={reference.referenceNumber}
      warehouseDeliveryStatuses={reference.warehouseDeliveryStatuses}
      narrow={false}
      quantity={Number(reference.quantity)}
      dealerType={data.dealerType}
      r1Country={data.r1Country}
    />
  );

  return (
    <View>
      <View
        style={[
          styles.row,
          {
            width: '545px',
            height: `${referenceCardHeight}px`,
            marginLeft: '5px',
          },
        ]}
      >
        <View
          style={[
            styles.col,
            {
              width: '250px',
              margin: '0 5px 0 5px',
              gap: '1px',
            },
          ]}
        >
          <TextMidBold>
            {data.text.ref}
            {` ${reference.referenceNumber}`}
          </TextMidBold>
          <TextMid>{reference.name}</TextMid>
          <View>
            <View style={styles.row}>
              <TextMidBold>{data.text.stocks}</TextMidBold>
              {!showStocksMultiline && renderStocks()}
            </View>
            {showStocksMultiline && <View style={{ marginLeft: '5px', paddingRight: '5px' }}>{renderStocks()}</View>}
          </View>
          <View style={[styles.row]}>
            <TextMidBold>{data.text.qty}</TextMidBold>
            <TextMid>
              {`${reference.quantity} `}
              {reference.deliveredQuantity !== undefined &&
                reference.deliveredQuantity !== 0 &&
                ` (${reference.deliveredQuantity} `}
            </TextMid>
            {reference.deliveredQuantity && <TextMid>{data.text.shipped})</TextMid>}
            {reference.returnedQuantity !== undefined && reference.returnedQuantity !== 0 && (
              <>
                <View style={{ marginLeft: '3px', paddingRight: '3px' }}>
                  <PictureReturn />
                </View>
                <TextMid>{data.text.returnedArticles(reference.returnedQuantity)}</TextMid>
              </>
            )}
          </View>
        </View>
        <View
          style={[
            styles.col,
            {
              width: '130px',
              margin: '0 5px 0 5px',
            },
          ]}
        >
          {reference.referenceMark && (
            <TextMid>
              <TextMidBold>{data.text.referenceMark}</TextMidBold>
              {reference.referenceMark}
            </TextMid>
          )}
        </View>
        <View style={styles.col}>
          <View style={[styles.row_reverse, { width: '100px' }]}>
            <View style={styles.col}>
              <PdfText style={[fonts.itemNameTitle, { textDecoration: price?.discount ? 'line-through' : 'none' }]}>
                {data.text.vatExcl(
                  textFormatter.formatCurrency(Number(price.originalUnitPriceVatExcluded), price.currency),
                )}
              </PdfText>
              {price?.discount && (
                <View>
                  <View style={[styles.row_reverse, { height: '12px', marginTop: '5px' }]}>
                    <PdfText style={[fonts.textMid, { color: discountColor }]}>
                      {data.text.vatExcl(
                        textFormatter.formatCurrency(Number(price.unitPriceVatExcluded), price.currency),
                      )}
                    </PdfText>
                  </View>
                  <View style={[styles.row_reverse]}>
                    <PdfText style={[fonts.text_bold, { color: discountColor, marginTop: '2px' }]}>
                      {data.text.discount(price.discount.toString())}
                    </PdfText>
                  </View>
                </View>
              )}
              <View style={[styles.row_reverse, { height: '12px', marginTop: '7px' }]}>
                <OrderReferenceStatusPdf referenceStatus={reference.status} orderStatus={data.order.orderStatus} />
              </View>
            </View>
          </View>
          <View
            style={[
              styles.col_reverse,
              {
                alignItems: 'flex-end',
                height: '100%',
              },
            ]}
          >
            {reference.isUrgent && (
              <RowItem>
                <View style={[styles.row]}>
                  <PictureCheckCircle />
                  <View style={{ marginLeft: 2 }} />
                  <PdfText style={[fonts.blue]}>{data.text.urgent}</PdfText>
                </View>
              </RowItem>
            )}
          </View>
        </View>
      </View>
      <View
        style={[{ height: '5px', width: '550px', marginBottom: '5px', marginLeft: '5px' }, components.dividerBottom]}
      />
    </View>
  );
};

const ReferencesList = ({ data }: { data: OrderPdfData }) => {
  return (
    <View>
      {data.order.orderedReferences.map((r) => {
        return <ReferenceCard key={r.referenceNumber} reference={r} data={data} />;
      })}
    </View>
  );
};

export const MyOrdersDetailPdfPage = ({ data }: { data: OrderPdfData }) => (
  <Document>
    <Page size={'A4'} style={styles.page} wrap>
      <PageNumber />
      <View fixed>
        <HeadText>{data.text.orderNumber}</HeadText>
        <View style={{ textAlign: 'right' }}>
          <LeadText>
            {data.text.status}
            {`: `}
            {getStatusLabel(data.order.presentableOrderStatus)}
          </LeadText>
        </View>
        <Spacer />
      </View>
      <View style={{ marginTop: '-45px' }}>
        <Header data={data} />
      </View>
      <Spacer />
      <InformationHeader data={data} />
      <Spacer />
      <Subtitle>{data.text.references}</Subtitle>
      <Spacer />
      <ReferencesList data={data} />
      <Layout3Columns
        left={
          <InformationCard
            text={data.text}
            orderedBy={`${data.order.userInfo.firstName} ${data.order.userInfo.lastName}`}
            clientNumber={data.order.userInfo.buyerId}
            distributor={data.order.userInfo.sellerName}
            billingAccount={data.order.userInfo.clientCode}
          />
        }
        middle={<ContactCard text={data.text} garageInfo={data.order.userInfo.garageInfo} />}
        right={<BillingAddressCard text={data.text} garageInfo={data.order.userInfo.garageInfo} />}
      />
      <View style={[styles.row, { marginTop: '5px' }]} wrap={false}>
        {data.order.instruction ? (
          <>
            <Spacer />
            <InstructionsCard data={data} />
          </>
        ) : (
          <View style={{ width: '322px' }} />
        )}
        <View style={[styles.row_reverse, { marginLeft: '100px' }]}>
          <TotalSection data={data} />
        </View>
      </View>
    </Page>
  </Document>
);
