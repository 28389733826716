import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OtherItemField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateOtherItem';
import { OtherItem } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { RootState } from 'app/AppStore';
import { getOtherItems, removeOtherItem, updateOtherItem } from 'domains/estimate/Estimate.store';
import { ClientView, getCurrency } from 'domains/user';
import {
  EstimatePriceInput,
  GetTitle,
  QuantityInput,
  TextInput,
  VatInput,
} from 'pages/EstimatePage/TableSection/tableComponents';
import { TableProps } from 'pages/EstimatePage/TableSection/TableSection';
import { Flex, Text } from 'UI';
import { textFormatter } from 'utils';
import { STable } from './TableSection.styled';

export default function OtherTable({ estimateId, sparePartsView }: Readonly<TableProps>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const data = useSelector((state: RootState) => getOtherItems(state, estimateId));
  const isClientView = sparePartsView === ClientView;

  function removeRow(rowId: string) {
    dispatch(removeOtherItem(rowId));
  }

  const handleUpdate = (itemId: string, field: OtherItemField) => (newValue: string) => {
    dispatch(updateOtherItem({ itemId, field, newValue }));
  };

  const tableColumns = [
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      render: function rowSelector(designation: string, row: OtherItem) {
        return (
          <TextInput
            value={designation}
            saveFunction={handleUpdate(row.itemId, 'NAME')}
            removeRow={() => removeRow(row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.unit_price', 'Unit Price')),
      dataIndex: 'unitPrice',
      render: function rowSelector(unitPrice: string, row: OtherItem) {
        return <EstimatePriceInput value={unitPrice} saveFunction={handleUpdate(row.itemId, 'UNIT_PRICE')} />;
      },
    },
    {
      title: GetTitle(t('common.quantity', 'Quantity')),
      dataIndex: 'quantity',
      render: function rowSelector(quantity: string, row: OtherItem) {
        return (
          <QuantityInput
            value={Number(quantity)}
            saveFunction={handleUpdate(row.itemId, 'QUANTITY')}
            removeRow={() => removeRow(row.itemId)}
            precisionMin={3}
            precisionMax={3}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      render: function rowSelector(vatExclPrice: string) {
        return (
          <Flex justify={'center'} align={'center'}>
            {textFormatter.formatCurrency(isNaN(Number(vatExclPrice)) ? 0 : vatExclPrice, currency)}
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat.title', 'VAT')),
      dataIndex: 'vatPercentage',
      render: function rowSelector(vat: number, row: OtherItem) {
        return <VatInput value={vat} saveFunction={handleUpdate(row.itemId, 'VAT_PERCENTAGE')} />;
      },
    },
  ];

  if (!isClientView) {
    tableColumns.push(
      {
        title: GetTitle(t('common.price.garage_price', 'Garage Price')),
        dataIndex: 'garagePrice',
        render: function rowSelector(garagePrice: string, row: OtherItem) {
          garagePrice = garagePrice ?? '0';
          return (
            <EstimatePriceInput
              value={garagePrice}
              saveFunction={handleUpdate(row.itemId, 'GARAGE_PRICE')}
              minWidth={125}
            />
          );
        },
      },
      {
        title: GetTitle(t('common.price.margin', 'Margin')),
        dataIndex: 'margin',
        render: function rowSelector(margin: string, row: OtherItem) {
          const marginPercentage = parseFloat(row.marginPercentage || '0');
          const formattedMarginPercentage = `${marginPercentage}%`;
          return (
            <Flex direction={'column'} justify={'center'} align={'center'}>
              <Text type={'light_14'}>
                {textFormatter.formatCurrency(isNaN(Number(margin)) ? 0 : margin, currency)}
              </Text>
              <Text type={'light_14'}>{formattedMarginPercentage}</Text>
            </Flex>
          );
        },
      },
    );
  }

  if (data.length === 0) {
    return null;
  }
  return (
    <STable<OtherItem>
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      rowKey={(row) => row.itemId}
      size={'large'}
    />
  );
}
