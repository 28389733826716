/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { useFetchLaborTimeIdsByReferenceGenericPart } from 'domains/laborTime/LaborTime.requests';
import { getLaborTimeIdsForGenericPart } from 'domains/laborTime/LaborTime.store';
import { DHReferenceLocal, setSelectedTab } from 'domains/references';
import { SparePartsViewType } from 'domains/user';
import { ProductDescription } from 'pages/CatalogPage/DH/Product/ProductDescription';
import { ProductMenu } from 'pages/CatalogPage/DH/Product/ProductMenu/ProductMenu';
import CrossSellingSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard/CrossSellingSection/CrossSellingSection';
import { Box, CenterFlex, Flex, ImageWithLoader, MarginBox, Text } from 'UI';
import { BorderedBox } from './Product.styled';
import { ProductDetails } from './ProductDetails';
import ProductImageSection from './ProductImageSection';
import ReferenceCardLaborTimesWrapper from '../SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardLaborTimesWrapper';

export interface ProductDetailProps {
  sparePartsView: SparePartsViewType;
  refLocal: DHReferenceLocal;
  query: string | undefined;
  isPopin?: boolean;
  seePlate?: React.ReactNode;
  scrollToSubstitutes?: boolean;
  isVehicleCatalog?: boolean;
  isUniversalProduct?: boolean;
}

export const ProductTab = ({
  sparePartsView,
  refLocal,
  query,
  isPopin,
  seePlate,
  isVehicleCatalog,
  scrollToSubstitutes = false,
  isUniversalProduct,
}: ProductDetailProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const productMenuRef = useRef<HTMLDivElement>(null);
  const displayDetailSection =
    (refLocal.additionalInformation && refLocal.additionalInformation?.length > 0) ||
    refLocal.characteristics?.unitsOfSales ||
    (refLocal.characteristics?.technicalCharacteristics &&
      refLocal.characteristics?.technicalCharacteristics?.length > 0) ||
    (refLocal.descriptions?.familyDetails && refLocal.descriptions?.familyDetails?.length > 0) ||
    (refLocal.descriptions?.modelDetails && refLocal.descriptions?.modelDetails?.length > 0) ||
    (refLocal.descriptions?.partDetails && refLocal.descriptions?.partDetails?.length > 0) ||
    refLocal.includeFDSDocument;
  const eanCode = refLocal.tireDetails?.eanCode;
  const eprelUrl = refLocal.tireDetails?.eprelUrl;

  useEffect(() => {
    if (scrollToSubstitutes) {
      dispatch(setSelectedTab('substitute_references'));
      setTimeout(() => {
        productMenuRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useFetchLaborTimeIdsByReferenceGenericPart(refLocal.referenceNumber, vehicleKey);

  const laborTimeIdsWrapper = useSelector((state: RootState) =>
    getLaborTimeIdsForGenericPart(state, { vehicleKey, genericPart: refLocal.genericPart }),
  );

  return (
    <>
      <CenterFlex>
        <Flex direction={'column'} maxWidth={'100%'}>
          <Flex wrap={'wrap'}>
            <Flex direction={'column'}>
              <ProductImageSection refLocal={refLocal} sparePartsView={sparePartsView} />
              {!refLocal.isApplicableToCurrentVehicle && (
                <>
                  <MarginBox mt={15} />
                  <ErrorWithLabel
                    label={t('catalog.reference.incompatible_vehicle', 'Part non-compatible with selected vehicle.')}
                    narrow
                  />
                </>
              )}
              {eanCode && (
                <Flex justify={'flex-end'}>
                  <MarginBox my={15}>
                    <Text type={'link_12'}>
                      {t('catalog.reference.ean_code', 'EAN CODE: {{eanCode}}', { eanCode })}
                    </Text>
                  </MarginBox>
                </Flex>
              )}
            </Flex>
            <Flex size={0} minWidth={60} />
            <Flex>
              <ProductDescription
                refLocal={refLocal}
                sparePartsView={sparePartsView}
                query={query}
                seePlate={seePlate}
                isVehicleCatalog={isVehicleCatalog}
                isUniversalProduct={isUniversalProduct}
              />
            </Flex>
          </Flex>
          {eprelUrl && (
            <MarginBox my={15} mx={15}>
              <ImageWithLoader
                alt={'energy-label-image'}
                imageUrl={eprelUrl}
                fallbackComponent={
                  <ErrorWithLabel
                    label={t('catalog.vehicle.tires.eprel_error', 'Eprel image temporarily unavailable.')}
                  />
                }
                imageHeight={200}
                imageWidth={130}
              />
            </MarginBox>
          )}
          <Box height={30} />
          <DataContainer
            data={refLocal.detailStatus}
            Error={() => (
              <ErrorWithLabel
                label={t(
                  'common.view.product_details.backend_error',
                  'Product details are temporarily unavailable, please try again later.',
                )}
              />
            )}
          >
            <Flex direction={isPopin ? 'column' : ['column', 'row']} justify={'center'}>
              <Flex>
                {displayDetailSection && (
                  <BorderedBox>
                    <MarginBox mx={30} my={30}>
                      <ProductDetails reference={refLocal} />
                    </MarginBox>
                  </BorderedBox>
                )}
              </Flex>
              {isPopin && <MarginBox mt={30} />}
              <Flex size={0} minWidth={60} />
              <Flex ref={productMenuRef}>
                <ProductMenu refLocal={refLocal} sparePartsView={sparePartsView} />
              </Flex>
            </Flex>
          </DataContainer>
        </Flex>
      </CenterFlex>
      <MarginBox mt={30} />
      {laborTimeIdsWrapper?.data && (
        <ReferenceCardLaborTimesWrapper
          referenceNumber={refLocal.referenceNumber}
          laborTimeIds={laborTimeIdsWrapper?.data}
          isProduct
        />
      )}
      <CrossSellingSection
        mainRef={refLocal.referenceNumber}
        crossSellingReferences={refLocal.crossSelling}
        type={'product'}
      />
    </>
  );
};
