/* eslint-disable max-len */
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  EstimateSettingsFilter,
  LaborType,
  SettingsField,
} from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateSettings';
import { Settings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { MotrioLogoIcon } from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { getEstimateLogo, getEstimateSettings, setEstimateLogo, updateSettings } from 'domains/estimate/Estimate.store';
import { FocusSetting } from 'domains/estimate/Estimate.types';
import { getUserGarageInfo } from 'domains/garage/Garage.store';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { getCurrency, getUserDisplayMode } from 'domains/user';
import { getSettingsLaborTimeRate } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';
import { ChangeEmail } from 'pages/EstimatePage/SettingTab/ChangeEmail';
import { BrandCheckBox, SEmail } from 'pages/EstimatePage/SettingTab/SettingsTab.styled';
import {
  Box,
  CleverInput,
  Flex,
  Icon,
  InputNumber,
  InputPrice,
  MarginBox,
  Select,
  Spin,
  Text,
  TextArea,
  WideTabs,
  Space,
} from 'UI';
import { Switch } from 'UI/Switch';
import { getData, NO_DATA, NOT_FOUND } from 'utils';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import WasteRecyclingSettings from './WasteRecyclingSettings';

interface SettingsSectionItem {
  title: string | ReactNode;
  component: ReactNode;
}

interface SettingsSectionData {
  title: ReactNode;
  items?: SettingsSectionItem[];
  description?: ReactNode;
  data: Garage | Settings | NO_DATA;
}

export const SETTINGS_RATES = 'labor_time_rates';

const SettingsSection = ({
  data: { items, title, description, data },
  maxW = 150,
  id,
}: {
  data: SettingsSectionData;
  maxW?: number;
  id?: string;
}) => (
  <>
    {title && (
      <>
        <MarginBox mt={30} />
        <Text type={'h2'} id={id}>
          {title}
        </Text>
      </>
    )}
    <MarginBox mt={30} />
    {description && (
      <>
        {description}
        <MarginBox mt={30} />
      </>
    )}
    <DataContainer
      data={data}
      Error={() => (
        <Box height={200}>
          <ErrorWithLabel />
        </Box>
      )}
    >
      {items &&
        items.map(({ title, component }, key) => (
          <React.Fragment key={`item-${key}`}>
            <Flex align={'center'}>
              {title && (
                <Flex maxWidth={maxW}>
                  {typeof title === 'string' ? <Text type={'text_dim_bold'}>{title}</Text> : title}
                </Flex>
              )}
              <Box>{component}</Box>
            </Flex>
            <MarginBox mt={15} />
          </React.Fragment>
        ))}
    </DataContainer>
    <MarginBox mt={45} />
  </>
);

const brandLogos = [
  {
    id: 'motrio',
    icon: MotrioLogoIcon,
  },
];

const initialSettings: Settings = {
  apeCode: '',
  intraCommunityVat: '',
  vatRate: undefined,
  estimateValidFor: undefined,
  validityUnit: 'MONTH',
  observations: '',
  legalMentions: '',
  autoAddWasteRecycling: 'false',
};

export const SettingsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const garageInfo = useSelector(getUserGarageInfo);
  const garageData = getData(garageInfo);
  const address = garageData ? getFirstValidAddress(garageData) : undefined;
  const settings = useSelector(getEstimateSettings);
  const estimateLogo = useSelector(getEstimateLogo);
  const isR3Motrio = useSelector(getUserDisplayMode) === 'R3_MOTRIO';
  const offsetTop = useOffsetTop('ESTIMATE');
  const currency = useSelector(getCurrency);
  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [settingsState, setSettingsState] = useState<Settings | undefined>(getData(settings));
  const focusSetting = params.get(FocusSetting);
  if (focusSetting) {
    setTimeout(() => {
      const focusElement = document.getElementById(focusSetting);
      if (focusElement) {
        scrollToTopSmooth(focusElement.offsetTop - offsetTop);
      }
    }, 100);
  }
  const handleUpdate = (field: SettingsField, filters?: EstimateSettingsFilter) => (
    newValue: string | number | undefined,
  ) => {
    dispatch(updateSettings({ field, newValue: newValue === null ? '' : String(newValue), filters }));
  };

  const handleUpdateNumber = (field: SettingsField) => (newValue: string | number | undefined) => {
    dispatch(updateSettings({ field, newValue: newValue ? String(newValue) : String(0) }));
  };

  const isAnyWasteRecyclingActive = settingsState?.wasteRecycling?.some((wr) => wr.priceVatExcluded);

  useEffect(() => {
    if (settings === NOT_FOUND) {
      setSettingsState(initialSettings);
    }
    if (getData(settings)) {
      setSettingsState(getData(settings));
    }
  }, [settings]);

  const companyContactDetails: SettingsSectionData = {
    title: t('estimate.settings.company_contact_details', 'Company contact details'),
    items: [
      {
        title: t('estimate.settings.social_reason', 'Social reason:'),
        component: <Text type={'text_dim'}>{garageData?.name ?? ''}</Text>,
      },
      {
        title: t('estimate.settings.address', 'Address:'),
        component: <Text type={'text_dim'}>{address?.address1 ?? ''}</Text>,
      },
      {
        title: t('estimate.settings.postal_code', 'Postal code:'),
        component: <Text type={'text_dim'}>{address?.zipCode ?? ''}</Text>,
      },
      {
        title: t('estimate.settings.city', 'City:'),
        component: <Text type={'text_dim'}>{address?.city ?? ''}</Text>,
      },
      {
        title: t('estimate.settings.phone', 'Phone:'),
        component: <Text type={'text_dim'}>{address?.phone ?? ''}</Text>,
      },
      {
        title: t('estimate.fax', 'Fax:'),
        component: <Text type={'text_dim'}>{address?.fax ?? ''}</Text>,
      },
      {
        title: <SEmail type={'text_dim_bold'}>{t('estimate.settings.email', 'Email:')}</SEmail>,
        component: <ChangeEmail email={address?.email} />,
      },
    ],
    data: garageInfo,
  };

  const administrativeInformation: SettingsSectionData = {
    title: t('estimate.settings.administrative_information', 'Administrative information'),
    items: [
      {
        title: t('estimate.settings.company_registration_number', 'Company registration number:'),
        component: <Text type={'text_dim'}>XXXXXXXXXX-XXX</Text>,
      },

      {
        title: t('estimate.settings.ape_code', 'APE Code:'),
        component: (
          <CleverInput
            size={'middle'}
            onChangeDebounced={handleUpdate('APE_CODE')}
            initialValue={settingsState?.apeCode ?? ''}
            bordered
          />
        ),
      },
      {
        title: t('estimate.settings.intra_community_vat', 'Intra-community TVA number:'),
        component: (
          <CleverInput
            size={'middle'}
            onChangeDebounced={handleUpdate('INTRA_COMMUNITY_VAT')}
            initialValue={settingsState?.intraCommunityVat ?? ''}
            bordered
          />
        ),
      },
    ],
    data: settingsState,
  };

  const VATRate: SettingsSectionData = {
    title: t('estimate.settings.vat_rate_title', 'VAT rate'),
    description: (
      <Text type={'text_dim'}>
        {t('estimate.settings.for_estimates_only', 'For Estimates (only for Labor Time and Waste Recycling)')}
      </Text>
    ),
    items: [
      {
        title: t('estimate.settings.vat_rate', 'VAT rate:'),
        component: (
          <InputNumber
            value={settingsState?.vatRate ?? undefined}
            onChangeDebounced={handleUpdate('VAT_RATE')}
            status={settingsState?.vatRate === 0 ? 'error' : undefined}
            precisionMax={2}
            addonAfter={'%'}
            min={0}
            max={100}
            bordered
          />
        ),
      },
    ],
    data: settingsState,
  };

  const wasteRecycling: SettingsSectionData = {
    title: (
      <Flex>
        <Flex>{t('catalog.waste_recycling', 'Waste recycling')}</Flex>
        <Flex justify={'flex-end'} align={'center'}>
          <Text type={'text_dim'}>{t('commono.action.add_automatically', 'Add automatically')}</Text>
          <MarginBox ml={5} />
          <Switch
            size={'small'}
            checked={settingsState?.autoAddWasteRecycling === 'true'}
            onChange={(v) => {
              handleUpdate('AUTO_ADD_WASTE_RECYCLING')(v ? 'true' : 'false');
            }}
            disabled={!isAnyWasteRecyclingActive}
          />
        </Flex>
      </Flex>
    ),
    data: settingsState,
    items: [
      {
        title: null,
        component: <WasteRecyclingSettings data={settingsState?.wasteRecycling ?? []} settingsState={settingsState} />,
      },
    ],
  };

  const commercialEstimateValidity: SettingsSectionData = {
    title: t('estimate.settings.commercial_estimate_validity', 'Commercial estimate validity'),
    items: [
      {
        title: t('estimate.settings.estimates_valid', 'The estimates generated will be valid:'),
        component: (
          <Flex align={'center'}>
            <Box width={50}>
              <InputNumber
                onChangeDebounced={handleUpdateNumber('ESTIMATE_VALIDITY')}
                value={settingsState?.estimateValidFor ?? undefined}
                min={0}
                max={settingsState?.validityUnit === 'WEEK' ? 12 : 3}
                status={settingsState?.estimateValidFor === 0 ? 'error' : undefined}
                bordered
              />
            </Box>
            <Box width={30} />
            <Box width={130}>
              <Select
                onChange={handleUpdate('ESTIMATE_VALIDITY_UNIT')}
                placeholder={'Month'}
                options={[
                  {
                    title: t('estimate.settings.week', 'Week', { count: settingsState?.estimateValidFor }),
                    value: 'WEEK',
                  },
                  {
                    title: t('estimate.settings.month', 'Month', { count: settingsState?.estimateValidFor }),
                    value: 'MONTH',
                  },
                ]}
                value={settingsState?.validityUnit}
                bordered
                styleLess
              />
            </Box>
          </Flex>
        ),
      },
    ],
    data: settingsState,
  };
  const observations: SettingsSectionData = {
    title: t('estimate.settings.observations', 'Observations'),
    description: (
      <TextArea
        onChangeDebounced={handleUpdate('OBSERVATIONS')}
        placeholder={t('estimate.settings.add_observation_here', 'Add your observations here.')}
        size={'large'}
        initialValue={settingsState?.observations ?? ''}
        maxLength={180}
      />
    ),
    data: settingsState,
  };
  const legalMentions: SettingsSectionData = {
    title: t('estimate.settings.legal_mentions', 'Legal mentions'),
    description: (
      <TextArea
        onChangeDebounced={handleUpdate('LEGAL_MENTIONS')}
        placeholder={t('estimate.settings.add_legal_mention_here', 'Add legal mentions here.')}
        size={'large'}
        initialValue={settingsState?.legalMentions ?? ''}
      />
    ),
    data: settingsState,
  };

  const pdfLogo: SettingsSectionData = {
    title: t('estimate.settings.pdf_logo.title', 'Logo to display on your Estimates'),
    description: (
      <Flex direction={'column'}>
        <Text type={'text_dim'}>
          {t(
            'estimate.settings.pdf_logo.description',
            'Please select the brand logo that should be displayed on your estimates.',
          )}
        </Text>
        <MarginBox mt={15} />
        <Space size={30}>
          {brandLogos.map((brand) => {
            return (
              <BrandCheckBox
                key={brand.id}
                onClick={() => dispatch(setEstimateLogo(brand.id))}
                $selected={brand.id === estimateLogo}
              >
                <Icon IconComponent={brand.icon} width={90} height={63} />
              </BrandCheckBox>
            );
          })}
        </Space>
      </Flex>
    ),
    data: undefined,
  };

  const getLaborTimeRates = (laborType: LaborType): SettingsSectionData => {
    return {
      title: null,
      items: [
        {
          title: t('estimate.settings.labor_time_excl_vat_rate.low_technicity', 'Low technicity:'),
          component: (
            <InputPrice
              value={getSettingsLaborTimeRate(laborType, 'NORMAL', settingsState?.laborPriceList)?.price}
              onChangeDebounced={handleUpdate('LABOR_PRICE', {
                laborType,
                laborLevel: 'NORMAL',
              })}
              placeholder={t(
                'estimate.settings.labor_time_excl_vat_rate.low_technicity.placeholder',
                'T1 technicity rate',
              )}
              currency={currency}
            />
          ),
        },
        {
          title: t('estimate.settings.labor_time_excl_vat_rate.high_technicity', 'High technicity:'),
          component: (
            <InputPrice
              value={getSettingsLaborTimeRate(laborType, 'HIGH', settingsState?.laborPriceList)?.price}
              onChangeDebounced={handleUpdate('LABOR_PRICE', { laborType, laborLevel: 'HIGH' })}
              placeholder={t(
                'estimate.settings.labor_time_excl_vat_rate.high_technicity.placeholder',
                'T2 technicity rate',
              )}
              currency={currency}
            />
          ),
        },
        {
          title: t('estimate.settings.labor_time_excl_vat_rate.very_high_technicity', 'Very high technicity:'),
          component: (
            <InputPrice
              value={getSettingsLaborTimeRate(laborType, 'VERY_HIGH', settingsState?.laborPriceList)?.price}
              onChangeDebounced={handleUpdate('LABOR_PRICE', { laborType, laborLevel: 'VERY_HIGH' })}
              placeholder={t(
                'estimate.settings.labor_time_excl_vat_rate.very_high_technicity.placeholder',
                'T3 technicity rate',
              )}
              currency={currency}
            />
          ),
        },
      ],
      data: settingsState,
    };
  };

  const tabData = [
    {
      title: t('estimate.settings.labor_time_excl_vat_rate.mechanics.tab_name', 'Mechanics'),
      key: 'mechanics',
      component: <SettingsSection data={getLaborTimeRates('MECHANICS')} id={SETTINGS_RATES} maxW={270} />,
    },
    {
      title: t('estimate.settings.labor_time_excl_vat_rate.painting.tab_name', 'Painting'),
      key: 'painting',
      component: <SettingsSection data={getLaborTimeRates('PAINTING')} id={SETTINGS_RATES} maxW={270} />,
    },
    {
      title: t('estimate.settings.labor_time_excl_vat_rate.bodywork.tab_name', 'Bodywork'),
      key: 'bodywork',
      component: <SettingsSection data={getLaborTimeRates('PANELWORK')} id={SETTINGS_RATES} maxW={270} />,
    },
    {
      title: t('estimate.settings.labor_time_excl_vat_rate.electricity.tab_name', 'Electricity'),
      key: 'electricity',
      component: <SettingsSection data={getLaborTimeRates('ELECTRICITY')} id={SETTINGS_RATES} maxW={270} />,
    },
    {
      title: t('estimate.settings.labor_time_excl_vat_rate.trim.tab_name', 'Trim'),
      key: 'trim',
      component: <SettingsSection data={getLaborTimeRates('TRIM')} id={SETTINGS_RATES} maxW={270} />,
    },
  ];

  const laborTime: SettingsSectionData = {
    title: t('estimate.settings.labor_time_excl_vat_rate.title', 'Labor time Excl.VAT rate'),
    description: (
      <Text type={'light_14'}>
        {t(
          'estimate.settings.labor_time_excl_vat_rate.description',
          'Please note that only Mechanics technicity are mandatory. If Painting, Bodywork, Electricity and Trim technicity are not filled, we will simply use Mechanics as default setting.',
        )}
      </Text>
    ),
    items: [
      {
        title: null,
        component: (
          <Box width={1000}>
            <WideTabs tabData={tabData} />
          </Box>
        ),
      },
    ],
    data: settingsState,
  };

  return (
    <>
      <SettingsSection data={companyContactDetails} key={'section-contact'} />
      {!settingsState ? (
        <Flex justify={'center'}>
          <Spin />
        </Flex>
      ) : (
        <>
          <SettingsSection data={administrativeInformation} maxW={270} key={'section-administrative'} />
          <SettingsSection data={laborTime} maxW={1000} key={'section-labor-time-rate'} />
          <SettingsSection data={wasteRecycling} key={'section-waste-recycling'} />
          <SettingsSection data={VATRate} key={'section-vat'} />
          <SettingsSection data={commercialEstimateValidity} maxW={270} key={'section-estimate-validity'} />
          {isR3Motrio && <SettingsSection data={pdfLogo} key={'section-pdf-logo'} />}
          <SettingsSection data={observations} key={'section-observations'} />
          <SettingsSection data={legalMentions} key={'section-legal'} />
        </>
      )}
    </>
  );
};
