/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/Reference';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { TFunction } from 'i18next';
import { ROUTER_HOME } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { TrashAltIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { calculateDiscountPrice } from 'components/Discount/Discount';
import { BANNER_HEIGHT } from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import {
  attachOtherNonApplicableReferenceToVehicleRequest,
  clearActionStatuses,
  clearOtherReferenceAttachStatus,
  computeTotalPrice,
  getActionStatus,
  getBasketExternalSection,
  getBasketId,
  getBasketOtherSection,
  getBasketVehicles,
  getFileReferencesUploadStatusErrorRows,
  getOtherReferenceAttachStatus,
  removeReferencesRequest,
  setBasket,
  setFileReferencesUploadStatusErrorRows,
  setReferencesIsSelected,
  setTotalPrices,
} from 'domains/basket/Basket.store';
import {
  ExternalBasketSectionLocal,
  OtherReferenceAttachStatus,
  REF_ATTACH_NON_COMPATIBLE,
  ReferenceLocal,
  VehicleLocal,
} from 'domains/basket/Basket.types';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import {
  collectAllReferenceNumbers,
  collectReferenceNumbers,
  countIsSelectedReferences,
} from 'domains/order/Order.mapper';
import { getDiscounts, getLoadedPrices } from 'domains/references';
import { getCurrency } from 'domains/user';
import { CART_STEPS_HEIGHT, CartAndValidationStepProps } from 'pages/CartPage/CartPage';
import { CartAddReferenceResult } from 'pages/CartPage/CartStep/CartAddReference';
import CartHeader from 'pages/CartPage/CartStep/CartHeader';
import CartItemsOthers from 'pages/CartPage/CartStep/CartItems/CartItemsOthers';
import CartItemsVehicle from 'pages/CartPage/CartStep/CartItems/CartItemsVehicle';
import { CartNotificationResultWrapper, CartStepHeaderWrapper } from 'pages/CartPage/CartStep/CartStep.styled';
import CheckoutCardSection from 'pages/CartPage/CartStep/CheckoutCardSection';
import { theme } from 'styles';
import { Box, Checkbox, Flex, MarginBox, StickyBox, Text, TrashAltButton, WarningAlert, WhiteButton } from 'UI';
import { hasData, useBreakpointSelectorFull, useExtraLarge } from 'utils';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import CartItemsExternal from './CartItems/CartItemsExternal';
import ExpiredDiscountsNotification from './ExpiredDiscountsNotification';
import { FileReferencesRowsErrors } from './FileUpload';

export const CART_HEADER_HEIGHT = 120;

const getRemoveDescription = (t: TFunction, isVehicleBasket: boolean, isLastSelected: boolean) => {
  if (isLastSelected) {
    return t('cart.action.removalText1', 'Deleting this reference will delete your vehicle basket.');
  }
  if (isVehicleBasket) {
    return t('cart.action.removalText2', 'This action will delete your vehicle basket and all attached references.');
  }
  return t('cart.action.removalText3', 'This action will delete any selected reference or vehicle basket.');
};

interface CartStepProps {
  addedOtherReference: string | undefined;
  setAddedByReferenceBox: (referenceNumber: string | undefined) => void;
  markRemovedReferences: (numberOfReferences: number) => void;
  numberOfAttachedReferences?: number;
  revertAttachOtherReference: () => void;
}

export const calculateTotalPrice = (
  references: Reference[],
  discounts: Map<string, ReferenceDiscount>,
  loadedPrices: {
    prices: any;
    reference: string;
  }[],
) => {
  return references.reduce((a: number, b: Reference) => {
    const discount = discounts.get(b.referenceNumber);
    const price = loadedPrices.find((p) => p.reference === b.referenceNumber)?.prices;
    if (price) {
      return a + calculateDiscountPrice(discount, price) * b.quantity;
    } else {
      return a;
    }
  }, 0);
};

const CartStep = ({
  next,
  addedOtherReference,
  setAddedByReferenceBox,
  markRemovedReferences,
  isBanners = false,
}: CartAndValidationStepProps & CartStepProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const cartMaxWidth =
    useBreakpointSelectorFull()({ xs: 1000, sm: 1000, md: 1000, lg: 1300, xl: 1600, xxl: 1600 }) ?? 1000;
  const [otherReferenceExpanded, setOtherReferenceExpanded] = useState(true);
  const [otherReferenceHighlight, setOtherReferenceHighlight] = useState<string | undefined>(undefined);
  const lastVehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const basketId = useSelector(getBasketId);
  const [disabledRefs, setDisabledRefs] = useState<string[]>([]);
  const large = useExtraLarge();
  const vehicles = useSelector(getBasketVehicles);
  const otherSection = useSelector(getBasketOtherSection);
  const externalSection = useSelector(getBasketExternalSection);
  const currency = useSelector(getCurrency);
  const errorRows = useSelector(getFileReferencesUploadStatusErrorRows);
  const [highlightedReference, setHighlightedReference] = useState<string | undefined>(undefined);

  const otherRefsActions = useSelector((state: RootState) => getActionStatus(state, addedOtherReference));
  const otherRefAskNonCompatibleStatus = useSelector((state: RootState) =>
    getOtherReferenceAttachStatus(state, REF_ATTACH_NON_COMPATIBLE),
  );
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  const [isTrashButtonVisible, setIsTrashButtonVisible] = useState(true);
  const [disableCheckout, setDisableCheckout] = useState(true);

  const selectedVehiclesReferencesRaw = vehicles?.map((vehicle) => ({
    ...vehicle,
    references: vehicle.references?.filter((reference) => reference.isSelected),
  }));
  const selectedExternalsReferencesRaw = externalSection?.map((ext) => ({
    ...ext,
    references: ext.references?.filter((reference) => reference.isSelected),
  }));
  const selectedVehiclesReferences = selectedVehiclesReferencesRaw?.filter(
    (vehicle) => vehicle && vehicle.references && vehicle.references.length > 0,
  ) as VehicleLocal[];
  const selectedExternalsReferences = selectedExternalsReferencesRaw?.filter(
    (ext) => ext && ext.references && ext.references.length > 0,
  ) as ExternalBasketSectionLocal[];
  const selectedOtherReferences = otherSection?.references.filter((reference) => reference.isSelected);
  const selectedExtBasketsReferencesRaw = externalSection?.map((exBasket) => ({
    ...exBasket,
    references: exBasket.references?.filter((reference) => reference.isSelected),
  }));
  const selectedExtBasketsReferences = selectedExtBasketsReferencesRaw?.filter(
    (exBasket) => exBasket && exBasket.references && exBasket.references.length > 0,
  ) as ExternalBasketSectionLocal[];
  const selectedReferences = selectedVehiclesReferences
    .map((r) => r.references)
    .reduce((a: ReferenceLocal[], b) => {
      return a.concat(b);
    }, [])
    .map((r) => r.referenceNumber)
    .concat(selectedOtherReferences.map((r) => r.referenceNumber))
    .concat(
      selectedExtBasketsReferences
        .map((r) => r.references)
        .reduce((a: ReferenceLocal[], b) => {
          return a.concat(b);
        }, [])
        .map((r) => r.referenceNumber),
    );
  const discounts = useSelector((state: RootState) => getDiscounts(state, selectedReferences));
  const loadedPrices = useSelector((state: RootState) => getLoadedPrices(state, selectedReferences));

  const remapReferencesWithDiscount = (references: ReferenceLocal[]) => {
    return references.map((ref) => {
      const discount = discounts.get(ref.referenceNumber);
      const price = loadedPrices.find((p) => p.reference === ref.referenceNumber);
      if (!discount || !hasData(price?.prices)) {
        return ref;
      }

      return {
        ...ref,
        price: {
          ...ref.price,
          priceVatExcluded: String(calculateDiscountPrice(discount, price?.prices)),
          priceVatIncluded: String(calculateDiscountPrice(discount, price?.prices, true)),
        },
      };
    });
  };

  const setDisableRefs = (ref: string) => {
    setDisabledRefs((prevState) => [...prevState, ref]);
  };
  const itemsCount =
    vehicles?.reduce(
      (acc: number, next: VehicleLocal) => (next && next.references ? acc + next.references.length : acc),
      0,
    ) + (otherSection.references.length || 0);

  useEffect(() => {
    setDisabledRefs([]);
  }, [itemsCount]);

  useEffect(() => {
    const updatedVehicles = vehicles.map((veh) => {
      const updatedReferences = veh.references.map((ref) => {
        return { ...ref, isSelected: disabledRefs.includes(ref.referenceNumber) ? false : ref.isSelected };
      });
      return { ...veh, references: updatedReferences };
    });
    const updatedOtherSection = {
      ...otherSection,
      references: otherSection.references.map((ref) => {
        return {
          ...ref,
          isSelected: disabledRefs.includes(ref.referenceNumber) ? false : ref.isSelected,
        };
      }),
    };
    dispatch(
      setBasket({
        basketId: basketId ?? '',
        vehicles: updatedVehicles,
        otherSection: updatedOtherSection,
      }),
    );
    /* eslint-disable-next-line */
  }, [disabledRefs.length]);

  const discountedSelectedVehiclesReferences: VehicleLocal[] = selectedVehiclesReferences.map((r) => {
    const discountedReferences = remapReferencesWithDiscount(r.references);
    return { ...r, references: discountedReferences };
  });
  const discountedSelectedExternalsReferences: ExternalBasketSectionLocal[] = selectedExternalsReferences.map((r) => {
    const discountedReferences = remapReferencesWithDiscount(r.references);
    return { ...r, references: discountedReferences };
  });
  const discountedSelectedOtherReferences: ReferenceLocal[] = remapReferencesWithDiscount(selectedOtherReferences);

  const selectedTotalPriceVatIncluded = computeTotalPrice(
    discountedSelectedVehiclesReferences,
    discountedSelectedExternalsReferences,
    discountedSelectedOtherReferences,
    true,
  );
  const selectedTotalPriceVatExcludedNoDiscount = computeTotalPrice(
    selectedVehiclesReferences,
    selectedExternalsReferences,
    selectedOtherReferences,
    false,
  );
  const selectedTotalPriceVatExcluded = computeTotalPrice(
    discountedSelectedVehiclesReferences,
    discountedSelectedExternalsReferences,
    discountedSelectedOtherReferences,
    false,
  );

  const totalDiscount = selectedTotalPriceVatExcludedNoDiscount - selectedTotalPriceVatExcluded;

  useEffect(() => {
    dispatch(
      setTotalPrices({
        vatIncluded: selectedTotalPriceVatIncluded,
        vatExcluded: selectedTotalPriceVatExcluded,
        totalDiscount,
      }),
    );
  }, [dispatch, selectedTotalPriceVatIncluded, selectedTotalPriceVatExcluded, totalDiscount]);

  const containsOnlyFromOneVehicleBasket = selectedVehiclesReferences?.length === 1 ?? false;
  const containsNoOtherReferences = !selectedOtherReferences || selectedOtherReferences?.length === 0;
  const containsExactlyOneReference =
    selectedVehiclesReferences?.reduce(
      (acc, next) => (next && next.references ? acc + next?.references?.length : acc),
      0,
    ) === 1 ?? false;
  const selectedLastReferenceInVehicleBasket =
    containsOnlyFromOneVehicleBasket && containsNoOtherReferences && containsExactlyOneReference;

  const containsAnyVehicleBasket =
    vehicles.filter(
      (vehicle) =>
        vehicle &&
        vehicle.references &&
        vehicle.references.filter((r) => r.isSelected).length === vehicle.references.length,
    ).length > 0;

  const disclosure = useDisclosure();
  const { onOpen } = disclosure;
  const disclosureAttach = useDisclosure();
  const { onOpen: onOpenAttachConfirmation } = disclosureAttach;

  useEffect(() => {
    if (otherRefAskNonCompatibleStatus.length > 0) {
      onOpenAttachConfirmation();
    }
  }, [otherRefAskNonCompatibleStatus, onOpenAttachConfirmation]);

  const handleConfirm = () => {
    const vehiclesWithReferences = selectedVehiclesReferencesRaw?.map((veh) => {
      return {
        vehicleKey: veh.vehicleDetail.vehicleKey,
        references: collectReferenceNumbers(veh.references),
      };
    });
    const otherReferences = collectReferenceNumbers(selectedOtherReferences);

    dispatch(clearActionStatuses());
    dispatch(setFileReferencesUploadStatusErrorRows([]));
    dispatch(
      removeReferencesRequest({
        vehiclesWithReferences,
        otherReferences,
      }),
    );
    const numberOfExternalReferences =
      selectedExternalsReferences.reduce((acc, next) => acc + next.references.length, 0) ?? 0;
    const numberOfVehicleReferences = vehiclesWithReferences.reduce((acc, curr) => acc + curr.references.length, 0);
    const numberOfReferences = otherReferences.length + numberOfVehicleReferences + numberOfExternalReferences;
    markRemovedReferences(numberOfReferences);
  };
  const handleConfirmAttach = () => {
    otherRefAskNonCompatibleStatus.forEach((status) => {
      dispatch(
        attachOtherNonApplicableReferenceToVehicleRequest({
          vehicleKey: status.vehicleKey,
          referenceNumber: status.reference,
        }),
      );
    });
  };

  const handleCancelAttach = (otherRefAskNonCompatibleStatus: OtherReferenceAttachStatus[]) => {
    otherRefAskNonCompatibleStatus.forEach((status) => {
      dispatch(clearOtherReferenceAttachStatus({ referenceNumber: status.reference }));
    });
  };

  const dialogDescription = [
    getRemoveDescription(
      t,
      containsOnlyFromOneVehicleBasket && containsNoOtherReferences,
      selectedLastReferenceInVehicleBasket,
    ),
    t('common.dialog.confirmationQuestion', 'Would you like to confirm?'),
  ];

  const handleRedirect = () => {
    scrollToTopSmooth();
    history.push(
      lastVehicleKey && vehicleDetail?.catalogSource
        ? `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${lastVehicleKey}/vehicle`
        : ROUTER_HOME,
    );
  };

  const onRemoveSelected = () => {
    if (containsAnyVehicleBasket) {
      onOpen();
    } else {
      const numberOfExternalReferences =
        selectedExternalsReferences?.reduce((acc, next) => acc + next.references.length, 0) ?? 0;
      const numberOfVehicleReferences =
        selectedVehiclesReferences?.reduce((acc, next) => acc + next.references.length, 0) ?? 0;
      markRemovedReferences(numberOfVehicleReferences + selectedOtherReferences.length + numberOfExternalReferences);

      dispatch(clearActionStatuses());
      dispatch(setFileReferencesUploadStatusErrorRows([]));

      dispatch(
        removeReferencesRequest({
          vehiclesWithReferences: selectedVehiclesReferencesRaw?.map((veh) => {
            return {
              vehicleKey: veh.vehicleDetail.vehicleKey,
              references: collectReferenceNumbers(veh.references),
            };
          }),
          otherReferences: collectReferenceNumbers(selectedOtherReferences),
          externalBasketsWithReferences: selectedExtBasketsReferencesRaw?.map((extB) => {
            return {
              externalBasketId: extB.externalBasketId,
              references: collectReferenceNumbers(extB.references),
            };
          }),
        }),
      );
    }
  };

  function setVehiclesReferences(isSelected: boolean) {
    vehicles?.forEach((v) => {
      const referenceIds = collectAllReferenceNumbers(v.references, disabledRefs);
      dispatch(
        setReferencesIsSelected({
          isSelected,
          referenceIds,
          vehicleKey: v.vehicleDetail.vehicleKey,
        }),
      );
    });
  }

  function setExternalsReferences(isSelected: boolean) {
    externalSection?.forEach((ex) => {
      const referenceIds = collectAllReferenceNumbers(ex.references, disabledRefs);
      dispatch(
        setReferencesIsSelected({
          isSelected,
          referenceIds,
          vehicleKey: undefined,
          externalBasketId: ex.externalBasketId,
        }),
      );
    });
  }

  function doSelectAll(isSelected: boolean) {
    setVehiclesReferences(isSelected);
    setExternalsReferences(isSelected);
    const referenceIds = collectAllReferenceNumbers(otherSection?.references, disabledRefs);
    dispatch(
      setReferencesIsSelected({
        isSelected,
        referenceIds,
        vehicleKey: undefined,
      }),
    );
    setIsSelectAllChecked(isSelected);
  }

  function selectAll() {
    doSelectAll(!isSelectAllChecked);
  }

  useEffect(() => {
    const isDisabledRef = (ref: string) => {
      return disabledRefs.includes(ref);
    };
    const countActiveRefs = (arr: ReferenceLocal[]) => {
      return arr.reduce((num, ref) => (num += isDisabledRef(ref.referenceNumber) ? 0 : 1), 0);
    };
    let count = countIsSelectedReferences(otherSection?.references);
    let numOfRef = countActiveRefs(otherSection.references);
    vehicles?.forEach((v) => {
      count += countIsSelectedReferences(v.references);
      numOfRef += countActiveRefs(v.references);
    });
    externalSection?.forEach((b) => {
      count += countIsSelectedReferences(b.references);
      numOfRef += countActiveRefs(b.references);
    });

    setIsTrashButtonVisible(count !== 0);
    setIsSelectAllChecked(count > 0 ? count === numOfRef : false);
    setDisableCheckout(count === 0);
  }, [otherSection, vehicles, disabledRefs, externalSection]);

  return (
    <Flex direction={'column'}>
      <Flex justify={'center'}>
        <CartStepHeaderWrapper direction={'column'} maxWidth={cartMaxWidth + 30} background={theme.color.white}>
          <div style={{ zIndex: 501, width: '100%' }}>
            <StickyBox
              offsetTop={HEADER_HEIGHT + CART_STEPS_HEIGHT + (isBanners ? BANNER_HEIGHT : 0)}
              background={theme.color.white}
              height={CART_HEADER_HEIGHT}
            >
              <MarginBox mx={15}>
                <CartHeader setAddedByReferenceBox={setAddedByReferenceBox} isCartEmpty={false} />
                <Flex direction={'row'} align={'center'} minHeight={30} maxHeight={30}>
                  <Checkbox
                    checked={isSelectAllChecked}
                    onChange={selectAll}
                    label={`${t('cart.action.select_all', 'Select all items')}`}
                  />
                  {isTrashButtonVisible && (
                    <MarginBox ml={15}>
                      <TrashAltButton onClick={onRemoveSelected} round bgColor={'grey95'} />
                    </MarginBox>
                  )}
                </Flex>
              </MarginBox>
            </StickyBox>
          </div>
        </CartStepHeaderWrapper>
      </Flex>
      <Flex direction={'column'} justify={'center'}>
        <Flex direction={'row'}>
          <Flex />
          <Flex>
            <MarginBox mx={15}>
              <Flex direction={'row'} justify={'center'} maxWidth={cartMaxWidth}>
                <Flex direction={'column'} align={'center'}>
                  <StickyBox
                    offsetTop={HEADER_HEIGHT + CART_STEPS_HEIGHT + CART_HEADER_HEIGHT + (isBanners ? BANNER_HEIGHT : 0)}
                    background={theme.color.white}
                    width={'100%'}
                  >
                    <Flex direction={'column'} align={'flex-start'} minWidth={'100%'}>
                      {otherRefsActions && (
                        <Flex>
                          <CartAddReferenceResult
                            otherSection={otherSection}
                            addedOtherReference={addedOtherReference}
                            otherReferencesAction={otherRefsActions}
                            setExpanded={setOtherReferenceExpanded}
                            setHighlightedRef={setOtherReferenceHighlight}
                          />
                        </Flex>
                      )}
                      {disabledRefs.length > 0 && (
                        <CartNotificationResultWrapper>
                          <MarginBox ml={-5}>
                            <Box background={theme.color.white}>
                              <MarginBox ml={5}>
                                <WarningAlert
                                  message={
                                    <Flex direction={'column'}>
                                      <Text type={'text_dim_bold'}>
                                        {t(
                                          'cart.action.changed_availabilities.title',
                                          'Availabilities have changed since they’ve been added to your basket',
                                        )}
                                      </Text>
                                      <Text type={'text_dim'}>
                                        {t(
                                          'cart.action.changed_availabilities.description',
                                          'Disabled references will not be part of your order.',
                                        )}
                                      </Text>
                                    </Flex>
                                  }
                                />
                              </MarginBox>
                            </Box>
                          </MarginBox>
                        </CartNotificationResultWrapper>
                      )}
                      <Dialog
                        disclosure={disclosureAttach}
                        title={t('common.dialog.information', 'Information')}
                        description={
                          t(
                            'common.dialog.compatibilityText',
                            'This reference is not compatible with the chosen vehicle.\nWould you like to confirm?',
                          ) as string
                        }
                        status={'info'}
                        handleCancel={() => handleCancelAttach(otherRefAskNonCompatibleStatus)}
                        handleConfirm={handleConfirmAttach}
                      />
                      <CartNotificationResultWrapper>
                        <FileReferencesRowsErrors errorRows={errorRows} />
                      </CartNotificationResultWrapper>
                      <Box height={1} />
                    </Flex>
                  </StickyBox>
                  <Flex direction={'column'} align={'center'}>
                    {disabledRefs.length > 0 && <MarginBox mt={15} />}
                    <ExpiredDiscountsNotification setHighlightedReference={setHighlightedReference} />
                    {vehicles?.map((basketVehicle) => (
                      <CartItemsVehicle
                        key={basketVehicle.vehicleDetail?.vehicleKey}
                        basketVehicle={basketVehicle}
                        currency={currency}
                        markRemovedReferences={markRemovedReferences}
                        disabledRefs={disabledRefs}
                        highlightedReference={highlightedReference}
                        setDisableRefs={setDisableRefs}
                      />
                    ))}
                    {externalSection.map((externalBasket) => (
                      <CartItemsExternal
                        key={externalBasket.externalBasketId}
                        externalBasket={externalBasket}
                        markRemovedReferences={markRemovedReferences}
                        currency={currency}
                        disabledRefs={disabledRefs}
                        highlightedReference={highlightedReference}
                        setDisableRefs={setDisableRefs}
                      />
                    ))}
                    {otherSection && otherSection.references.length > 0 && (
                      <CartItemsOthers
                        key={'otherRefs'}
                        otherSection={otherSection}
                        currency={currency}
                        addedOtherReference={addedOtherReference}
                        expanded={otherReferenceExpanded}
                        setExpanded={setOtherReferenceExpanded}
                        highlightedRef={otherReferenceHighlight}
                        setHighlightedRef={setOtherReferenceHighlight}
                        markRemovedReferences={markRemovedReferences}
                        disabledRefs={disabledRefs}
                        highlightedReference={highlightedReference}
                        setDisableRefs={setDisableRefs}
                      />
                    )}
                    <Box width={'100%'}>
                      <Flex justify={'flex-end'}>
                        <WhiteButton onClick={handleRedirect}>
                          {t('cart.action.keep_shopping', 'Keep on shopping')}
                        </WhiteButton>
                      </Flex>
                    </Box>
                  </Flex>
                </Flex>
                {large && (
                  <StickyBox
                    offsetTop={HEADER_HEIGHT + CART_STEPS_HEIGHT + CART_HEADER_HEIGHT + (isBanners ? BANNER_HEIGHT : 0)}
                  >
                    <MarginBox ml={15}>
                      <CheckoutCardSection
                        totalPriceVatIncluded={selectedTotalPriceVatIncluded ?? 0}
                        totalPriceVatExcluded={selectedTotalPriceVatExcluded ?? 0}
                        vehicles={selectedVehiclesReferences ?? []}
                        externalBaskets={selectedExternalsReferences ?? []}
                        otherReferences={selectedOtherReferences}
                        totalVat={(selectedTotalPriceVatIncluded ?? 0) - (selectedTotalPriceVatExcluded ?? 0)}
                        currency={currency}
                        onCheckout={next}
                        loading={false}
                        disabledCheckout={disableCheckout}
                        totalDiscount={totalDiscount}
                      />
                    </MarginBox>
                  </StickyBox>
                )}
              </Flex>
            </MarginBox>
          </Flex>
          <Flex />
        </Flex>
        {!large && (
          <>
            <MarginBox mt={30} />
            <Flex direction={'row'}>
              <Flex />
              <StickyBox>
                <CheckoutCardSection
                  totalPriceVatIncluded={selectedTotalPriceVatIncluded ?? 0}
                  totalPriceVatExcluded={selectedTotalPriceVatExcluded ?? 0}
                  totalVat={(selectedTotalPriceVatIncluded ?? 0) - (selectedTotalPriceVatExcluded ?? 0)}
                  vehicles={selectedVehiclesReferences ?? []}
                  externalBaskets={selectedExternalsReferences ?? []}
                  otherReferences={selectedOtherReferences}
                  currency={currency}
                  onCheckout={next}
                  disabledCheckout={disableCheckout}
                  totalDiscount={totalDiscount}
                />
              </StickyBox>
              <Flex />
            </Flex>
            <MarginBox mt={30} />
          </>
        )}
      </Flex>
      <Box height={140} />
      <Dialog
        disclosure={disclosure}
        title={t('common.dialog.actionRequired', 'Action required')}
        description={dialogDescription}
        icon={TrashAltIcon}
        status={'info'}
        handleConfirm={handleConfirm}
      />
    </Flex>
  );
};

export default CartStep;
