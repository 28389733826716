import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'assets/icons';
import { ServiceCategoryLocal } from 'domains/maintenancePlan/MaintenancePlan.types';
import { SPanel } from 'pages/CatalogPage/IAM/MaintenancePlan/Category/Category.styled';
import RenderOperation from 'pages/CatalogPage/IAM/MaintenancePlan/Operation';
import { CollapseWithKey, Container, Flex, Icon, Text } from 'UI';

const RenderCategory = ({
  serviceCategory,
  expanded,
}: {
  serviceCategory: ServiceCategoryLocal;
  expanded: boolean;
}) => {
  const [activeKey, setActiveKey] = useState<string[]>([expanded ? 'panel-' + serviceCategory.id : '']);

  useEffect(() => {
    //    open selected by proposals even addtional operations which are in default collapsed
    if (serviceCategory.operations.some((o) => o.selected)) {
      setActiveKey(['panel-' + serviceCategory.id]);
    }
  }, [setActiveKey, serviceCategory.operations, serviceCategory.id]);

  const isActive = () => {
    return activeKey.includes('panel-' + serviceCategory.id);
  };

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKey(typeof key === 'string' ? [key] : key);
  };

  return (
    <Container>
      <CollapseWithKey
        iconInactive={
          <Flex>
            <Icon IconComponent={ChevronDownIcon} height={15} width={15} />
          </Flex>
        }
        iconActive={
          <Flex>
            <Icon IconComponent={ChevronUpIcon} height={15} width={15} />
          </Flex>
        }
        noBorder
        position={'end'}
        noShadow
        noMargin
        key={'service-op-main-collapse-' + serviceCategory.id}
        activeKey={activeKey}
        onChange={handleCollapseChange}
      >
        <SPanel
          header={
            isActive() ? (
              <Text type={'link_bold'} decoration={'underline'}>
                {serviceCategory.label}
              </Text>
            ) : (
              <Text type={'text_dim_bold'}>{serviceCategory.label}</Text>
            )
          }
          key={'panel-' + serviceCategory.id}
        >
          {serviceCategory.operations.map((operation) => (
            <RenderOperation key={operation.id} operation={operation} />
          ))}
        </SPanel>
      </CollapseWithKey>
    </Container>
  );
};

export default RenderCategory;
