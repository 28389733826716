import { ReactNode } from 'react';
import { Collapse as CollapseAnt } from 'antd';
import styled from 'styled-components';

const { Panel: PanelAnt } = CollapseAnt;

export const SPanel = styled(PanelAnt)<{ headerBorder?: boolean; children?: ReactNode }>`
  background-color: ${({ theme }) => theme.color.white};

  .ant-collapse-header-text {
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
  }

  .ant-collapse-header {
    border: 1px solid ${({ theme }) => theme.color.grey85};
    box-shadow: ${({ theme }) => `3px 5px 10px 5px ${theme.color.grey95}`};
  }

  .ant-slider-track {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }

  .ant-slider:hover .ant-slider-rail {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }

  .ant-slider-handle {
    background-color: white;
    border-color: ${({ theme }) => theme.color.grey20};
  }

  .ant-slider-handle:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.color.clear_blue};
  }
`;
